import { Injectable } from '@angular/core';
import {NgbTimeStruct, NgbTimeAdapter} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class TimepickerAdapter extends NgbTimeAdapter<Date> {

    fromModel(date : Date) : NgbTimeStruct{
        return (date && date.getHours()) ? {hour : date.getHours(), minute : date.getMinutes(), second : date.getSeconds()} : null;
    }

    toModel(timeStruct : NgbTimeStruct) : Date{
        if(timeStruct){
            let date = new Date();
            date.setHours(timeStruct.hour);
            date.setMinutes(timeStruct.minute);
            date.setSeconds(timeStruct.second);
            return date;
        } else {
            return null;
        }
    }
}
