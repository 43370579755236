import { SiteDocumentDAO } from 'global/models/dao/site-document';

export class EvenementDAO extends SiteDocumentDAO {
  public nom: string;
  public description: string;
  public evenementJournee: boolean;
  public dateDebut: any;
  public dateFin: any;
  public couleur: string;
  public categorie: string;
  public getCouleur: boolean;
  public intervenant: any;

  constructor() {
    super();
    this.dataType = 'evenement';
  }
}