import { CaracteristiqueEconomique } from '../../../../models/caracteristique-economique';
import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { CaracteristiquePhysicoChimique } from 'tracabilite/models/caracteristique-physico-chimique';


export class StockIntrantDAO extends SiteDocumentDAO {
	public categorie: string;
	public nom: string;
	public code: string;
	public lieuStockage: string;
	public quantiteIncorporeeCste: boolean;
	public calculTonnage: boolean;
	public calculCPC: boolean;
	public consigneCste: boolean;
	public cpc: CaracteristiquePhysicoChimique[];
	public ce: CaracteristiqueEconomique[];
	public tonnage: number;
	public dateTonnageRef: any;
	public tags: string[];
	public etatStock: number;
	public notDisplayInDropdowns: boolean = false;
	public methodCalculCPC: string;
	public tauxMatiereDurable: number;

	constructor() {
		super();
		this.dataType = 'stock-intrant';
		this.quantiteIncorporeeCste = false;
		this.calculTonnage = true;
		this.calculCPC = true;
		this.consigneCste = true;
	}

}
