import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import { IColumn } from '../interfaces/IColumn';
import { IPagination } from '../interfaces/IPagination';
import { IRow } from '../interfaces/IRow';
import { ITableFooter } from '../interfaces/ITableFooter';
import { ITableHeader } from '../interfaces/ITableHeader';
import { ColumnSelectorService } from '../services/ColumnSelectorService';
import { DataService } from '../services/DataService';
import { FilterService } from '../services/FilterService';
import { PaginationService } from '../services/PaginationService';
import { SortService } from '../services/SortService';
import {TranslateService} from "@ngx-translate/core";

export { AlignRule } from '../enums/align-rule';

@Component({
    selector: 'data-table',
    templateUrl: '../views/table.html',
    providers: [DataService, PaginationService, SortService, ColumnSelectorService, FilterService]
})
export class TableComponent implements OnInit, DoCheck, OnChanges {
    @Input('total-rows') totalRows;
    @Input('all-row-selected') allRowSelected: boolean;
    @Input('selection-type') selectionType: string;
    @Input('table-row-id') tableRowId: any;
    @Input('columns') columns: any;
    @Input('selected-rows') selectedRows: any;
    @Input('rows') rows: any;
    @Input() noDataText: any;
    @Input('table-header') tableHeader: ITableHeader;
    @Input('pagination') pagination: IPagination;
    @Input('sortable-columns') sortableColumns: boolean;
    @Input('selectable-columns') selectableColumns: boolean;
    @Input('selectable-rows') selectableRows: boolean;
    @Input('clickable-row') clickableRow: boolean;
    @Output('onrowselect') onRowSelect: EventEmitter<any> = new EventEmitter();
    @Output('onchangepage') onChangePage: EventEmitter<any> = new EventEmitter();
    @Output('oncolumnselect') onColumnSelect: EventEmitter<any> = new EventEmitter();
    @Output('onrowclick') onRowClick: EventEmitter<any> = new EventEmitter();
    tableFooter: ITableFooter;
    private oldRows = [];
    private oldSelectedRows = [];

    constructor(
        protected dataService: DataService,
        protected arrayPaginationService: PaginationService,
        protected sortService: SortService,
        private translateService: TranslateService,
        private ref: ChangeDetectorRef
    ) {
        this.translateService.onLangChange.subscribe(lang => {
            this.noDataText = this.translateService.instant('app.global.termes.pasDeDonnees');
        })
    }

    ngOnInit() {
        if (this.allRowSelected == undefined) this.allRowSelected = false;
        if (!this.noDataText) this.noDataText = this.translateService.instant('app.global.termes.pasDeDonnees');
        this.dataService.setRowId(this.tableRowId || '_id');
        this.dataService.removeColumns();
        this.dataService.addColumns(this.columns);
        this.dataService.addRows(this.rows);
        this.dataService.setSelectedRows([]);
        this.dataService.setRowSelectionEnabled(this.selectableRows);
        this.dataService.setRowSelectionType(this.selectionType);
        this.sortService.setSortingEnabled(this.sortableColumns);
        this.tableFooter = {
            pagination: this.pagination || <IPagination>{}
        };
    }

    // ngAfterContentChecked(){
    //   this.ref.detectChanges();
    // }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            const columnChange: SimpleChange = changes.columns.currentValue;
            if (columnChange != undefined) {
                this.dataService.removeColumns();
                this.dataService.addColumns(this.columns);
            }
        }
        if (changes.rows) {
            const change: SimpleChange = changes.rows.currentValue;
            if (change != undefined) {
                this.dataService.addRows(this.rows);
                this.oldRows = _.cloneDeep(this.rows);
                this.dataService.setSelectedRows(this.selectedRows || []);
            }
        }
        if (changes.selectedRows) {
            const rowChange: SimpleChange = changes.selectedRows.currentValue;
            if (rowChange != undefined && !this.oldSelectedRows && this.oldRows) {
                this.oldSelectedRows = _.cloneDeep(this.selectedRows);
                this.dataService.setSelectedRows(this.selectedRows);
            }
        }
        if (changes.totalRows) {
            if (changes.totalRows.currentValue != undefined) {
                this.arrayPaginationService.setTotalRowsCount(this.totalRows);
            }
        }
        if (changes.selectionType) {
            const rowChange: SimpleChange = changes.selectionType.currentValue;
            if (rowChange != undefined) {
                this.dataService.setRowSelectionType(this.selectionType);
            }
        }
    }

    ngDoCheck() {
        if (!_.isEqual(this.oldRows, this.rows)) {
            this.oldRows = _.cloneDeep(this.rows);
            this.dataService.addRows(this.rows);
        }
        if (this.selectedRows && !_.isEqual(this.oldSelectedRows, this.selectedRows)) {
            this.oldSelectedRows = _.cloneDeep(this.selectedRows);
            this.dataService.setSelectedRows(this.selectedRows);
        }
    }

    rowSelected(rows: Array<IRow>) {
        if (this.selectableRows) {
            this.onRowSelect.emit(rows);
        }
    }

    rowClicked(row: IRow) {
        if (this.clickableRow) {
            this.onRowClick.emit(row);
        }
    }

    columnSelected(cols: Array<IColumn>) {
        if (this.selectableColumns) {
            this.onColumnSelect.emit(cols);
        }
    }

    pageChanged(newPage: any) {
        if (this.onChangePage) {
            this.onChangePage.emit(newPage);
        }
    }
}
