import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    SimpleChanges,
    AfterContentChecked, OnChanges, DoCheck, OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerimetreService } from 'global/services/perimetre/perimetre.service';
import * as _ from 'lodash';

@Component({
    selector: 'selecteur-perimetre-inform',
    templateUrl: './selecteur-perimetre-inform.component.html',
    styleUrls: ['./selecteur-perimetre-inform.component.scss']
})
export class SelecteurPerimetreInformComponent implements OnInit, AfterContentChecked, OnChanges, DoCheck, OnDestroy {

    @Input() global = false;
    @Input() value = null;
    @Output() valueChange = new EventEmitter();
    @Input() invalid: boolean;
    @Input() disabled = false;
    private perimetreSubscription;
    public display = false;
    public sites = [];
    public oldValue;

    constructor(
        private ref: ChangeDetectorRef,
        private perimetreService: PerimetreService,
        private translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.sites = JSON.parse(sessionStorage.getItem('sites'));
        this.perimetreSubscription = this.perimetreService.perimetre$.subscribe(perimetre => {
            if (perimetre && perimetre.dataType == 'organisation') {
                if (this.sites.length == 1) {
                    this.value = this.sites[0];
                    this.valueChange.emit(this.value);
                } else {
                    this.display = true;
                    this.value && this.value.dataType == 'organisation' ? this.value = null : null;
                }
            } else {
                this.display = false;
                this.value = perimetre;
                this.valueChange.emit(this.value);
            }
        });
    }


    compareFn(complexeObject1: any, complexeObject2: any) {
        return complexeObject1 && complexeObject2 && complexeObject1.nom && complexeObject2.nom ? complexeObject1.nom == complexeObject2.nom : complexeObject1 == complexeObject2;
    }

    ngAfterContentChecked() {
        this.ref.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && changes.value.currentValue) {
            this.oldValue = _.cloneDeep(this.value);
        } else if (changes.value && changes.value.currentValue == null) {
            this.value = this.translateService.instant('app.global.allSites');
            this.oldValue = this.translateService.instant('app.global.allSites');
        }
    }

    ngDoCheck() {
        if (!_.isEqual(this.oldValue, this.value)) {
            this.oldValue = _.cloneDeep(this.value);
            this.ref.detectChanges();
        }
    }

    valueChanged(perimetre: any) {
        this.value = perimetre;
        if (perimetre && (perimetre.id || perimetre._id)) {
            this.valueChange.emit(this.value);
        } else {
            this.valueChange.emit(null);
        }
    }

    ngOnDestroy() {
        this.perimetreSubscription.unsubscribe();
    }

}
