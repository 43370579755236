import {Directive,ElementRef } from '@angular/core';

import * as $ from 'jquery';


@Directive({
	selector: '[mega-navigation]',
})
export class MegaNavigationDirective{
	
	constructor(private elementRef: ElementRef<HTMLElement>){
		
	}
}