import { IFilterData } from '../interfaces/IFilterData';
import { IFilter } from '../interfaces/IFilter';
import { IRow } from '../interfaces/IRow';
import { ICell } from '../interfaces/ICell';
import * as _ from 'lodash';

export class FilterService{
	private filters: IFilterData[];

	constructor(){
		this.filters = [];
	}

	addFilter(filter: IFilterData){
		let index = _.indexOf(this.filters,_.find(this.filters,{column: filter.column}));
		if(index>=0) this.filters[index] = filter;
		else this.filters.push(filter);
		
	}

	deleteFilter(filter: IFilterData){
		let index = _.indexOf(this.filters,_.find(this.filters,{column: filter.column}));
		if( index>=0) this.filters.splice(index,1);
	}

	transforms(rows: IRow[]): IRow[]{
		if(this.filters.length){
			let res = new Array<IRow>();
			_.each(this.filters,(filter: IFilterData, i)=>{
				let filterValues = filter.values.map(f=> f.value);
				let index = _.findIndex(rows[0].value, _.find(rows[0].value,{dataKey: filter.column.dataKey}) as any);
				if(index >= 0 ){					
					if(filter.values.length == 1 && filter.values[0].predicate) {
						let predicate = filter.values[0].predicate;
						if(i == 0 ) res = res.concat(rows.filter((row: IRow) => predicate(row.value[index].value)));
						else res = res.filter((row: IRow) => rows.filter((row: IRow) => predicate(row.value[index].value)))
					}
					else{
						if(i == 0 ) res = res.concat(rows.filter((row: IRow) => _.includes(filterValues,row.value[index].value.toString().toLowerCase())));
						else res = res.filter((row: IRow) => _.includes(filterValues,row.value[index].value.toString().toLowerCase()))
					}
				} 
			})
			return res;
		} else return rows;
	}
}