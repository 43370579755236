import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { TableBodyComponent } from './components/table-body/table-body.component';
import { TableComponent } from './components/table/table.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableNumberFixedPipe } from './pipes/table-number-fixed.pipe';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TranslateModule
    ],
  declarations: [
  	TableBodyComponent, 
  	TableComponent, 
  	TableHeaderComponent,
  	TableNumberFixedPipe
	],
  exports : [
    TableComponent
  ]
})
export class ToggleTableModule { }
