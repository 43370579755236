import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class Contact implements IBGVTreeNode{
	nom: string;
	email: string;
	numeroPortable: string;
	numeroFixe: string;

	toNodes(parentId?: number, parentName?: any): any{
		let keyNames ={
			'nom': 'Nom',
			'email': 'Email',
			'numeroPortable': 'Numéro portable',
			'numeroFixe': 'numeroFixe'
		}
		return Object.keys(keyNames)
			.map((key,index) =>{
				if(parentId != undefined && parentName)
					return {
						id: index+parentId+1,
						name: keyNames[key],
						dataKey: parentName+'.'+key,
						parent: parentId
					}
				else return {
					id: index,
					name: keyNames[key],
					dataKey: key
				}
			})
	}

}