import { SiteDocument } from 'global/models/dto/site-document';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import { VariableDAO } from 'rondes/models/dao/variable';
import { Famille } from '../famille';
import { UniteFamille } from '../unite-famille';
import * as shortUuid from 'short-uuid';

export class Variable extends SiteDocument implements IDto2Dao, IBGVTreeNode {

	code: string;
	nom: string;
	equipement: string;
	nomComplet: string;
	famille: Famille;
	unite: UniteFamille;
	frequence: any;
	description: string;
	seuilHaut: number;
	seuilHautBloquant: number;
	seuilBas: number;
	seuilBasBloquant: number;
	valeurParDefaut: string;
	consolidation: string;
	type: string;
	valeurs: any[];

	constructor() {
		super();
		this.dataType = 'variable';
		this.type = 'Mesure';
		this.valeurParDefaut = 'Pas de valeur par défaut';
		this.consolidation = 'Moyenne';
	}

	toDao() : VariableDAO{
		let variable = new VariableDAO();
		Object.keys(this)
			.forEach(key => {
				if(key == "site") this[key]? variable[key] = this[key]._id || this[key].id : variable[key] = null;
				else {
					variable[key]= this[key];
				}
			});

		return variable;
	}

	toNodes(parentId?: number, parentName?: any): any{
		let keyNames =[
			{'label': 'Code','dataKey': 'code'},
			{'label': 'Type','dataKey': 'type'},
			{'label': 'Nom','dataKey': 'nom'},
			{'label': 'Equipement','dataKey': 'equipement'},
			{'label': 'Nom complet','dataKey': 'nomComplet'},
			{'label': 'Famille','dataKey': 'famille.nom'},
			{'label': 'Unité','dataKey': 'unite.label'},
			{'label': 'Fréquence','dataKey': 'frequence'},
			{'label': 'Description','dataKey': 'description'},
			{'label': 'Seuil haut','dataKey': 'seuilHaut'},
			{'label': 'Seuil haut bloquant','dataKey': 'seuilHautBloquant'},
			{'label': 'Seuil bas','dataKey': 'seuilBas'},
			{'label': 'Seuil bas bloquant','dataKey': 'seuilBasBloquant'},
			{'label': 'Valeur par défaut','dataKey': 'valeurParDefaut'},
			{'label': 'Consolidation','dataKey': 'consolidation'},
		]
		let index = 0;
		return keyNames.map(key =>{
			let node: any = {},
        id = parentId ? parentId+index+1 : index;
      node = {
        id: id,
        name: key.label,
        dataKey: key.dataKey
      }
      index++;
      return node;
		})

	}

}
