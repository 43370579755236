import { Directive, ElementRef, Input } from '@angular/core';
import { IColumn } from '../interfaces/IColumn';
import { AlignRule } from '../enums/align-rule';

@Directive({
	selector: '[table-cell-align]'
})
export class TableCellAlignDirective{
	
	private el_: HTMLElement;

  @Input('table-cell-align')
  aColumn: IColumn;

  constructor(el: ElementRef){
    this.el_ = el.nativeElement;
  }

  ngOnInit(){
    switch(this.aColumn.align){
      case AlignRule.Left:
        this.el_.classList.add('dt-left');
        return;
      case AlignRule.Center:
        this.el_.classList.add('dt-center');
        return;
      case AlignRule.Justify:
        this.el_.classList.add('dt-justify');
        return;
      case AlignRule.Right:
        this.el_.classList.add('dt-right');
        return;
      default:
     		throw new Error('Could not align because the align rule ('+this.aColumn.align+') was unknown.');
    }
  }
}