import { Injectable, Input, Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'confirm-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{'app.modules.global.services.confirmation.titre' | translate}}</h4>
    </div>
    <div class="modal-body" [innerHTML]="message"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="confirm()">{{'app.global.boutons.oui' | translate}}</button>
      <button type="button" class="btn btn-light" (click)="cancel()">{{'app.global.boutons.non' | translate}}</button>
    </div>
  `
})
export class ConfirmModalComponent{

  @Input() message: string;

  constructor(
    private _activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) { 
  }

  confirm(){
    this._activeModal.close(true);
  }

  cancel(){
    this._activeModal.dismiss(false);
  }

}

@Component({
  selector: 'alert-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{'app.global.termes.information' | translate}}</h4>
    </div>
    <div class="modal-body" [innerHTML]="message"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="confirm()">Ok</button>
    </div>
  `
})
export class AlertModalComponent{

  @Input() message: string;

  constructor(
    private _activeModal: NgbActiveModal
  ) { 
  }

  confirm(){
    this._activeModal.close(true);
    this._activeModal.dismiss(true);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ConfirmationGuardService {

  constructor(private _modalService: NgbModal, private translateService: TranslateService) { }

  /**
  * Create a confirmation guard message with window.confirm
  * @param message - confirmation message
  */
  confirm(message?: string,size: any = 'lg', centered: boolean = false): any{
    const modalRef = this._modalService.open(ConfirmModalComponent, {size: size, backdrop: 'static', centered: centered});
    modalRef.componentInstance.message = message || this.translateService.instant('app.modules.global.services.confirmation.sur');
    return modalRef.result;
  };
  /**
  * Create an alert message with window.alert
  * @param message - alert message
  */
  alert(message?: string,size: any = 'lg', centered: boolean = false): any {
    const modalRef = this._modalService.open(AlertModalComponent, {size: size, backdrop: 'static', centered: centered});
    modalRef.componentInstance.message = message || this.translateService.instant('app.modules.global.services.confirmation.sur');
    return modalRef.result;
  };
}
