import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import * as _ from 'lodash';
import { IColumn } from '../interfaces/IColumn';
import { IFilter} from '../interfaces/IFilter';
import { FilterService } from '../services/FilterService';
import { SortService } from '../services/SortService';

@Component({
	selector: 'column-filter-checkbox',
	templateUrl: '../views/column-filter-checkbox.html'
})
export class ColumnFilterCheckboxComponent implements OnChanges{
	@Input() column: IColumn;
	@Input() filters: Array<IFilter>;
	private selectedFilters_: Array<boolean>;
	constructor(
		private filterService_: FilterService,
		private sortService_: SortService
	){

	}

	ngOnChanges(changes: SimpleChanges){
		const filters: SimpleChange = changes.filters.currentValue;
		this.selectedFilters_ = new Array<boolean>();
		_.each(filters, filter =>{
			this.selectedFilters_.push(false);
		})
	}

	setColumnOrder(){
		this.sortService_.setColumnToSort(this.column);
	}

	selectAll(){
		_.each(this.selectedFilters_,(value,i)=>{
			this.selectedFilters_[i] = true;
		})
	}

	clear(){
		_.each(this.selectedFilters_,(value,i)=>{
			this.selectedFilters_[i] = false;
		})
	}

	apply(){
		let filters = new Array<IFilter>();
		_.each(this.selectedFilters_,(value,i)=>{
			if(value) {
				this.filters[i].value = this.filters[i].value.toString().toLowerCase();
				filters.push(this.filters[i]);
			}
		})
		
		if(filters.length) 
			this.filterService_.addFilter({
				column: this.column,
				values: filters
			})
		else this.filterService_.deleteFilter({
				column: this.column,
				values: filters
			})
	}

}