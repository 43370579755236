import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[toggle-table-header]',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

    @Input('headers') headers;
    @Input('legende') legende;
    @Input('leftStyleType') leftStyleType;
    @Input('rightStyleType') rightStyleType;
    @Input('firstStyleType') firstStyleType;
    @Input('highlightedColumn') highlightedColumn;
    cellsNumber : number;
    toggled : boolean = false;
    @Output('toggleRows') toggleRows = new EventEmitter();
    @Input('totalColumn') totalColumn : boolean;

  constructor() { }

  ngOnInit() {
    this.cellsNumber = this.legende.length;
  }

  toggle(){
      this.toggled = !this.toggled;
      this.toggleRows.emit(this.toggled);
  }




}
