import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

	user: any;

  constructor(
  	public router : Router,
	) {
	}

  ngOnInit() {
  	this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
  	if(!this.user) {
  		this.router.navigate(['/login']);
  	}
  }

}
