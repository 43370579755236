import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges, DoCheck, AfterContentChecked
} from '@angular/core';
import { NgbTypeahead, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, merge, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'custom-input',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit, OnChanges, DoCheck, AfterContentChecked {

  constructor(private ref: ChangeDetectorRef) {
  }

  @Input('prop-name') propName = 'nom';
  @Input() type: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() role: string;
  @Input() id: string;
  @Input() lastDatepicker: boolean;
  @Input() list: any;
  @Input() listButtonAddValue: any;
  @Input() listButtonCancelValue = false;
  @Input() required: boolean;
  @Input() invalid: boolean;
  @Input() disabled = false;
  @Input() disabledHours = false;
  @Input() readOnly = false;
  @Input() valueExists: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() maxdate: any;
  @Input() mindate: any;
  @Input() min: number;
  @Input() max: number;
  @Input() unique = false;
  @Input() rows: number;
  @Input() unit: string;
  @Input() value: any;
  @Input() uppercaseControl: boolean;
  @Input() pattern: string;
  @Input() tooltip: string;
  @Input() tooltipPlacement: string;
  @Input() errorMessage: string;
  @Output() valueChange = new EventEmitter();
  @Output() navigateToAnotherForm = new EventEmitter();
  @Output() pipedBlur = new EventEmitter();

  @ViewChild('date', { static: false }) instanceDate: NgbInputDatepicker;
  @ViewChild('instanceTypeAhead', { static: false }) instanceTypeAhead: NgbTypeahead;

  focusTypeAhead$ = new Subject<string>();
  clickTypeAhead$ = new Subject<string>();

  private complexeData = false;
  private toggled = false;
  private composedData = false;
  public translateData = false;
  private selectedOptions: boolean[] = [];
  private selectedOptionsName: string[] = [];
  private oldValue;
  private timeValue;

  private classes = {
    toggled: this.toggled
  };

  /**
  *
  * Template for the results displayed in the list for typeaheads
  */
  resultFormatterComposedData = ((item: any) => {
    if (this.composedData && item[0] && item[1]) {
      return item[0] + ' - ' + item[1];
    } else if (this.composedData && item[0] && !item[1]) {
      return item[0];
    } else if (!this.composedData) {
      return item;
    }
  });

  inputFormatterComposedData = ((item: any) => {
    if (this.composedData && Array.isArray(item)) {
      return item[0];
    } else {
      return item;
    }
  });

  ngOnInit() {
    if (!this.tooltipPlacement) {
      this.tooltipPlacement = 'top';
    }
    if (this.role === 'date-time') {
      this.timeValue = new Date(this.value);
    }
    if (this.required && this.label) {
      this.label += '*';
    }
    if (this.role === 'list' && !this.list) {
      this.list = [];
    }
    if (this.list && this.list.length && (this.list[0]._id || this.list[0][this.propName])) {
      this.complexeData = true;
    } else if (this.list && this.list.length && Array.isArray(this.list[0])) {
      this.composedData = true;
    } else if (this.list && this.list.length && this.list[0].hasOwnProperty('id') && this.list[0].hasOwnProperty('label')) {
      this.translateData = true;
    }
    if (this.role === 'email') {
      this.pattern = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
    }
    if (this.role === 'number' && this.min >= 0 || this.role === 'cpc') {
      this.pattern = '[+-]?([0-9]*[.])?[0-9]+';
    }
    if (this.role === 'number-unsigned') {
      this.pattern = '[+-]?([0-9]*[.])?[0-9]+';
    }
    if ((this.role === 'list-multiple' || this.role === 'checkbox-multiple')
        && this.list && this.selectedOptions && this.selectedOptions.length === 0) {
      this.initalizeSelectedValues();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.list && changes.list.currentValue && changes.list.currentValue.length) {
      const grouped = changes.list.currentValue[0].options && (typeof changes.list.currentValue[0].options[0] == 'object');
      if (changes.list.currentValue[0]._id || changes.list.currentValue[0][this.propName] || grouped) {
        this.complexeData = true;
      }
      if (this.list && this.list.length && Array.isArray(this.list[0])) {
        this.composedData = true;
      }
      if (this.list && this.list.length && this.list[0].hasOwnProperty('id') && this.list[0].hasOwnProperty('label')) {
        this.translateData = true;
      }
      if ( (this.role === 'list-multiple' || this.role === 'checkbox-multiple') && this.selectedOptions && this.selectedOptions.length === 0) {
        this.initalizeSelectedValues();
      }
    }
    if (this.role === 'date-time') {
      this.timeValue = new Date(this.value);
    }
    if (changes.value && changes.value.currentValue) {
      this.oldValue = _.cloneDeep(this.value);
    }
    if (this.role === 'list-multiple' && this.list && this.selectedOptions) {
      this.initalizeSelectedValues();
    }
  }

  ngDoCheck() {
    if (!_.isEqual(this.oldValue, this.value)) {
      if (this.role === 'date-time') {
        this.timeValue = new Date(this.value);
      }
      this.oldValue = _.cloneDeep(this.value);
      if (this.list && this.list.length && (this.list[0]._id || this.list[0][this.propName])) {
        this.complexeData = true;
      }
      if (this.list && this.list.length && Array.isArray(this.list[0])) {
        this.composedData = true;
      }
      if (this.role === 'email') {
        this.pattern = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
      }
      if ((this.role === 'list-multiple' || this.role === 'checkbox-multiple')
          && this.list && this.selectedOptions && this.selectedOptions.length === 0) {
        this.initalizeSelectedValues();
      }
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  compareFn(complexeObject1: any, complexeObject2: any) {
    const id = complexeObject1 && complexeObject1._id ? '_id' : 'id';
    if (complexeObject1 && complexeObject2 && complexeObject1.nom && complexeObject1[id] === complexeObject2[id] && complexeObject2.nom) {
      return complexeObject1.nom === complexeObject2.nom;
    } else {
      return complexeObject1 && complexeObject2 ? _.isEqual(complexeObject1, complexeObject2) : complexeObject1 === complexeObject2;
    }
  }

  compareFnBasic(object1: any, object2: any) {
    return _.isEqual(object1, object2);
  }

  compareFnComposed(composedObject1: any, composedObject2: any) {
    return composedObject1 && composedObject2 ? composedObject1[0] === composedObject2[0] : composedObject1 === composedObject2;
  }

  /**
  * for checkboxes.
  * basically comparison between the initial value and the list of all the possible values
  */
  initalizeSelectedValues() {
    this.selectedOptions = [];
    this.selectedOptionsName = [];
    if (!this.list) {
      return;
    }
    this.list.forEach(option => {
      if (this.value.length) {
        if (option.hasOwnProperty('id') && option.hasOwnProperty('label')) {
          if (_.findIndex(this.value, option.id) >= 0 || this.value.includes(option.id)) {
            this.selectedOptions.push(true);
            this.selectedOptionsName.push(option.label);
          } else {
            this.selectedOptions.push(false);
          }
        } else {
          if (_.findIndex(this.value, option) >= 0 || this.value.includes(option)) {
            this.selectedOptions.push(true);
            this.complexeData ? this.selectedOptionsName.push(option[this.propName]) : this.selectedOptionsName.push(option);
          } else {
            this.selectedOptions.push(false);
          }
        }
      }
    });
  }

  validateInputUppercase(event: any) {
    if (this.uppercaseControl) {
      let char = event.which || event.keyCode;
      char = String.fromCharCode(char);
      char = _.deburr(char).toUpperCase();
      if (!/[A-Z'\-_\s0-9]/.test(char)) {
        event.preventDefault();
      }
    }
  }

  change(newValue: any) {
    this.value = newValue;
    if (newValue === 'add') {
      this.navigateToAnotherForm.emit(this.listButtonAddValue);
    }else if(typeof newValue == 'string' && newValue.indexOf("/") >=0){
      this.navigateToAnotherForm.emit(newValue);
    }
    else {
      if (this.uppercaseControl) {
        this.value = _.deburr(newValue).toUpperCase();
        newValue = _.deburr(newValue).toUpperCase();
      }
      if (this.role === 'number-unsigned') {
        newValue = newValue.endsWith('-') ? newValue.substring(0, newValue.length - 1) : newValue;
        newValue = newValue.match(/[+-]?([0-9]*[.])?[0-9]+/)[0] || undefined;
        this.value = newValue;
      }
      if (this.composedData && Array.isArray(newValue)) {
        this.valueChange.emit(newValue[0]);
      } else {
        this.valueChange.emit(newValue);
      }
    }
  }

  changeTime(newValue: any) {
    if (newValue && newValue instanceof Date) {
      this.value.setHours(newValue.getHours());
      this.value.setMinutes(newValue.getMinutes());
    }
  }

  /**
  * changeOptions - listening for changes in the checkboxes and emitting the new value
  */
  changeOptions(event?: any) {
    this.value = [];
    this.selectedOptionsName = [];
    for (let i = 0; i < this.selectedOptions.length; i++) {
      if (this.selectedOptions[i]) {
        this.value.push(this.list[i].id);
        if (this.complexeData) {
          this.selectedOptionsName.push(this.list[i][this.propName]);
        } else if (this.translateData) {
          this.selectedOptionsName.push(this.list[i].id);
        } else {
          this.selectedOptionsName.push(this.list[i]);
        }

      }
    }
    this.valueChange.emit(this.value);
  }

  validateInput(event: any) {
    // const whatDecimalSeparator = function () {
    //   const n = 1.1;
    //   return n.toLocaleString().substring(1, 2);
    // };
    let char = event.which || event.keyCode;
    char = String.fromCharCode(char);
    if (this.type === 'number' && !/[0-9.,]/.test(char)) {
      event.preventDefault();
    }
    if (this.role === 'number-unsigned' && !/[0-9.,-]/.test(char)) {
      event.preventDefault();
    }
  }

  validateInputNegatif(event: any) {
    // const whatDecimalSeparator = function () {
    //   const n = 1.1;
    //   return n.toLocaleString().substring(1, 2);
    // };
    let char = event.which || event.keyCode;
    char = String.fromCharCode(char);

    if (this.type === 'number' && !/[0-9.,-]/.test(char)) {
      event.preventDefault();
    }
  }


  /**
  *
  * Function to handle the typeahead instance
  */
  searchTypeAhead = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      merge(this.focusTypeAhead$),
      merge(this.clickTypeAhead$.pipe(filter(() => !this.instanceTypeAhead.isPopupOpen()))),
      map(term => (term === '' ? this.list : _.sortBy(this.list,
        c => {
          c = String(c);
          c = c.toLowerCase();
          let score = 0;
          const termArray = _.split(term.toLowerCase(), '');
          termArray.forEach(termChar => {
            if (c.indexOf(termChar) > -1) {
              score += 1;
            }
          });
          return -score;
        })
      )))

  toggleSelect(event: any, source: string): void {
    if (event.target.id === this.id && source === 'in' && !this.readOnly) {
      this.toggled = !this.toggled;

    } else if (source === 'out' && event.target.id !== this.id) {
      this.toggled = false;
    }
  }

  /**
  *Permet de gérer les click event pour fermer le popup calendrier lorsque l'on clique en dehors
  * @param event - $event depuis html
  * @param id - valeur de l'attribut id de l'élément visé
  */
  public handleClickDatepicker(event: any, id: string) {
    const element = document.getElementById(id);
    let clickedInside = false;
    if (element) {
      let el = event.target;
      while (el.parentElement) {
        if (el === element && !this.readOnly) {
          clickedInside = true;
          event.target.focus();
          break;
        }
        el = el.parentElement;
      }
    }
    if (!clickedInside) {
      if (this.instanceDate) { this.instanceDate.close(); }
      event.stopPropagation();
    } else {
      if (this.lastDatepicker) { event.stopPropagation(); }
    }

  }




}
