import { LoginService } from 'global/services/login/login.service';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  SimpleChanges,
  AfterViewInit,
  OnDestroy, OnChanges
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { CalendarComponent } from 'ng-fullcalendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventModalComponent } from './event-modal/event-modal.component';
import { CustomDatepickerI18n } from 'global/services/custom-datepicker-i18n/custom-datepicker-i18n';
import { PerimetreService } from 'global/services/perimetre/perimetre.service';
import { ReceptionService } from 'appro/services/reception/reception.service';
import { ReceptionPrevueService } from 'appro/services/reception-prevue/reception-prevue.service';
import { ReceptionPrevue } from 'appro/models/dto/reception-prevue';
import { Reception } from 'appro/models/dto/reception';
import * as _ from 'lodash';
import 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.scss']
})
export class CalendrierComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() events: any[] = [];
  @Input() site: any;
  @Input() dataType: string = "reception";
  @Input() view: string = "agendaWeek";
  @Output('ontitlechange') onTitleChanged: EventEmitter<any> = new EventEmitter();
  navigation: string;
  date: any = new Date();
  calendarOptions;
  @ViewChild(CalendarComponent, { static: false }) calendar: CalendarComponent;
  dateRangeString: string = '';
  viewChecked: boolean;
  timerId: any;
  loading;
  loadingMessage;
  readOnly = false;
  langSubscription;

  constructor(
    private datePipe: DatePipe,
    private dateService: CustomDatepickerI18n,
    private modalService: NgbModal,
    private receptionService: ReceptionService,
    private receptionPrevueService: ReceptionPrevueService,
    private perimetreService: PerimetreService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) {
    this.langSubscription = this.translateService.onLangChange.subscribe(lang => {
      this.calendarOptions = this.setCalendarOptions();
      if (this.calendar) {
        this.calendar.fullCalendar('option', this.calendarOptions);
      }
      this.loadData();
      this.dateRangeString = this.dateService.getMonthYearRange(this.date);
      this.onTitleChanged.emit(this.dateRangeString);
    });
  }

  ngOnInit() {
    this.loginService.getWriteAccessRight().then(accessRight => this.readOnly = !accessRight);
    this.dateRangeString = this.dateService.getMonthYearRange(this.date);
    this.onTitleChanged.emit(this.dateRangeString);
    this.calendarOptions = this.setCalendarOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.site) {
      const siteChange: SimpleChange = changes.site.currentValue;
      if (siteChange) this.loadData();
    }
  }

  ngAfterViewInit() {
    if (!this.viewChecked) {
      this.timerId = setInterval(() => {
        this.viewChecked = true;
        this.loadData();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

  setCalendarOptions() {
    return {
      navLinks: true,
      editable: true,
      eventLimit: true,
      header: false,
      defaultView: this.view,
      aspectRatio: 50,
      height: 'parent',
      weekNumbers: true,
      weekNumberCalculation: 'ISO',
      selectable: true,
      nowIndicator: true,
      firstDay: 1,
      views: {
        agendaWeek: {
          eventLimit: 2,
          minTime: "00:00:00",
          maxTime: "23:59:59"
        },
        month: {
          eventLimit: 2
        },
        day: {
          eventLimit: 2,
          minTime: "00:00:00",
          maxTime: "23:59:59"
        }
      },
      events: [],
      monthNames: this.translateService.instant('app.modules.global.components.calendrier.mois'),
      monthNamesShort: this.translateService.instant('app.modules.global.components.calendrier.moisShort'),
      dayNames: this.translateService.instant('app.modules.global.components.calendrier.jours'),
      dayNamesShort: this.translateService.instant('app.modules.global.components.calendrier.joursShort'),
      timeFormat: 'HH:mm',
      allDayText: this.translateService.instant('app.modules.global.components.calendrier.touteJournée'),
      eventLimitText: this.translateService.instant('app.modules.global.components.calendrier.enPlus'),
      noEventsMessage: this.translateService.instant('app.modules.global.components.calendrier.aucunEvent'),
      weekNumberTitle: this.translateService.instant('app.modules.global.components.calendrier.semaineShort'),
      slotLabelFormat: 'HH(:mm)'
    };
  }

  eventClicked(event) {
    this.loadEvent(event.event)
      .then(res => {
        const modal = this.modalService.open(EventModalComponent, { size: 'lg' });
        modal.componentInstance.eventType = event.event.dataType;
        modal.componentInstance.event = res;
        modal.result
          .then(res => this.loadData())
          .catch(error => console.log(error));
      });
  }

  eventDragStart(event) {
    if (!this.readOnly) {
      this.loadEvent(event.event).then(res => {
        switch (res.dataType) {
          case "receptionPrevue":
            res.dateReception = event.event.start._d.getTime() + event.event.start._d.getTimezoneOffset() * 60 * 1000;
            let recepPrevue = new ReceptionPrevue();
            _.merge(recepPrevue, res);
            this.receptionPrevueService.update(recepPrevue).then(res => 0);
            break;
          default:
            res.dateReception = event.event.start._d.getTime() + event.event.start._d.getTimezoneOffset() * 60 * 1000;
            let recep = new Reception();
            _.merge(recep, res);
            this.receptionService.update(recep).then(res => 0);
            break;
        }
      })
    }
  }

  dayClicked(event) {
    if (!this.readOnly) {
      const modal = this.modalService.open(EventModalComponent, { size: 'lg' });
      modal.componentInstance.eventType = this.dataType;
      modal.componentInstance.date = event.date._d;
      if (this.site) modal.componentInstance.event.site = this.site
      modal.result
        .then(res => this.loadData())
        .catch(error => console.log(error))
    }
  }

  goToDate(date) {
    if (date) {
      this.date = date;
      this.calendar.fullCalendar('gotoDate', this.date);
      this.calendar.fullCalendar('render');
      this.changeTitle();
      this.loadData();
    }
  }

  setView(view) {
    this.view = view;
    if (this.date) this.calendar.fullCalendar('gotoDate', this.date);
    this.calendar.fullCalendar('changeView', view);
    this.calendar.fullCalendar('render');
    this.changeTitle();
    this.loadData();
  }

  setDirection(direction) {
    this.calendar.fullCalendar(direction);
    this.changeTitle();
    this.loadData();
  }

  private changeTitle() {
    if (this.calendar) {
      let view = this.calendar.fullCalendar('getView');
      if (this.view === 'agendaWeek') {
        this.dateRangeString = this.dateService.getWeekAndDaysRange(view.start._d, view.end._d);
      } else if (this.view === "month") {
        this.dateRangeString = view.title;
      } else {
        this.dateRangeString = this.dateService.getDayRange(view.start._d);
      }
      this.onTitleChanged.emit(this.dateRangeString);
    }
  }

  private loadEvent(event: any) {
    let help;
    switch (event.dataType) {
      case "receptionPrevue":
        help = this.receptionPrevueService.get(event.id)
          .then(recep => {
            if (recep.dateReception) recep.dateReception = new Date(recep.dateReception);
            return recep;
          });
        break;
      default:
        help = this.receptionService.get(event.id)
          .then(recep => {
            if (recep.dateReception) recep.dateReception = new Date(recep.dateReception);
            return recep;
          });
        break;
    }
    return help;
  }

  private loadData() {
    if (this.calendar && this.viewChecked) {
      this.loading = true;
      this.loadingMessage = this.translateService.instant('app.global.chargement');
      clearInterval(this.timerId);
      this.calendar.fullCalendar('removeEvents');
      let promises = [];
      let view = this.calendar.fullCalendar('getView');
      let start = this.datePipe.transform(view.start._d, "yyyy/MM/dd");
      let end = this.datePipe.transform(view.end._d, "yyyy/MM/dd");
      if (this.dataType != "evenement") {
        if (this.site.dataType == 'site') {
          promises.push(this.receptionService.byDate(this.site.id, start, end));
          promises.push(this.receptionPrevueService.byDate(this.site.id, start, end));
        }
        Promise.all(promises)
          .then(result => {
            this.events = [];
            result.forEach(res => {
              let help = res.rows.map(event => {
                event = event.doc;
                switch (event.dataType) {
                  case 'receptionPrevue': {
                    let dateEndPrevue = new Date(event.dateReception);
                    dateEndPrevue.setMinutes(dateEndPrevue.getMinutes() + 30);
                    return {
                      id: event._id,
                      dataType: event.dataType,
                      start: this.datePipe.transform(new Date(event.dateReception), "yyyy-MM-dd HH:mm"),
                      end: this.datePipe.transform(dateEndPrevue, "yyyy-MM-dd HH:mm"),
                      title: event.gisement.denominationProduit + ' (' + event.poidsNet + ' ' + this.translateService.instant('app.global.tonnes') + ')',
                      className: ['e-recep-prevue', 'event']
                    }
                  }
                  default: {
                    let dateEnd = new Date(event.dateReception);
                    dateEnd.setMinutes(dateEnd.getMinutes() + 30);
                    return {
                      id: event._id,
                      dataType: event.dataType,
                      start: this.datePipe.transform(new Date(event.dateReception), "yyyy-MM-dd HH:mm"),
                      end: this.datePipe.transform(dateEnd, "yyyy-MM-dd HH:mm"),
                      title: event.gisement.denominationProduit + ' (' + event.poidsNet + ' ' + this.translateService.instant('app.global.tonnes') + ')',
                      className: ['e-recep', 'event']
                    }
                  }
                }
              })
              this.loading = false;
              this.events = this.events.concat(help);
            })
          })
      }

    }

  }

}
