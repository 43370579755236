import { BiogasviewDocument } from './../biogasview-document';
import { UtilisateurOrganisation } from './utilisateur-organisation';
import { Site } from './site';
export class Organisation extends BiogasviewDocument{
	public nom : string;
	public sites : Site[];
	public utilisateurs : UtilisateurOrganisation[];
	constructor(){
		super();
		this.dataType = 'organisation';
	}
}