import { BiogasviewDocument } from './../biogasview-document';
import { Adresse } from './../adresse';
import { Site } from './site';
import { Module } from '../module';
import { Organisation } from './organisation';

export class Utilisateur extends BiogasviewDocument{
	email: string ="";
	password: string = "";
	prenom: string = "";
	nom: string = "" ;
	numeroPortable: string = "";
	numeroFixe: string = "";
	adresse: Adresse = new Adresse();
	langue: string;
	lignesParPage: number;
	sites: Site[] = new Array<Site>();
	role: string;
	accessOrga: boolean = false;
	modules: Module = new Module();
	organisations : Organisation[] = [];
	constructor(){
		super();
		this.dataType = 'utilisateur';
	}
}
