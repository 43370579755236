import { SiteDocumentDAO } from 'global/models/dao/site-document';

export class TacheDAO extends SiteDocumentDAO {
  public nom: string;
  public description: string;
  public tacheJournee: boolean;
  public dateDebut: any;
  public dateFin: any;
  public couleur: string;
  public categorie: string;
  public getCouleur: boolean;
  public intervenant: any;
  public statut: string;

  constructor() {
    super();
    this.dataType = 'tache';
  }
}