import { Component, Input} from '@angular/core';
import * as _ from 'lodash';
import { IColumn } from '../interfaces/IColumn';
import { IFilter } from '../interfaces/IFilter';
import { FilterService } from '../services/FilterService';
import { SortService } from '../services/SortService';

@Component({
	selector: 'column-filter-dropdown',
	templateUrl: '../views/column-filter-dropdown.html'
})
export class ColumnFilterDropDownComponent{
	@Input() column: IColumn;
	@Input() filters: Array<IFilter>;
	private selectedFilter_: string;
	constructor(
		private filterService_: FilterService,
		private sortService_: SortService
	){
		this.selectedFilter_ = "";
	}

	setColumnOrder(){
		this.sortService_.setColumnToSort(this.column);
	}

	clear(){
		this.selectedFilter_ = "";
	}

	apply(){
		
		if(this.selectedFilter_ != ""){
			let filters = new Array<IFilter>();
			_.each(this.filters,(filter: IFilter)=>{
				if(filter.value == this.selectedFilter_) filters.push(filter);
			})
			this.filterService_.addFilter({
				column: this.column,
				values: filters
			})
		} 
		else this.filterService_.deleteFilter({
				column: this.column,
				values: []
			})
	}
}