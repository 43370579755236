import { Component, Output, EventEmitter } from '@angular/core';
import { DataService } from '../services/DataService';
import { IRow } from '../interfaces/IRow';

@Component({
  selector: '[table-rows]',
  templateUrl: '../views/table-rows.html',
})

export class TableRowsComponent{

	@Output('onselectrow') onSelectRow: EventEmitter<any> = new EventEmitter();
	@Output('onclickrow') onClickRow : EventEmitter<any> = new EventEmitter();

  constructor(public dataService: DataService){

  }

  rowSelected(rows: Array<IRow>){
  	this.onSelectRow.emit(rows);
  }

  rowClicked(row : IRow){
  	this.onClickRow.emit(row);
  }
}