import { SiteDocument } from 'global/models/dto/site-document';
import { Adresse } from 'global/models/adresse';
import { Contact } from 'global/models/contact';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import { TiersDAO } from 'tracabilite/models/dao/tiers';
import * as _ from 'lodash';

export class Tiers extends SiteDocument implements IDto2Dao, IBGVTreeNode {
	public codeTiers: string;
	public nom: string;
	public adresse: Adresse = new Adresse();
	public typeTiers: string[] = [];
	public typeActivite: string[] = [];
	public contactFacturation: Contact = new Contact();
	public contact: Contact = new Contact();
	public contact2: Contact = new Contact();
	public siret: string;
	public numTVA: string;
	public numAgrementSanitaire: string;
	public domiciliationBancaire: string;
	public rib: string;
	public bic: string;
	public iban: string;
	public numProducteur: string;
	public numCompteClient: string;
	public numCompteFournisseur: string;
	public envoiFacture: string;
	public numRecepisse: string;
	public limiteValidite: any;
	public commentaires: string;
	public notDisplayInDropdowns: boolean = false;
	public destinatairesFacture: any[];
	public destinatairesLogistique: any[];
	public sameAdresse : boolean = true;
	public adresseFacturation : Adresse = new Adresse();

	constructor() {
		super();
		this.dataType = 'tiers';
		this.envoiFacture = "Par courrier";
		this.destinatairesFacture = [];
		this.destinatairesLogistique = [];
	}

	toDao(): TiersDAO {
		let tiers = new TiersDAO();
		Object.keys(this)
			.forEach(key => {
				if (key == "site") this[key] ? tiers[key] = this[key]._id || this[key].id : tiers[key] = null;
				else if (key == "limiteValidite") {
					if (this[key]) {
						let date = new Date(this[key]);
						tiers[key] = date.getTime();
					}
				}
				else {
					tiers[key] = this[key];
				}
			});

		return tiers;
	}

	toNodes(parentId?: number, parentName?: any): any {
		let keyNames = [
			{ 'label': 'Site', 'dataKey': 'site.nom' },
			{ 'label': 'Nom', 'dataKey': 'nom' },
			{ 'label': 'Code tiers', 'dataKey': 'codeTiers' },
			{ 'label': 'Adresse-Adresse 1', 'dataKey': 'adresse.adresse' },
			{ 'label': 'Adresse-Adresse 2', 'dataKey': 'adresse.complementAdresse' },
			{ 'label': 'Adresse-Code postal', 'dataKey': 'adresse.codePostal' },
			{ 'label': 'Adresse-Commune', 'dataKey': 'adresse.commune' },
			{ 'label': 'Adresse-Pays', 'dataKey': 'adresse.pays' },
			{ 'label': 'Type de tiers', 'dataKey': 'typeTiers' },
			{ 'label': 'Type d\'activité', 'dataKey': 'typeActivite' },
			{ 'label': 'Contact principal-Nom', 'dataKey': 'contact.nom' },
			{ 'label': 'Contact principal-Téléphone', 'dataKey': 'contact.numeroPortable' },
			{ 'label': 'Contact principal-Email', 'dataKey': 'contact.email' },
			{ 'label': 'Contact logistique-Nom', 'dataKey': 'contact2.nom' },
			{ 'label': 'Contact logistique-Téléphone', 'dataKey': 'contact2.numeroPortable' },
			{ 'label': 'Contact logistique-Email', 'dataKey': 'contact2.email' },
			{ 'label': 'Contact logistique-Autres emails', 'dataKey': '' },
			{ 'label': 'Contact facturation-Nom', 'dataKey': 'contactFacturation.nom' },
			{ 'label': 'Contact facturation-Téléphone', 'dataKey': 'contactFacturation.numeroPortable' },
			{ 'label': 'Contact facturation-Email', 'dataKey': 'contactFacturation.email' },
			{ 'label': 'Siret', 'dataKey': 'siret' },
			{ 'label': 'Numéro de TVA', 'dataKey': 'numTVA' },
			{ 'label': 'Numéro d\'agrément sanitaire', 'dataKey': 'numAgrementSanitaire' },
			{ 'label': 'Domiciliation bancaire', 'dataKey': 'domiciliationBancaire' },
			{ 'label': 'Code RIB', 'dataKey': 'rib' },
			{ 'label': 'Code BIC', 'dataKey': 'bic' },
			{ 'label': 'Code IBAN', 'dataKey': 'iban' },
			{ 'label': 'Numéro de producteur', 'dataKey': 'numProducteur' },
			{ 'label': 'Numéro de compte client (PCG)', 'dataKey': 'numCompteClient' },
			{ 'label': 'Numéro de compte fournisseur (PCG)', 'dataKey': 'numCompteFournisseur' },
			{ 'label': 'Méthode d\'envoi de facture', 'dataKey': 'envoiFacture' },
			{ 'label': 'Adresse facturation-Adresse 1', 'dataKey': 'adresseFacturation.adresse' },
			{ 'label': 'Adresse facturation-Adresse 2', 'dataKey': 'adresseFacturation.complementAdresse' },
			{ 'label': 'Adresse facturation-Code postal', 'dataKey': 'adresseFacturation.codePostal' },
			{ 'label': 'Adresse facturation-Commune', 'dataKey': 'adresseFacturation.commune' },
			{ 'label': 'Numéro de recepisse', 'dataKey': 'numRecepisse' },
			{ 'label': 'Date limite recepisse', 'dataKey': 'limiteValidite' },
			{'label': 'Désactivé', 'dataKey': 'notDisplayInDropdowns'},
			{ 'label': 'Commentaires', 'dataKey': 'commentaires' },
		]
		let index = 0;
		return keyNames.map(key => {
			let node: any = {},
				id = parentId ? parentId + index + 1 : index;
			node = {
				id: id,
				name: key.label,
				dataKey: key.dataKey
			}
			index++;
			return node;
		})

	}

}
