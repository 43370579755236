import { SiteDocument } from 'global/models/dto/site-document';
import { CaracteristiquePhysicoChimique } from './../caracteristique-physico-chimique';
import { Tiers } from './tiers';
import { StockIntrant } from 'intrants/models/dto/stock-intrant';
import { OuvrageIncorporation } from 'shared-ration/models/ouvrage-incorporation';
import { Digesteur } from 'shared-ration/models/digesteur';
import { AnalyseLabo } from './analyse-labo';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { GisementDAO } from 'tracabilite/models/dao/gisement';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class Gisement extends SiteDocument implements IDto2Dao, IBGVTreeNode{

  public codeGisement  : string;
  public denominationProduit : string;
  public nom  : string;
  public type : string;
  public categorie  : string;
  public codeDechet  : string;
  public consistance  : string;
  public conditionnement : string;
  public modeDeLivraison  : string;
  public sousProduitsAnimaux  : string;
  public documentAEmettre : string;
  public tags  : string[];
  public stockIntrant : StockIntrant;
  public ouvrageIncorporation : OuvrageIncorporation;
  public digesteur : Digesteur;
  public producteur : Tiers;
  public transporteur : Tiers;
  public negociant : Tiers;
  public codeContrat : string;
  public cpc : CaracteristiquePhysicoChimique[] ;
  public analyses : AnalyseLabo[];
  public dateDerniereAnalyse : any;
  public numCAP: string;
  public typeFacturation : string[];
  public notDisplayInDropdowns : boolean = false;
  public distanceSiteProduction: number;
  public commentaires : string;
  public delaiIncorporation: number;
  public numLot: string;
  public traitementSubi: string;
  public traitementAEffectuer: string;
  public durabilite: string;
  public certification: string;
  public certifieDurable: boolean;

  constructor(){
  	super();
  	this.dataType = 'gisement';
    this.typeFacturation = ['Aucune'];
    this.analyses = [];
    this.certifieDurable = false;
  }

  public toDao() : GisementDAO{
      let gisement: GisementDAO = new GisementDAO();
      Object.keys(this)
        .forEach(key => {
          const id = this[key] ? this[key]._id || this[key].id : undefined;
          if(["transporteur","producteur","negociant","stockIntrant"].includes(key) && this[key]) gisement[key] = this[key]._id;
          else if(key == "ouvrageIncorporation" && this[key]) gisement[key] = this[key].id;
          else if(key == "digesteur" && this[key]) gisement[key] = this[key].id;
          else if(key == "site" && id && id.indexOf('site_')>=0) gisement[key] = id;
          else if(key == "analyses"){
            gisement[key] = [];
            this[key].forEach((analyse:any) => {
                analyse && analyse._id? gisement[key].push(analyse._id) : gisement[key].push(analyse);
            })
          }
          else {
            gisement[key]= this[key];
          }
        });

      return gisement.site ? gisement : undefined;
  }

  toNodes(parentId?: number, parentName?: any): any{
    let keyNames = [
      {'label': 'app.global.headers.site', 'dataKey': 'site.nom'},
      {'label': 'app.global.headers.nom', 'dataKey': 'nom'},
      {'label': 'app.global.headers.codeGisement', 'dataKey': 'codeGisement'},
      {'label': 'app.global.headers.denominationProduit', 'dataKey': 'denominationProduit'},
      {'label': 'app.global.headers.categorie', 'dataKey': 'categorie'},
      {'label': 'app.global.headers.codeDechet', 'dataKey': 'codeDechet'},
      {'label': 'app.global.headers.consistance', 'dataKey': 'consistance'},
      {'label': 'app.global.headers.modeDeLivraison', 'dataKey': 'modeDeLivraison'},
      {'label': 'app.global.headers.sousProduitsAnimaux', 'dataKey': 'sousProduitsAnimaux'},
      {'label': 'app.global.headers.documentAEmettre', 'dataKey': 'documentAEmettre'},
      {'label': 'app.global.headers.tags', 'dataKey': 'tags'},
      {'label': 'app.global.headers.stockIntrant', 'dataKey': 'stockIntrant.nom'},
      {'label': 'app.global.headers.ouvrage','dataKey': 'ouvrageIncorporation.nom'},
      {'label': 'app.global.headers.producteur', 'dataKey': 'producteur.nom'},
      {'label': 'app.global.headers.transporteurHabituel', 'dataKey': 'transporteur.nom'},
      {'label': 'app.global.headers.negociant', 'dataKey': 'negociant.nom'},
      {'label': 'app.global.headers.codeContrat', 'dataKey': 'codeContrat'},
      {'label': 'app.global.headers.dateDerniereAnalyse', 'dataKey': 'dateDerniereAnalyse'},
      {'label': 'app.global.headers.commentaire', 'dataKey': 'commentaires'},
      {'label': 'app.global.headers.conditionnement', 'dataKey': 'conditionnement'},
      {'label': 'app.global.headers.durabilite', 'dataKey': 'durabilite'},
      {'label': 'app.global.headers.certification', 'dataKey': 'certification'},
      {'label': 'app.global.headers.delaiIncorporationPrevu%', 'dataKey': 'delaiIncorporation'},
      {'label': 'app.global.headers.desactive', 'dataKey': 'notDisplayInDropdowns'},
      {'label': 'app.global.headers.distanceSiteProduction%', 'dataKey': 'distanceSiteProduction'},
      {'label': 'app.global.headers.matiereOrganiqueMB', 'dataKey': 'MO/MB'},
      {'label': 'app.global.headers.matiereSecheMB', 'dataKey': 'MS/MB'},
      {'label': 'app.global.headers.potentionMéthaneOrganiqueMB', 'dataKey': 'PM/MB stand'},
      {'label': 'app.global.headers.azotetotal', 'dataKey': 'Ntot'},
      {'label': 'app.global.headers.nCap', 'dataKey': 'numCAP'},
      {'label': 'app.global.headers.nLotEnCours', 'dataKey': 'numLot'},
      {'label': 'app.global.headers.type', 'dataKey': 'type'},
      {'label': 'app.global.headers.typeFacturation', 'dataKey': 'typeFacturation'},
      {'label': 'Traitement subi', 'dataKey': 'traitementSubi'},
      {'label': 'Traitement à effectuer', 'dataKey': 'traitementAEffectuer'}
    ];
    let index = 0;
    return keyNames.map(key =>{
      let node: any = {},
      id = parentId ? parentId+index+1 : index;
      node = {
        id: id,
        name: key.label,
        dataKey: key.dataKey
      }
      index++;
      return node;
    })
  }

  private nodeLength(node: any){
    if(!node.children) return 1;
    else return 1 + node.children.map(n => this.nodeLength(n)).reduce((a,b)=>a+b);
  }
}
