import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes = [
	{
		path: 'suivi',
		loadChildren: './modules/suivi/suivi.module#SuiviModule'
	},
	{
		path: 'rondesExploit',
		loadChildren: './modules/rondes/rondes.module#RondesModule'
	},
	{
		path: 'journal',
		loadChildren: './modules/journal/journal.module#JournalModule'
	},
	{
		path: 'materiel',
		loadChildren: './modules/materiel/materiel.module#MaterielModule'
	}
];

@NgModule({
	exports: [
		RouterModule
	],
	imports: [
		RouterModule.forRoot(routes, { useHash: true }),
	],
	declarations: []
})
export class ExploitationRoutingModule { }
