import {Component, Input} from '@angular/core';
import {ITableHeader} from '../interfaces/ITableHeader';

@Component({
  selector: 'table-header',
  templateUrl: '../views/table-header.html',
})
export class TableHeaderComponent{
	@Input('table-header')
  tableHeader: ITableHeader;

  isEnabled(){
    return this.tableHeader ? true : false;
  }
}