import { Component, OnInit } from '@angular/core';
import { PasswordService } from '../../../modules/global/services/password/password.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {

  public _confirmpassword: string;
  public _password: string;
  public _showMessage = false;
  public _invalidConfirmation = false;
  user: any;
  
  constructor(    
    public passwordService: PasswordService,
    public _router: Router,
    ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    if(!this.user) {
      this._router.navigate(['login']);
    }
  }

  changePassword() {
    if(this._confirmpassword !== this._password) this._invalidConfirmation = true;
    else {
      this.passwordService.changePassword(this.user.email, this._password, this._confirmpassword)
        .subscribe((res:any) => {
          if(res != undefined && !res.error) this._router.navigate(['login']);
          else this._showMessage = true;
        })
    }
  }

}
