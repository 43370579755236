import { Evenement } from './../models/dto/evenement';
import { Categorie } from './../models/dto/categorie';
import { Site } from 'global/models/dto/site';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';

import { defer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class EvenementService extends CommonDatabaseQueryService {
  constructor(_dbService: DatabaseService) {
    super(_dbService, {
      all: 'Evenement/all',
      joins: 'Evenement/joins',
      by_site: 'Evenement/by_site',
      by_date: 'Evenement/by_date',
      by_date_and_site: 'Evenement/by_date_and_site',
    });
  }

  headers = [
    { nom: "dateDebutTable", label: "Date de début" },
    { nom: "nom", label: "Nom" },
    { nom: "description", label: "Description" },
    { nom: "categorie.nom", label: "Categorie" },
    { nom: "attachements", label: "PJ" },
  ]

  public joins(docId: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
    return super.joins(docId, skip, limit, desc)
      .then(res => {
        let evenement: Evenement;
        let evenements = new Array<Evenement>();
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0) {
            delete res.rows[i].doc.siteId;
            evenement = res.rows[i].doc as Evenement;
            evenements.push(evenement);
          }
          else if (res.rows[i].key[1] == 1) evenement.categorie = res.rows[i].doc as Categorie;
          else if (res.rows[i].key[1] == 2) evenement.site = res.rows[i].doc as Site;
        }
        return evenements;
      })
      .catch(error => defer(() => Promise.reject(error)))
  }

  public bySite(id) {
    return super.bySite(id).then(res => {
      let evenements = new Array<Evenement>();
      let categories = new Array<Categorie>();
      let sites = new Array<Site>();
      for (let i = 0; i < res.rows.length; i++) {
        if (res.rows[i].key[1] == 0) evenements.push(res.rows[i].doc as Evenement);
        else if (res.rows[i].key[1] == 1) categories.push(res.rows[i].doc as Categorie);
        else if (res.rows[i].key[1] == 2) sites.push(res.rows[i].doc as Site);
      }
      for (let i = 0; i < evenements.length; i++) {
        evenements[i].categorie = categories.find(categorie => { return categorie && categorie._id == evenements[i].categorie as any; });
        evenements[i].site = sites.find(site => { return site._id == evenements[i].site as any; });
      }
      return _.sortBy(evenements, 'nom');
    })
      .catch(error => defer(() => Promise.reject(error)))
  }

  public byDateAndSite(start: string, end: string, site: any = undefined) {
    let options: any = {
      include_docs: true
    }
    let promise = undefined;
    if (site) {
      options.startkey = [site, start, 0];
      options.endkey = [site, end, 2];
      promise = this._dbService.db.query('Evenement/by_date_and_site', options);
    }
    else {
      options.startkey = [start, 0];
      options.endkey = [end, 2];
      promise = this._dbService.db.query('Evenement/by_date', options);
    }
    return promise.then(res => {
      let evenements = new Array<Evenement>();
      let categories = new Array<Categorie>();
      let sites = new Array<Site>();
      res.rows.forEach(row => {
        if (row.key[2] == 0 || row.key[1] == 0) evenements.push(row.doc as Evenement);
        else if (row.key[2] == 1 || row.key[1] == 1) categories.push(row.doc as Categorie);
        else if (row.key[2] == 2 || row.key[1] == 2) sites.push(row.doc as Site);
      })
      for (let i = 0; i < evenements.length; i++) {
        evenements[i].categorie = categories[i];
        evenements[i].site = sites[i];
      }
      res.rows = _.sortBy(evenements, [function (event) { return - event.dateDebut; }]);
      return res.rows;
    })
      .catch(error => defer(() => Promise.reject(error)));
  }

  public documents(id: string): any {
    return this._dbService.db
      .query(
        'GedDocument/by_documents',
        {
          include_docs: true,
          key: id
        }
      )
      .then(res => res.rows.map(d => d.doc))
      .catch(error => defer(() => Promise.reject(error)))
  }


  public create(evenement: Evenement) {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    evenement.createdBy = user.email;
    evenement.modifiedBy = user.email;
    evenement.createdOn = new Date().getTime();
    evenement.modifiedOn = new Date().getTime();
    if (evenement._id == undefined) evenement._id = 'evenement_' + uuid();
    return this._dbService.db.put(evenement.toDao());
  }

  public upSert(evenement: Evenement): any {
    if (evenement._id && evenement._rev) return this.update(evenement);
    else return this.create(evenement);
  }

  public update(evenement: Evenement) {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    evenement.modifiedBy = user.email;
    evenement.modifiedOn = new Date().getTime();
    return this._dbService.db.get(evenement._id).then(doc => {
      evenement._rev = doc._rev;
      return this._dbService.db.put(evenement.toDao())
    });
  }

  public delete(evenement: Evenement) {
    return this._dbService.db.get(evenement._id).then(doc => {
      evenement._rev = doc._rev;
      return this._dbService.db.remove(evenement);
    });
  }

  public bulkDelete(evenement: Evenement[]) {
    let promises = [];
    promises = evenement.map(evenement => this.delete(evenement));
    return Promise.all(promises);
  }

  public bulkCreate(evenements: Evenement[]) {
    let promises = [];
    evenements.map(evenement => {
      this.create(evenement)
    });
    return Promise.all(promises)
  }
}