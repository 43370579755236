import { Injectable } from '@angular/core';

import { DatabaseService } from './../database/database.service';

export class CommonDatabaseQueryService {

	constructor(
		protected _dbService: DatabaseService,
		private _views: any
	) {
		if (!this._dbService.db) this._dbService.getDatabaseFromSession();
	}

	/**
	* Remove a document from database
	* @param doc - document with _id and _rev
	*/
	public delete(doc: any) {
		return this._dbService.db.remove(doc)
	}
	/**
	  * List
	  * @param skip - number of rows to skip
	  * @param limit - max number of rows to return
	  */
	public fetchAll(site: string = undefined, skip: number = 0, limit: number = 100, desc: boolean = false): any {
		let options: any = {
			skip: skip,
			descending: desc,
			include_docs: true
		}
		options.limit = limit ? limit : undefined;
		options.key = site ? site : undefined;
		return this._dbService.query(
			this._views.fetch_all, options
		);
	}
	/**
	  * List
	  * @param skip - number of rows to skip
	  * @param limit - max number of rows to return
	  */
	public list(site: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
		let options: any = {
			skip: skip,

			limit: limit,
			descending: desc,
			include_docs: true
		}
		if (site != undefined) {
			options.startkey = [site],
				options.endkey = [site, {}];
		}
		return this._dbService.query(
			this._views.all, options
		);
	}
	/**
	* Find according mango selector
	* @param selector - a mango selector
	*/
	public find(skip: number = 0, limit: number = 10000, sort: string[] = [], selector: any): any {
		return this._dbService.db.find({
			use_index: this._views.all,
			skip: skip,
			limit: limit,
			sort: sort,
			selector: selector
		})
	}
	/**
	* Get by id
	* @param id - reception id
	*/
	public get(id: string): any {
		return this._dbService.db.get(id);
	}
	/**
	* Joins
	* @param skip - number of rows to skip
	* @param limit - max number of rows to return
	*/
	public joins(docId: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
		let options: any = {
			skip: skip,
			limit: limit,
			descending: desc,
			include_docs: true
		}
		if (docId != undefined) {
			options.startkey = [docId],
				options.endkey = [docId, {}];
		}
		return this._dbService.query(
			this._views.joins, options
		);
	}
	/**
	* bySite
	* @param skip - number of rows to skip
	* @param limit - max number of rows to return
	*/
	public bySite(id: string, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
		let options: any = {
			skip: skip,
			limit: limit,
			descending: desc,
			include_docs: true
		}
		if (id != undefined) {
			options.startkey = [id],
				options.endkey = [id, {}];
		}
		return this._dbService.query(
			this._views.by_site, options
		);
	}
	/**
	* byEvent
	* @param skip - number of rows to skip
	* @param limit - max number of rows to return
	*/
	public byEvent(id: string, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
		let options: any = {
			skip: skip,
			limit: limit,
			descending: desc,
			include_docs: true
		}
		if (id != undefined) {
			options.startkey = [id],
				options.endkey = [id, {}];
		}
		return this._dbService.query(
			this._views.by_event, options
		);
	}
	/**
	* linkedDocuments
	*/
	public linkedDocuments(id: string): any {
		return this._dbService.query(
			this._views.linked_documents,
			{ key: id, include_docs: true }
		);
	}
}
