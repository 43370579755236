import { Component, OnInit, HostListener } from '@angular/core';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PerimetreService } from 'global/services/perimetre/perimetre.service';
import { map, merge, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

    private breadcrumb;
    private breadcrumbSubscription;
    private perimetreSubscription;

  constructor(private breadcrumbService : BreadcrumbService, private perimetreService : PerimetreService, private router : Router,private route : ActivatedRoute, private location : Location) { }

  ngOnInit() {
      this.perimetreSubscription = this.perimetreService.perimetre$.subscribe( _perimetre => {
          if(_perimetre) this.breadcrumbService.changePerimetre({label : _perimetre.nom, route : 'home-board'})
      });
      this.breadcrumbSubscription = this.breadcrumbService.breadcrumb.subscribe( _breadcrumb => {
          this.breadcrumb = _breadcrumb;
      });
  }

  /**
  * @param breadcrumbItem : {label : string, route : string}
  * Navigate to the specified route, and update the breadcrumb accordingly
  */
  navigate(breadcrumbItem : any, index : number){
      if(index == this.breadcrumb.length -2){
          this.breadcrumbService.removeLast();
          breadcrumbItem.filter ? this.router.navigate([breadcrumbItem.route]) : this.router.navigate([breadcrumbItem.route])
          //index == 0 ? this.router.navigate([breadcrumbItem.route]) : this.location.back();
      }
      else if(index < this.breadcrumb.length - 1){
          this.breadcrumbService.removeFrom(index);
          // this.location.replaceState(breadcrumbItem.route);
          this.router.navigate([breadcrumbItem.route]);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      sessionStorage.setItem('tmpBreadcrumb',JSON.stringify(this.breadcrumb));
  }

  ngOnDestroy(){
      this.perimetreSubscription.unsubscribe();
      this.breadcrumbSubscription.unsubscribe();
  }

}
