import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportTicketModalComponent } from './../support-ticket-modal/support-ticket-modal.component';
import { BreadcrumbService } from './../../../modules/global/services/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';
import { SupportTicketService } from './../support-ticket.service';
import { ParametresService } from './../../../modules/global/services/parametres/parametres.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-ticket-add',
  templateUrl: './support-ticket-add.component.html',
  styleUrls: ['./support-ticket-add.component.scss']
})
export class SupportTicketAddComponent implements OnInit {

  modules = [
    "Approvisionnements",
    "Intrants",
    "Rations",
    "Digestats",
    "Epandages",
    "Facturation",
    "Suivi d'explotation",
    "Performances",
    "Rondes",
    "Autre problème"
  ];

  priorite = [
    "Normale",
    "Elevée",
    "Bloquante"
  ];

  sites = []

  isLoading = false;
  loadingMessage: string = 'Chargement';


  onglets = [];
  displayOnglets = false;

  ticketImages = [];
  ticketFiles = [];
  imagesSize = [];

  userEmail = '';
  userOrga = '';
  user;
  organisation;

  supportTicket: any = {};
  private invalidSubmitted = false;
  private bloc: any = {};
  private blocModules: any = [];

  constructor(
    private parametresService: ParametresService,
    private supportTicketService: SupportTicketService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.bloc = {
      id: "support",
      label: "Support",
      modules: [
        { id: "tickets", label: "Mes tickets", readWrite: 1 },
        // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
      ]
    };
    this.blocModules = [
      { id: "tickets", label: "Mes tickets", readWrite: 1 },
      // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
    ];
    this.breadcrumbService.add({ label: "Nouveau ticket", route: this.router.routerState.snapshot.url });
    this.userEmail = sessionStorage.getItem('user');
    this.organisation = JSON.parse(sessionStorage.getItem('organisation'));
    let sites = JSON.parse(sessionStorage.getItem('sites'));
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    if (this.user.accessOrga) {
      this.sites.push("Tous les sites")
    }
    for (let i = 0; i < sites.length; i++) {
      this.sites.push(sites[i].nom)
    }
    this.userOrga = this.organisation.nom;
    this.supportTicket.email = this.userEmail;
    this.supportTicket.orga = this.userOrga;
    let perimetre = JSON.parse(sessionStorage.getItem("perimetre"));
    if (perimetre.dataType === "organisation") {
      let index = this.sites.indexOf("Tous les sites")
      this.supportTicket.site = this.sites[index]
    } else {
      let index = this.sites.indexOf(perimetre.nom)
      this.supportTicket.site = this.sites[index]
    }
  }

  changeOnglet(event) {
    if (event == 'Approvisionnements') {
      this.searchOnglets('Appro');
    }
    else if (event == "Suivi d'explotation" || event == "Performances" || event == "Autre problème") {
      this.displayOnglets = false;
      this.onglets = [];
      this.supportTicket.onglet = '';
      if (event == "Autre problème") {
        this.supportTicket.onglet = 'Autre'
      }
      else if (event == "Performances") {
        this.supportTicket.onglet = 'Performances'
      }
    }
    else {
      this.searchOnglets(event);
    }
  }

  searchOnglets(param) {
    this.onglets = [];
    let bloc = this.parametresService.getParametres().blocs.find(bloc => bloc.label === param);
    this.onglets.push('');
    for (let i = 0; i < bloc.modules.length; i++) {
      if (bloc.modules[i].label != "Planning") {
        this.onglets.push(bloc.modules[i].label);
      }
    }
    this.onglets.push("Autre problème");
    this.displayOnglets = true
    this.supportTicket.onglet = '';
  }

  onSubmit(formValid: boolean) {
    if (!formValid) {
      this.invalidSubmitted = true;
    } else {
      this.isLoading = true;
      this.loadingMessage = 'Ajout du ticket';
      const username = this.user.prenom + this.user.nom === '' ? this.userEmail : this.user.prenom + ' ' + this.user.nom;
      if (this.supportTicket.module == "Autre problème") {
        this.supportTicket.module = "Autre_problème"
      }
      const ticket = {
        "request": {
          "subject": this.supportTicket.sujet,
          "status": "open",
          "comment": {
            "body": this.supportTicket.description,
          },
          "custom_fields": [{
            "id": 360009067060,
            "value": this.supportTicket.email
          },
          {
            "id": 360009097879,
            "value": this.supportTicket.orga
          },
          {
            "id": 360011672679,
            "value": this.organisation.id
          },
          {
            "id": 360008759880,
            "value": this.supportTicket.module
          },
          {
            "id": 360011048799,
            "value": this.supportTicket.onglet
          },
          {
            "id": 360009067380,
            "value": this.supportTicket.priorite
          }, {
            "id": 360011040420,
            "value": this.supportTicket.site
          },
          ],
          "requester": {
            "name": username,
            "email": this.supportTicket.email
          }
        }
      }
      const userObject = {
        "user":
        {
          "name": username,
          "email": this.supportTicket.email,
          "verified": true
        }
      }
      this.supportTicketService.addZendeskTicket(this.organisation.id, this.ticketFiles, ticket, userObject)
        .subscribe(res => {
          if (res.type) {
            this.breadcrumbService.removeFrom(-2);
            this.router.navigate(['/support/tickets/list']);
            this.isLoading = false;
            this.modalService.open(SupportTicketModalComponent);
          }
          if (res.body) {
            if (!sessionStorage.getItem("newTickets")) {
              let newTicketsArray = [res.body.request]
              sessionStorage.setItem("newTickets", JSON.stringify(newTicketsArray));
            } else {
              let newTicketsArray = JSON.parse(sessionStorage.getItem("newTickets") || "[]");
              newTicketsArray.push(res.body.request);
              sessionStorage.setItem("newTickets", JSON.stringify(newTicketsArray));
            }
          }
        }, error => {
          this.isLoading = false;
        })
    }
  }

  addTicketImages(event) {
    this.imagesSize = [];
    let ticketImagesLength;
    this.ticketImages ? ticketImagesLength = this.ticketImages.length : ticketImagesLength = 0;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size > 1048576) {
          this.imagesSize.push(event.target.files[i].name)
        }
        else if (ticketImagesLength >= 5) {
          return
        } else {
          this.ticketFiles.push({
            name: event.target.files.item(i).name,
            file: event.target.files.item(i),
            size: event.target.files.item(0).size
          })
          ticketImagesLength = ticketImagesLength + 1;
          if (event.target.files[i].type === 'application/pdf') {
            this.ticketImages.push('assets/images/pdf-icon-preview.png')
          }
          else if (event.target.files[i].type === 'text/plain') {
            this.ticketImages.push('assets/images/txt-icon-preview.png')
          }
          else if (event.target.files[i].type === 'text/csv') {
            this.ticketImages.push('assets/images/csv-icon-preview.png')
          }
          else {
            var reader = new FileReader();

            reader.onload = (event: any) => {
              this.ticketImages.push(event.target.result);
            }

            reader.readAsDataURL(event.target.files[i]);
          }
        }
      }
    }
  }

  deleteTicketImages(id) {
    this.ticketImages.splice(id, 1);
    this.ticketFiles.splice(id, 1);
  }

  close() {
    this.breadcrumbService.removeFrom(-2);
    this.router.navigate(['/support/tickets/list']);
  }

}

