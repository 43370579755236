import { Component, Input} from '@angular/core';
import { IColumn } from '../interfaces/IColumn';
import { IFilter } from '../interfaces/IFilter';
import { FilterService } from '../services/FilterService';
import { SortService } from '../services/SortService';

@Component({
	selector: 'column-filter-input',
	templateUrl: '../views/column-filter-input.html'
})
export class ColumnFilterInputComponent{
	@Input() column: IColumn;
	private selectedFilter_: string;
	constructor(
		private filterService_: FilterService,
		private sortService_: SortService
	){
		this.selectedFilter_ = "";
	}

	setColumnOrder(){
		this.sortService_.setColumnToSort(this.column);
	}

	clear(){
		this.selectedFilter_ = "";
	}

	apply(){
		
		if(this.selectedFilter_ != "") 
			this.filterService_.addFilter({
				column: this.column,
				values: [{label: this.column.name, value: this.selectedFilter_.toLowerCase()}]
			})
		else this.filterService_.deleteFilter({
				column: this.column,
				values: []
			})
	}
}