import { PerimetreService } from './../../../modules/global/services/perimetre/perimetre.service';
import { BreadcrumbService } from './../../../modules/global/services/breadcrumb/breadcrumb.service';
import { DatePipe } from '@angular/common';
import { SupportTicketService } from './../support-ticket.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-support-ticket-list',
  templateUrl: './support-ticket-list.component.html',
  styleUrls: ['./support-ticket-list.component.scss']
})

export class SupportTicketListComponent implements OnInit, AfterViewInit {
  @ViewChild('close', { static: true }) closeButton: ElementRef;

  pageSize = 10;
  perimetre: any;
  page = 1;
  tickets = [];
  term = "";
  columnList: any = [];
  headers: any[] = new Array<any>();
  isLoading = false;
  loadingMessage: string = 'Chargement';
  ticketsFiltered;
  labelText = 'Filtrer par statut';
  inputText = '';
  private bloc : any = {};
    private modules : any = [];

  constructor(
    private router: Router,
    private supportTicketService: SupportTicketService,
    private datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,
    private _perimetreService: PerimetreService,
  ) { }


  ngOnInit() {
    this.headers = this.supportTicketService.headers;
    this.breadcrumbService.add({ label: "Mes tickets", route: this.router.routerState.snapshot.url });
    this._perimetreService.perimetre$.subscribe(res => {
      if (res != undefined && res.dataType) {
        this.perimetre = res;
        this.perimetreChanged();
        const site = res.dataType == 'site' ? res.id : undefined;
      }
    })
    this.bloc = {
      id: "support",
      label: "Support",
      modules: [
        {id: "tickets", label: "Mes tickets", readWrite: 1},
        // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
      ]
    };
    this.modules = [
      {id: "tickets", label: "Mes tickets", readWrite: 1},
      // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
    ];
  }

  ngAfterViewInit() {

    $('.cd-dropdown-trigger').on('click', function (event) {
      event.preventDefault();
      toggleNav();
    });

    //close meganavigation
    $('.cd-dropdown .cd-close').on('click', function (event) {
      event.preventDefault();
      toggleNav();
    });

    //on mobile - open submenu
    $('.has-children').children('a').on('click', function (event) {
      //prevent default clicking on direct children of .has-children 
      event.preventDefault();
      var selected = $(this);
      selected.next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('move-out');
    });

    //on desktop - differentiate between a user trying to hover over a dropdown item vs trying to navigate into a submenu's contents
    var submenuDirection = (!$('.cd-dropdown-wrapper').hasClass('open-to-left')) ? 'right' : 'left';
    $('.cd-dropdown-content').menuAim({
      activate: function (row) {
        $(row).children().addClass('is-active').removeClass('fade-out');
        if ($('.cd-dropdown-content .fade-in').length == 0) $(row).children('ul').addClass('fade-in');
      },
      deactivate: function (row) {
        $(row).children().removeClass('is-active');
        if ($('li.has-children:hover').length == 0 || $('li.has-children:hover').is($(row))) {
          $('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
          $(row).children('ul').addClass('fade-out')
        }
      },
      exitMenu: function () {
        $('.cd-dropdown-content').find('.is-active').removeClass('is-active');
        return true;
      },
      submenuDirection: submenuDirection,
    });

    function toggleNav() {
      var navIsVisible = (!$('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
      $('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
      $('.cd-dropdown-trigger').toggleClass('dropdown-is-active', navIsVisible);
      if (!navIsVisible) {
        $('.cd-dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
          $('.has-children ul').addClass('is-hidden');
          $('.move-out').removeClass('move-out');
          $('.is-active').removeClass('is-active');
        });
      }
    }
  }

  private constuctColumnList() {
    this.columnList = [];
    for (let header of this.headers) {
      let col: any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.columnList.push(col);
    }
  }

  addTicket() {
    this.router.navigate(['../support/tickets/add']);
  }

  update(event) {
    let index = event.value.find(field => field.dataKey === "id").value;
    this.router.navigate(['support/tickets', index])
  }

  changeLabel(label: string) {
    this.labelText = label;
    label === 'Filtrer par statut' ? this.ticketsFiltered = this.tickets : this.ticketsFiltered = this.tickets.filter(ticket => ticket.status === label.toUpperCase());
    this.closeButton.nativeElement.click();
    this.searchTermChanged(this.inputText)
  }

  searchTermChanged(term: string) {
    this.inputText = term;
    this.labelText === 'Filtrer par statut' ? this.ticketsFiltered = this.tickets : this.ticketsFiltered = this.tickets.filter(ticket => ticket.status === this.labelText.toUpperCase());
    this.ticketsFiltered = this.ticketsFiltered.filter(tier => {
      for (let header of this.headers) {
        let value = _.get(tier, header.nom);
        if (value && value.toString().toLowerCase().includes(term.toLowerCase())) return true;
      }
      return false;
    })
    return this.tickets;
  }

  getTickets(orga: boolean, site?: string) {
    this.isLoading = true;
    let organisation = JSON.parse(sessionStorage.getItem('organisation'));
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.supportTicketService.getZendeskTickets(organisation.id, user.email, site, orga)
      .subscribe(tickets => {
        if (tickets) {
          if (tickets.results) {
            let newTicketsArray = JSON.parse(sessionStorage.getItem("newTickets") || "[]");
            newTicketsArray.map(newTicket => {
              let ticketExist = tickets.results.find(ticket => ticket.id === newTicket.id);
              if (ticketExist) {
                let index = newTicketsArray.indexOf(newTicket);
                newTicketsArray.splice(index, 1);
              } else {
                tickets.results.push(newTicket);
              }
            })
            newTicketsArray.length === 0 ? sessionStorage.removeItem("newTickets") : sessionStorage.setItem("newTickets", JSON.stringify(newTicketsArray));
            tickets.results = tickets.results.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
            this.tickets = tickets.results.map(ticket => {
              ticket['site'] = this.getCustomField(360011040420, ticket.custom_fields);
              if (orga && !site || orga && ticket.site === 'Tous les sites' || orga && ticket.site === site || !orga) {
                if (ticket.status === 'new') {
                  ticket.status = 'ouvert'
                } else if (ticket.status === 'open') {
                  ticket.status = 'ouvert'
                } else if (ticket.status === 'closed' || ticket.status === 'solved') {
                  ticket.status = 'resolu'
                } else if (ticket.status === 'pending') {
                  ticket.status = 'en attente'
                }
                ticket['requester'] = this.getCustomField(360009067060, ticket.custom_fields);
                ticket.status = ticket.status.toUpperCase();
                ticket.created_at = this.datePipe.transform(ticket.created_at, 'dd/MM/yyyy')
                ticket.updated_at = this.datePipe.transform(ticket.updated_at, 'dd/MM/yyyy')
                return ticket
              }
            });
            this.tickets = this.tickets.filter(ticket => ticket != undefined);
            this.ticketsFiltered = this.tickets;
            this.searchTermChanged(this.inputText);
          }
          this.constuctColumnList();
        }
        this.isLoading = false;
      })
  }

  private getCustomField(id: number, fields: any[]): any {
    return fields.find(field => field.id == id).value;
  }

  private perimetreChanged() {
    const temp = this.perimetre.dataType == 'site' ? this.perimetre.id : undefined;
    if (JSON.parse(sessionStorage.getItem('loggedUser')).accessOrga === true) {
      if (this.perimetre.dataType == 'organisation') {
        this.getTickets(true);
      }
      else if (this.perimetre.dataType == 'site') {
        this.getTickets(true, this.perimetre.nom)
      }
    } else {
      this.getTickets(false, this.perimetre.nom)
    }
  }

}
