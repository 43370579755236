import { Utilisateur } from './utilisateur';
import { Organisation } from './organisation';

export class UtilisateurOrganisation extends Utilisateur {
	public organisation : Organisation;
	constructor(){
		super();
		this.organisation = new Organisation();
		this.dataType = 'utilisateurOrganisation'
	}
}