import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PasswordService } from 'global/services/password/password.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
	private _username: string;
    private badUsername: string;
  private emailSend = false;
  private errorCode: number;
  private sending = false;

  constructor(
  	private passwordService: PasswordService,
    private router: Router
	) { }

  ngOnInit() {
  }

  generateLink(){
      this.sending = true;
  	this.passwordService.generateLink(this._username)
  		.subscribe(res =>{
            this.sending = false;
            if(res != undefined && !res.error){
                  this.emailSend = true;
        }
        else {
            this.errorCode = 404;
            this.badUsername = this._username;
              }
  		}, error => {
            this.sending = false;
            this.errorCode = 500
        })
  }

    goToHome() {
        this.router.navigate(['login'])
    }
}
