import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name:'joinArray'})
export class JoinArrayPipe implements PipeTransform{
	transform(array : any[]){
		let finalString = '';
		array.forEach(obj=>{
      obj.nom ? finalString+=obj.nom+', ' : finalString+=obj+', ';
    })
		return finalString.slice(0,-2);
	}
}
