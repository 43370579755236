import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(
  	private httpClient: HttpClient
	) { }
  /**
  * Generate password reset link 
  * @param email - user email
  */
  public generateLink(email: string): Observable<any>{
  	return this.httpClient.get<any>(environment.api+'/password/generateResetLink?email='+email,{})
  		.pipe(
  			catchError(this.handleError<any>('password'))
			)
  }
  /**
  * Reset password 
  * @param token - token
  * @param password - password
  * @param confirmpassword - confirmpassword
  */
  public resetPassword(token: string, password: string, confirmpassword: string): Observable<any>{
  	return this.httpClient.put<any>(
  		environment.api+'/password/reset',
  		{
  			token:token,
  			password: password,
  			confirmPassword: confirmpassword
  		}
		)
		.pipe(
			catchError(this.handleError<any>('password'))
		)
  }

  public changePassword(email: any, password: string, confirmPassword: string): Observable<any>{
	  return this.httpClient.post<any>(
		  environment.api+'/user/changePassword',
		  {
			  email: email,
			  password: password,
			  confirmPassword: confirmPassword
		  }
	  ).pipe(
		  catchError(this.handleError<any>('password'))
	  )
  }
  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead
	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}
}
