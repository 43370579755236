import { IColumn } from '../interfaces/IColumn';

export class ColumnSelectorService{
	constructor(){}

	setVisible(column: IColumn){
		column.visible = true;
	}

	setInVisible(column: IColumn){
		column.visible = false;
	}
}