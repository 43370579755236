import { CaracteristiqueEconomique } from '../../../../models/caracteristique-economique';
import { SiteDocument } from 'global/models/dto/site-document';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { CaracteristiquePhysicoChimique } from 'tracabilite/models/caracteristique-physico-chimique';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { StockIntrantDAO } from '../dao/stock-intrant';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class StockIntrant extends SiteDocument implements IDto2Dao, IBGVTreeNode {
	public categorie: string;
	public nom: string;
	public code: string;
	public lieuStockage: string;
	public quantiteIncorporeeCste: boolean;
	public calculTonnage: boolean;
	public calculCPC: boolean;
	public consigneCste: boolean;
	public cpc: CaracteristiquePhysicoChimique[];
	public ce: CaracteristiqueEconomique[];
	public tonnage: number;
	public tonnagee: number;
	public dateTonnageRef: any;
	public tags: string[];
	public etatStock: number;
	public notDisplayInDropdowns: boolean = false;
	public methodCalculCPC: string;
	public tauxMatiereDurable: number;

	constructor() {
		super();
		this.dataType = 'stockIntrant';
		this.quantiteIncorporeeCste = false;
		this.calculTonnage = true;
		this.calculCPC = true;
		this.consigneCste = true;
		this.tonnage = 0;
		this.methodCalculCPC = 'Utiliser le dernier gisement';
		this.dateTonnageRef = this.lastDay();
		//this.methodCalculCPC = "Utiliser le dernier gisement";
	}

	public toDao(): StockIntrantDAO {
		let stock: StockIntrantDAO = new StockIntrantDAO();
		Object.keys(this)
			.forEach(key => {
				if (key == "site") stock[key] = this[key]._id || this[key].id;
				else if (key == 'tonnage') stock[key] = +this[key];
				else if (key == 'dateTonnageRef') {
					let date = new Date(this[key]);
					stock[key] = date.getTime();
				} else {
					stock[key] = this[key];
				}
			});
		return stock;
	}

	toNodes(parentId?: number, parentName?: any): any {
		let keyNames = [
			{ 'label': 'app.global.headers.nom', 'dataKey': 'nom' },
			{ 'label': 'app.global.headers.code', 'dataKey': 'code' },
			{ 'label': 'app.global.headers.site', 'dataKey': 'site.nom' },
			{ 'label': 'app.global.headers.categorie', 'dataKey': 'categorie' },
			{ 'label': 'app.global.headers.lieuStockage', 'dataKey': 'lieuStockage' },
			{ 'label': 'app.global.headers.tonnage', 'dataKey': 'tonnage' },
			{ 'label': 'app.global.headers.dateTonnageReference', 'dataKey': 'dateTonnageRef' },
			{'label': 'Matière organique sur MB', 'dataKey': 'MO/MB'},
			{'label': 'Matière sèche sur MB', 'dataKey': 'MS/MB'},
			{'label': 'Potentiel méthane sur MB', 'dataKey': 'PM/MB stand'},
			{'label': 'Azote total', 'dataKey': 'Ntot'},
		]
		let index = 0;
		return keyNames.map(key => {
			let node: any = {},
				id = parentId ? parentId + index + 1 : index;
			node = {
				id: id,
				name: key.label,
				dataKey: key.dataKey
			}
			index++;
			return node;
		})
	}

	private lastDay(): Date {
		let todayTime = new Date().getTime();
		return new Date(todayTime - 24 * 60 * 60 * 1000);
	}

}
