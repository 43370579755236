import { SiteDocumentDAO } from 'global/models/dao/site-document';


export class AnalyseLaboDAO extends SiteDocumentDAO{
	public dateAnalyse : any;
	public laboratoire : string;
	public nLot : string;
	public nAnalyse : string;
	public commentaire : string;
	
	constructor(){
		super();
	}
}
