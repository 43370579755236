import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SupportTicketService {

  constructor(
    private http: HttpClient
  ) { }

  public get headers() {
    return [
      { nom: "site", label: "Site" },
      { nom: "id", label: "N°" },
      { nom: "subject", label: "Sujet" },
      { nom: "requester", label: "Demandeur" },
      { nom: "created_at", label: "Création" },
      { nom: "updated_at", label: "Dernière modification" },
      { nom: "status", label: 'Statut' },
    ]
  }

  getZendeskTickets(organisation: string, user: string, site?: string, orga?: boolean): Observable<any> {
    if (site && !orga) {
      return this.http.get(`${environment.api}/organisations/${organisation}/tickets?query=${organisation} ${site}`);
    }
    else if (orga) {
      return this.http.get(`${environment.api}/organisations/${organisation}/tickets?query=${organisation}`);
    }
  }

  getZendeskTicket(organisation: string, id: number): Observable<any> {
    return this.http.get(`${environment.api}/organisations/${organisation}/tickets/${id}`);
  }

  addZendeskTicket(organisation: string, files: any, ticket: any, user): Observable<any> {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      if (files[i]._id == undefined) {
        formData.append(
          `files`,
          files[i].file,
          files[i].name
        );
      }
    }
    formData.append(`ticket`, new Blob([JSON.stringify(ticket)], { 'type': 'application/json' }));
    formData.append(`user`, new Blob([JSON.stringify(user)], { 'type': 'application/json' }));
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    const req = new HttpRequest('POST', `${environment.api}/organisations/${organisation}/tickets`, formData, httpOptions);
    return this.http.request(req)
      .pipe(
        catchError(this.handleError<any>('ticket'))
      )
  }

  updateZendeskTicket(organisation: string, user: string, id: number, files: any, ticket: any): Observable<any> {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      if (files[i]._id == undefined) {
        formData.append(
          `files`,
          files[i].file,
          files[i].name
        );
      }
    }
    formData.append(`ticket`, new Blob([JSON.stringify(ticket)], { 'type': 'application/json' }));
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    const req = new HttpRequest('PUT', `${environment.api}/organisations/${organisation}/tickets/${id}`, formData, httpOptions);
    return this.http.request(req)
      .pipe(
        catchError(this.handleError<any>('ticket'))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
