import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as shortUuid from 'short-uuid';
import { environment } from '../../../../../environments/environment';
import { BiogasviewDocument } from 'global/models/biogasview-document';
declare var PouchDB: any;

export class CouchDbQueryResponse {
    rows;
    offset;
    total_rows;
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

	private _db;
  private _dbName;
  private _token;
  private _username;
	private _userCtx: any;

	constructor(
		private httpClient: HttpClient
	) {

	}

  get dbName(){
    return this._dbName;
  }

  public query(viewName : string, options : any) : Promise<CouchDbQueryResponse>{
      return this._db.query(viewName,options);
  }

	get db(): any{
    return this._db;
  }

  public getDatabaseFromSession(){
    const organisation = JSON.parse(sessionStorage.getItem('organisation'));
    const user = sessionStorage.getItem('user');
    const token = sessionStorage.getItem('token');
    let help: boolean = organisation != undefined &&
      organisation.database != undefined &&
      user != undefined &&
      token != undefined;
    if(help){
      this.changeDatabase(organisation.database,user,token);
    }
  }

	public changeDatabase(name: string,username: string,token: string){
    /// TS_IGNORE
		this._db = new PouchDB(environment.dbHost+'/'+name,{
			fetch: function (url, opts) {
        opts.headers.set('X-Auth-Couchdb-Username',username);
        opts.headers.set('X-Auth-Couchdb-Roles',name);
        opts.headers.set('X-Auth-Couchdb-Token',token);
		    return PouchDB.fetch(url, opts);
		  }
		})
	}

  public increment(counter : string){
    let shortUUID = shortUuid().new().toUpperCase().substr(0,3);
    return shortUUID;
  }


	private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
    };

}
