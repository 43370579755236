import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

    private _previousUrl: string;
  private _currentUrl: string;

    activeItems : boolean[];
    private _activeItemsSource = new BehaviorSubject(new Array<boolean>());
    public activeItems$ = this._activeItemsSource.asObservable();
    _oldSubNavs;
    _subNavs;

  constructor() { }

    resetActiveItems(){
        let activeItems = new Array(this._subNavs.length);
        activeItems.forEach(item => item = false);
        this._activeItemsSource.next(activeItems);
    }

    initialiseActiveItems(){
        let activeItems = new Array(this._subNavs.length);
        activeItems.forEach(item => item = false);
        activeItems[0] = true;
        this._activeItemsSource.next(activeItems);
    }

    setActive(index : number){
        let activeItems = new Array(this._subNavs.length);
        activeItems.forEach(item => item = false);
        activeItems[index] = true;
        this._activeItemsSource.next(activeItems);
    }

    setPreviousUrl(url){
        this._previousUrl = url;
    }

    setCurrentUrl(url){
        this._currentUrl = url;
    }

    getPreviousUrl(){
       return this._previousUrl;
    }

    getCurrentUrl(){
       return this._currentUrl;
    }

    set subNavs(subNavs){
        this._subNavs = subNavs;
        if(!_.isEqual(this._oldSubNavs, this._subNavs)){
            this.initialiseActiveItems();
            this._oldSubNavs = _.cloneDeep(this._subNavs);
        }
    }

    get subNavs(){
        return this._subNavs;
    }
}
