import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { UtilisateurOrganisation } from 'global/models/dto/utilisateur-organisation';
import { Utilisateur } from 'global/models/dto/utilisateur';
import { Organisation } from 'global/models/dto/organisation';
import { Site } from 'global/models/dto/site';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends CommonDatabaseQueryService {

  parametres;
  private _username;
  private _password;

  constructor(
    private http: HttpClient,
    _databaseService: DatabaseService,
    private router: Router,
    private parametresService: ParametresService,
    private location: Location
  ) {
    super(_databaseService, {
      utilisateurOrga_by_email: 'UtilisateurOrganisation/by_email',
      utilisateur_by_email: 'Utilisateur/by_email',
    });
  }

  get username() {
    return this._username;
  }

  get password() {
    return this._password;
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  public testConnexion() {
    return this.http.get<any>(environment.api + '/authorization/session');
  }
  /**
   * Log user in.
   * @param username - username
   * @param password - user password
   */
  public login(username: string, password: string): Observable<any> {
    console.log("Dans login()");
    this._username = username;
    this._password = password;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(username + ':' + password)
      })
    };
    return this.http.post<any>(environment.api + '/authorization/login', { usernameOrEmail: username, password: password }, httpOptions)
      .pipe(
        tap(jwtoken => this.setSession(jwtoken)),
        catchError(this.handleError<any>('login'))
      )
  }

  public session(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.get<any>(environment.api + '/authorization/session', httpOptions)
      .pipe(
        tap(user => this.setLoggedUser(user)),
        catchError(this.handleError<any>('session'))
      )
  }

  private setLoggedUser(res: any) {
    sessionStorage.setItem('organisation', JSON.stringify(res.data.organisation));
    sessionStorage.setItem('user', res.data.email);
    sessionStorage.setItem('sites', JSON.stringify(res.data.sites));
    sessionStorage.setItem('token', res.dbToken);
    sessionStorage.setItem("loggedUser", JSON.stringify(res.data));
    if (res.data.dataType == "utilisateur") {
      //stocker les organisations et les sites
      sessionStorage.setItem('listeOrganisation', JSON.stringify(res.data.organisations));
    }
  }

  private setSession(jwtResponse: any) {
    sessionStorage.setItem('expireAt', jwtResponse.expiration);
    sessionStorage.setItem('session', jwtResponse.accessToken)
  }
  /**
 * Log user out
 */
  public logout(): Observable<any> {
    return this.http.post<any>(environment.api + '/authorization/logout', {})
      .pipe(
        catchError(this.handleError<any>('logout'))
      )
  }

  public loggedUser() {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    let userId = user ? user.id || user._id : undefined;
    if (!user) {
      return Promise.resolve(undefined);
    }
    if (user.dataType == "utilisateurOrganisation") {
      return this._dbService.db.query(
        'UtilisateurOrganisation/joins',
        {
          include_docs: true,
          descending: false,
          startkey: [userId],
          endkey: [userId, {}]
        }
      ).then(res => {
        if (res.rows.length) {
          let utilisateur = new UtilisateurOrganisation();
          res.rows.forEach(row => {
            if (row.key[1] == 0) {
              utilisateur = row.doc as UtilisateurOrganisation;
              utilisateur.sites = [];
            } else if (row.key[1] == 1) utilisateur.organisation = row.doc as Organisation;
            else if (row.key[1] == 2) utilisateur.sites.push(row.doc as Site);
          })
          return utilisateur;
        } else {
          return undefined;
        }
      });
    } else if (user.dataType == "utilisateur") {
      return this._dbService.db.query(
        'Utilisateur/joins',
        {
          include_docs: true,
          descending: false,
          startkey: [userId],
          endkey: [userId, {}]
        }
      ).then(res => {
        if (res.rows && res.rows.length) {
          let utilisateur = new Utilisateur();
          res.rows.forEach(row => {
            if (row.key[1] == 0) {
              utilisateur = row.doc as Utilisateur;
              utilisateur.sites = [];
              utilisateur.organisations = [];
            } else if (row.key[1] == 1) utilisateur.sites.push(row.doc as Site);
            else if (row.key[1] == 2) utilisateur.organisations.push(row.doc as Organisation);
          })
          return utilisateur;
        } else {
          return undefined;
        }
      });
    }
  }

  public getWriteAccessRight() {
    return this.loggedUser().then(user => {
      if (!user) {
        this.router.navigate(['/login']);
        return;
      }
      if (!this.parametres) this.parametres = this.parametresService.getParametres();
      let state = this.router.routerState.snapshot;
      let blocObjective = _.compact(state.url.split('/'))[0];
      let moduleObjective = _.compact(state.url.split('/'))[1];
      let functionObjective = _.compact(state.url.split('/'))[2];
      let bloc = _.find(this.parametres.routes, ['path', blocObjective]);
      if (!bloc) {
        return;
      }
      let module;
      let userBloc = _.find(user.blocs, ['id', bloc.id]);
      if(!userBloc) {
        return;
      }
      userBloc.modules.forEach((userModule: any) => {
        if (_.find(this.parametres.routes, ['id', userModule.id]).path == moduleObjective) module = _.find(this.parametres.routes, ['id', userModule.id]);
        else if (_.find(this.parametres.routes, ['id', functionObjective])) module = _.find(this.parametres.routes, ['id', functionObjective]);
      })
      if (!module) {
        return;
      }
      if (module.id === "calendrier") {
        module = { id: "evenements", path: "evenements" };
      }
      userBloc.modules = userBloc.modules.map(mod => {
        let res;
        if (mod.children) {
          res = [mod.children];
          delete mod.children;
          res.push(mod);
        }
        else res = mod;
        return res;
      })
      userBloc.modules = _.flattenDeep(userBloc.modules);
      if (userBloc && _.find(userBloc.modules, ['id', module.id])) {
        if (!_.find(userBloc.modules, ['id', module.id]).readWrite) {
          return false;
        } else {
          return true;
        }
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
    }).catch(err => console.error(err))
  }

  /**
  * Clear session data;
  */
  public removeLoggedUser() {
    sessionStorage.clear();
  }

}
