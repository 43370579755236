import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PasswordService } from 'global/services/password/password.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

	private _token: string;
	private _confirmpassword: string;
	private _password: string;
	private _invalidToken = false;
	private _invalidConfirmation = false;
  private _showMessage = false;

  constructor(
  	private activateRoute: ActivatedRoute,
  	private passwordService: PasswordService,
  	private _router: Router
	) { }

  ngOnInit() {
  	this.activateRoute.queryParams
  		.pipe(
  			filter( params => params.token)
			)
  		.subscribe( (param: any) => {
  			if(param == undefined) this._invalidToken = true;
  			else this._token = param.token;
  		})
  }

  resetPassword(){
  	if(this._confirmpassword !== this._password) this._invalidConfirmation = true;
  	else{
  		this.passwordService.resetPassword(this._token,this._password,this._confirmpassword)
  			.subscribe((res:any)=>{
          if(res != undefined && !res.error) this._router.navigate(['login']);
          else this._showMessage = true;
  			})
  	}
  }

}
