import { Intervenant } from './../models/dto/intervenant';
import { Tache } from './../models/dto/tache';
import { Categorie } from '../models/dto/categorie';
import { Site } from 'global/models/dto/site';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';

import { defer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class TacheService extends CommonDatabaseQueryService {
  constructor(_dbService: DatabaseService) {
    super(_dbService, {
      joins: 'Tache/joins',
      by_date: 'Tache/by_date',
      by_date_and_site: 'Tache/by_date_and_site',
      by_site: 'Tache/by_site',
    });
  }

  headers = [
    { nom: "dateDebutTable", label: "Date de début" },
    { nom: "nom", label: "Nom" },
    { nom: "description", label: "Description" },
    { nom: "categorie.nom", label: "Categorie" },
    { nom: "attachements", label: "PJ" },
    { nom: "statut", label: "Statut" },
  ]

  public joins(docId: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false): any {
    return super.joins(docId, skip, limit, desc)
      .then(res => {
        let tache: Tache;
        let taches = new Array<Tache>();
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].key[1] == 0) {
            delete res.rows[i].doc.siteId;
            tache = res.rows[i].doc as Tache;
            taches.push(tache);
          }
          else if (res.rows[i].key[1] == 1) tache.categorie = res.rows[i].doc as Categorie;
          else if (res.rows[i].key[1] == 2) tache.intervenant = res.rows[i].doc as Intervenant;
          else if (res.rows[i].key[1] == 3) tache.site = res.rows[i].doc as Site;
        }
        return taches;
      })
      .catch(error => defer(() => Promise.reject(error)))
  }


  public bySite(id) {
    return super.bySite(id).then(res => {
      let taches = new Array<Tache>();
      let sites = new Array<Site>();
      for (let i = 0; i < res.rows.length; i++) {
        if (res.rows[i].key[1] == 0) taches.push(res.rows[i].doc as Tache);
        else if (res.rows[i].key[1] == 1) sites.push(res.rows[i].doc as Site);
      }
      for (let i = 0; i < taches.length; i++) {
        taches[i].site = sites[i];
      }
      return _.sortBy(taches, 'nom');
    })
      .catch(error => defer(() => Promise.reject(error)))
  }
  public documents(id: string): any {
    return this._dbService.db
      .query(
        'GedDocument/by_documents',
        {
          include_docs: true,
          key: id
        }
      )
      .then(res => res.rows.map(d => d.doc))
      .catch(error => defer(() => Promise.reject(error)))
  }

  public byDateAndSite(start: string, end: string, site: any = undefined) {
    let options: any = {
      include_docs: true
    }
    let promise = undefined;
    if (site) {
      options.startkey = [site, start, 0];
      options.endkey = [site, end, 3];
      promise = this._dbService.db.query('Tache/by_date_and_site', options);
    }
    else {
      options.startkey = [start, 0];
      options.endkey = [end, 3];
      promise = this._dbService.db.query('Tache/by_date', options);
    }
    return promise.then(res => {
      let taches = new Array<Tache>();
      let categories = new Array<Categorie>();
      let intervenants = new Array<Intervenant>();
      let sites = new Array<Site>();
      res.rows.forEach(row => {
        if (row.key[2] == 0 || row.key[1] == 0) taches.push(row.doc as Tache);
        else if (row.key[2] == 1 || row.key[1] == 1) categories.push(row.doc as Categorie);
        else if (row.key[2] == 2 || row.key[1] == 2) intervenants.push(row.doc as Intervenant);
        else if (row.key[2] == 3 || row.key[1] == 3) sites.push(row.doc as Site);
      })
      for (let i = 0; i < taches.length; i++) {
        taches[i].categorie = categories[i];
        taches[i].intervenant = intervenants[i];
        taches[i].site = sites[i];
      }
      res.rows = _.sortBy(taches, [function (event) { return - event.dateDebut; }]);
      return res.rows;
    })
      .catch(error => defer(() => Promise.reject(error)));
  }

  getDocument(id) {
    return this._dbService.db.get(id).then(doc => {
      return doc;
    })
  }

  public bulkCreate(taches: Tache[]) {
    let promises = [];
    taches.map(tache => {
      this.create(tache)
    });
    return Promise.all(promises)
  }

  private create(tache: Tache) {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    tache.createdBy = user.email;
    tache.modifiedBy = user.email;
    tache.createdOn = new Date().getTime();
    tache.modifiedOn = new Date().getTime();
    if (tache._id == undefined) tache._id = 'tache_' + uuid();
    return this._dbService.db.put(tache.toDao());
  }

  private update(tache: Tache) {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    tache.modifiedBy = user.email;
    tache.modifiedOn = new Date().getTime();
    return this._dbService.db.get(tache._id).then(doc => {
      tache._rev = doc._rev;
      return this._dbService.db.put(tache.toDao());
    });
  }

  public upSert(tache: Tache): any {
    if (tache._id && tache._rev) return this.update(tache);
    else return this.create(tache);
  }

  public delete(tache: Tache) {
    return this._dbService.db.get(tache._id).then(doc => {
      tache._rev = doc._rev;
      return this._dbService.db.remove(tache);
    });
  }

  public bulkDelete(tache: Tache[]) {
    let promises = [];
    promises = tache.map(tache => this.delete(tache));
    return Promise.all(promises);
  }
}