import { AnalyseLaboDAO } from 'tracabilite/models/dao/analyse-labo';
import { GisementDAO } from 'tracabilite/models/dao/gisement';
import { CaracteristiquePhysicoChimique } from 'tracabilite/models/caracteristique-physico-chimique';

export class AnalyseIntrantDAO extends AnalyseLaboDAO{
	public nom : string;
	public type : string;
	public gisement : string;
	public numEchantillon : string;
	public commentaire : string;
	public cpc : CaracteristiquePhysicoChimique[];
	constructor(){
		super();
		this.dataType = 'analyseIntrant';
	}
}
