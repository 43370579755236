import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';



const routes = [
	{
		path: 'appro',
    loadChildren: './modules/appro/appro.module#ApproModule'
	},
	{
		path: 'digestats',
    loadChildren: './modules/digestats/digestats.module#DigestatsModule'
	},
	{
		path: 'epandages',
    loadChildren: './modules/epandages/epandages.module#EpandagesModule'
	},
	{
		path: 'facturation',
    loadChildren: './modules/facturation/facturation.module#FacturationModule'
	},
	{
		path: 'intrants',
    loadChildren: './modules/intrants/intrants.module#IntrantsModule'
	},
	{
		path: 'rations',
    loadChildren: './modules/rations/rations.module#RationsModule'
	},
	{
		path: 'ration2',
    loadChildren: './modules/ration/ration.module#RationModule'
	}
];

@NgModule({
	exports: [
		RouterModule
	],
  imports: [
    RouterModule.forRoot(routes,{useHash: true}),
  ],
  declarations: []
})
export class TracabiliteRoutingModule { }
