import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from '../services/DataService';
import { SortService } from '../services/SortService';
import { IColumn } from '../interfaces/IColumn';

@Component({
	selector: '[table-columns]',
  templateUrl: '../views/table-columns.html'
})
export class TableColumnsComponent{
	@Input() selectAll: boolean;
	@Output('onselectall') onSelectAll: EventEmitter<any> = new EventEmitter();
  constructor(
  	public dataService: DataService,
    public sortService: SortService
  ){
  	
  }

  ngOnInit(){
    if(this.selectAll) this.toggleSelection();
  }
  setColumnOrder(aColumn:IColumn){
    if(!aColumn.filterable)
      this.sortService.setColumnToSort(aColumn);
  }

  toggleSelection(){
    if(this.selectAll) this.dataService.selectAllRows();
    else this.dataService.deselectAllRows();
    this.onSelectAll.emit(this.dataService.getSelectedRows());
  }
}