import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TracabiliteRoutingModule } from './tracabilite-routing.module';
import { GisementsService } from './services/gisements/gisements.service';
import { TiersService } from './services/tiers/tiers.service';

@NgModule({
  imports: [
    CommonModule,
    TracabiliteRoutingModule,
  ],
  declarations: [
  ],
  providers:[
    TiersService,
    GisementsService
  ],
  entryComponents: [
    
  ],
  exports: []

})
export class TracabiliteModule { }
