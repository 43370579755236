import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { SiteDocument } from 'global/models/dto/site-document';
import { TacheDAO } from '../dao/tache';

export class Tache extends SiteDocument implements IDto2Dao, IBGVTreeNode {
  public nom: string;
  public description: string;
  public tacheJournee: boolean;
  public dateDebut: any;
  public dateFin: any;
  public couleur: string;
  public categorie: any;
  public getCouleur: boolean;
  public intervenant: any;
  public statut: string;


  constructor() {
    super();
    this.dateDebut = new Date();
    this.dateFin = new Date();
    this.dateFin.setMinutes((this.dateFin.getMinutes() + 30));
    this.couleur = "#ffffff";
    this.categorie = undefined;
    this.getCouleur = true;
    this.intervenant = undefined;
  }

  toDao(): TacheDAO {
    let tache = new TacheDAO();
    Object.keys(this).forEach(key => {
      if ("categorie".includes(key) && this[key]) tache[key] = this[key]._id;
      else if ("intervenant".includes(key) && this[key]) tache[key] = this[key]._id;
      else if (key == "site") tache[key] = this[key]._id || this[key].id;
      else {
        tache[key] = this[key];
      }
    });
    return tache;
  }

  toNodes(parentId?: number, parentName?: any): any {
    let keyNames = [
    ]
    let index = 0;
    return keyNames.map(key => {
      let node: any = {},
        id = parentId ? parentId + index + 1 : index;
      node = {
        id: id,
        name: key.label,
        dataKey: key.dataKey
      }
      index++;
      return node;
    })

  }

}
