import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'global/services/login/login.service';
import { DatabaseService } from 'global/services/database/database.service';

@Component({
  selector: 'app-orga-board',
  templateUrl: './orga-board.component.html',
  styleUrls: ['./orga-board.component.scss']
})
export class OrgaBoardComponent implements OnInit {

  private listeOrganisation = null;
  private site = null;

  constructor(
      private databaseService : DatabaseService,
      private loginService : LoginService,
      private router : Router
  ) { }

  ngOnInit() {
      this.listeOrganisation = JSON.parse(sessionStorage.getItem('listeOrganisation'));
      this.listeOrganisation = this.listeOrganisation.map((orga) =>{
        orga.sites = orga.sites.sort((a,b) => a.nom.localeCompare(b.nom));
        return orga;
      })
  }

  setOrganisation(organisation){
      if(organisation._id || organisation.id){
        const dbSessionToken = sessionStorage.getItem('token');
        if(dbSessionToken){
          const username = this.loginService.username || sessionStorage.getItem('user');
          this.databaseService.changeDatabase(organisation.database,username,dbSessionToken);
          this.databaseService.db.info().then(result =>{
              sessionStorage.setItem('sites',JSON.stringify(organisation.sites));
              sessionStorage.setItem('organisation',JSON.stringify(organisation));
              if (this.site && this.site.dataType == 'site') sessionStorage.setItem('perimetre',JSON.stringify(this.site)); 
              else sessionStorage.removeItem('perimetre') 
              sessionStorage.removeItem('tmpBreadcrumb');
              this.router.navigate(['home-board'], { state: { reload: true}});
          })
        } else{
          this.logout();
        }
      }
  }

    pickSite(organisation,site){
      this.site = site;
      this.setOrganisation(organisation);
    }


    logout(){
    this.loginService.logout()
      .subscribe(res =>{
        this.loginService.removeLoggedUser();
        this.router.navigate(["/login"]);
      })
  }



}
