import { MaterielModule } from './modules/materiel/materiel.module';
import { JournalModule } from './modules/journal/journal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'global/global.module';
import { DataTableModule } from 'table/data-table.module';
import { ToggleTableModule } from 'global/modules/toggle-table/toggle-table.module';
import { SuiviModule } from './modules/suivi/suivi.module';
import { ExploitationRoutingModule } from './exploitation-routing.module';
import { RondesModule } from './modules/rondes/rondes.module';

@NgModule({
  imports: [
    CommonModule,
    ExploitationRoutingModule,
    GlobalModule,
    ToggleTableModule,
    DataTableModule
  ],
  declarations: [],
})
export class ExploitationModule { }
