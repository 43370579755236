import { Injectable } from '@angular/core';
import { StockIntrant } from 'intrants/models/dto/stock-intrant';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { CustomDatepickerI18n } from 'global/services/custom-datepicker-i18n/custom-datepicker-i18n';
import { Site } from 'global/models/dto/site';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';
import * as moment from 'moment';
import { defer } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class StockIntrantService extends CommonDatabaseQueryService {

    cpc = ["MS/MB","MO/MB","PM/MB stand", "Ntot"];

    constructor(_databaseService : DatabaseService, private dateService : CustomDatepickerI18n, private translateService: TranslateService) {
        super(_databaseService,{
            all: 'StockIntrant/all',
            joins : 'StockIntrant/joins',
            by_site : 'StockIntrant/by_site',
            tonnage_avec_ration : 'StockIntrant/tonnage_avec_ration',
            tonnage_sans_ration : 'StockIntrant/tonnage_sans_ration',
            by_site_and_nom : 'StockIntrant/by_site_and_nom',
            linked_documents: 'StockIntrant/linked_documents',
            tonnage_prevu_avec_ration : 'StockIntrant/tonnage_prevu_avec_ration'
        });
    }

    get headers() {
        return [
            {nom: 'nom', label: this.translateService.instant('app.global.headers.nom')},
            {nom: 'tonnage', label: this.translateService.instant('app.global.headers.tonnage')},
            {nom: 'MS/MB', label : this.translateService.instant('app.global.headers.MS/MBEn%')},
            {nom: 'MO/MB', label : this.translateService.instant('app.global.headers.MO/MBEn%')},
            {nom: 'PM/MB stand', label : this.translateService.instant('app.global.headers.PM/MBEn%C')},
            {nom: 'Ntot', label : this.translateService.instant('app.global.headers.azoteTotale%')},
            {nom: 'tauxMatiereDurable', label: this.translateService.instant('app.global.headers.tauxMatiereDurable%')}
        ];
    }

    get ouvrageIncorporationHeaders() {
        return [
            { nom: 'code', label: this.translateService.instant('app.global.headers.nom') },
            { nom: 'label', label: this.translateService.instant('app.global.headers.description') }
        ];
    }

    public getIncrement(){
        return 'S-'+this._dbService.increment("stockCounter");
    }

    public list(){
            return super.list()
                    .then( res => {
                            let stocks = new Array<StockIntrant>();
                            let stock = new StockIntrant();
                            res.rows.forEach( row => {
                                    if(row.key[1] == 0) stock = row.doc as StockIntrant;
                                    else if(row.key[1] == 1) {
                                            stock.nom = stock.nom ? stock.nom : '';
                                            stock.site = row.doc as Site;
                                            stocks.push(stock);
                                    }
                            })
                            return _.sortBy(stocks,(stock) => stock.nom.toLocaleLowerCase());
                    })
                    .catch( error => defer(()=>Promise.reject(error)))
    }

    public get(stock: string): any{
        return super.joins(stock)
            .then(res => {
                let stock = new StockIntrant();
                res.rows.forEach( row => {
                    if(row.key[1] == 0) stock = row.doc as StockIntrant;
                    else if(row.key[1] == 1 && row.doc) {
                        stock.site = row.doc as Site;
                    }
                })
                return stock;
            }).catch(error => defer(()=>Promise.reject(error)))
    }

    public joins(docId : string = undefined, skip: number = 0, limit: number = 10000,desc: boolean = false){
            return super.joins(docId, skip,limit,desc)
            .then(res => {
                    let stocks = new Array<StockIntrant>();
                    let stock = new StockIntrant();
                    res.rows.forEach( row => {
                        if(row.key[1] == 0) stock = row.doc as StockIntrant;
                        else if(row.key[1] == 1) {
                            stock.nom = stock.nom ? stock.nom : '';
                            stock.site = row.doc as Site;
                            stocks.push(stock);
                        }
                    })
                    return stocks.length > 1? _.sortBy(stocks,(stock) => stock.nom.toLocaleLowerCase()) : stocks[0];
            }).catch(error => defer(()=>Promise.reject(error)))
    }

    public documents(id: string): any{
            return this._dbService.db
                    .query(
                            'StockIntrant/joins',
                            {
                                    include_docs: true,
                                    start_key: [id,2],
                                    end_key: [id,3]
                            }
                    )
                    .then( res =>    {
                            res = res.rows.map(d => d.doc);
                            return res;
                    })
                    .catch( error => defer(()=>Promise.reject(error)))
    }

    public bySite(id : string){

            return super.bySite(id).then( res =>    {
                            let stocks = new Array<StockIntrant>();
                            let sites = new Array<Site>();
                            res.rows.forEach( row => {
                                    if(row.key[1] == 0) stocks.push(row.doc as StockIntrant);
                                    else if(row.key[1] == 1) sites.push(row.doc as Site);
                            })
                            for(let i = 0; i< stocks.length; i++){
                                stocks[i].nom = stocks[i].nom ? stocks[i].nom : '';
                                stocks[i].site = sites[i];
                            }
                            return _.sortBy(stocks,(stock) => stock.nom.toLocaleLowerCase());
                    })
                    .catch( error => defer(()=>Promise.reject(error)))
    }

    public create(stock : StockIntrant){
        let user = JSON.parse(sessionStorage.getItem('loggedUser'));
        stock.createdBy = user.email;
        stock.createdOn = new Date().getTime();
        stock.modifiedBy = user.email;
        stock.modifiedOn = new Date().getTime();
        stock._id = 'stockIntrant_' + uuid();
        return this._dbService.db.put(stock.toDao());
    }



    public checkIfExists(stock: StockIntrant){
        if (stock.site) {
            const siteId = stock.site.id || stock.site._id;
            return this._dbService.db.query(
                'StockIntrant/by_site_and_nom',
                {
                    include_docs : true,
                    key : [siteId, stock.nom]
                }
            ).then(res => {
                let sameObject = false;
                if (res.rows.length === 0) {
                    return false;
                } else if (stock._id) {
                    res.rows.forEach(row => {
                        if (row.id !== stock._id) {
                            return true;
                        }
                    });
                    return sameObject;
                }
                return true;
            });
        } else {
            return Promise.resolve();
        }
    }

    public checkIfCodeExists(stock : StockIntrant){
        if(stock.site){
            let siteId = stock.site.id || stock.site._id;
            return this._dbService.db.query(
                'StockIntrant/by_site_and_code',
                {
                    include_docs : true,
                    key : [siteId,stock.code]
                }
            ).then(res => {
                let sameObject = false;
                if(res.rows.length == 0) return false;
                else if(stock._id){
                    res.rows.forEach(row => {
                        if(row.id != stock._id) {
                            return true;
                        }
                    })
                    return !sameObject;
                }
                return true;
            })
        }
    }


    //calcul le tonnage du stock en prenant en compte les recep/exped/rations depuis la dernière date de réinitialisation du stock
    // jusqu'à la date renseignée
    public getTonnageByStockAndDate(stock, date : any){
        let startDateFormat = moment(stock.dateTonnageRef).format("YYYY/MM/DD");
        if(moment(date).diff(moment(startDateFormat),'days')<=0){
            startDateFormat = "2014/01/01";
        }
        if(stock._id == 'stockIntrant_8eed8b7f-882b-40e9-bfee-40b4bc74bc2b'){
            console.log(startDateFormat+' '+moment(date).format("YYYY/MM/DD"));
        }
        
        return this._dbService.db.query(
            'StockIntrant/tonnage_avec_ration',
            {
                include_docs: false,
                start_key: [stock._id, startDateFormat],
                end_key: [stock._id, moment(date).format("YYYY/MM/DD")]
            }
        ).catch( error => defer(()=>Promise.reject(error)))
    }

    //calcul le tonnage du stock en prenant en compte les recep/recepPrévue/exped/rations depuis la dernière date de réinitialisation du stock
    // jusqu'à la date renseignée
    public getTonnagePrevuByStockAndDate(stock, date : any){
        let startDateFormat = moment(stock.dateTonnageRef).format("YYYY/MM/DD");
        if(moment(date).diff(moment(startDateFormat),'days')<=0){
            startDateFormat = "2014/01/01";
        }
        
        return this._dbService.db.query(
            'StockIntrant/tonnage_prevu_avec_ration',
            {
                include_docs: false,
                start_key: [stock._id, startDateFormat],
                end_key: [stock._id, moment(date).format("YYYY/MM/DD")]
            }
        ).then(res => {
            if(startDateFormat == stock.dateTonnageRef && !res.rows.length) return stock.tonnage;
            return res.rows[0].value;
        }).catch( error => defer(()=>Promise.reject(error)))
    }

    getWeekTonnageByStockAndDate(stock , dateDebut : any){
        let tonnages = new Array(7);
        let dateDebutFormat = new Date(dateDebut);
        // dateDebutFormat = new Date(dateDebutFormat.getFullYear(), dateDebutFormat.getMonth(), dateDebutFormat.getDate());
        let weekRange = this.dateService.getWeekRangeFromDate(dateDebut);
        let daysStarts = this.dateService.calculateDaysInBetween(weekRange.debut, weekRange.fin);
        let daysEnds = [];
        // dateDebutFormat est fixé au lundi minuit
        return this.getTonnageByStockAndDate(stock, dateDebut).then(tonnage => {
            let value;
            tonnage.rows[0] && tonnage.rows[0].value? value = tonnage.rows[0].value : value = 0;
            tonnages[0] = value;
            return tonnages;
        }).then(tonnages => {
            let promises = new Array(7);
            //pour tous les jours on se place entre le début et la fin de la journée pour aller chercher les réceptions du jour
            for(let i = 0; i < daysStarts.length; i++){
                let tmp = new Date(daysStarts[i].setHours(23));
                tmp.setMinutes(59);
                tmp.setSeconds(59);
                daysEnds.push(tmp.getTime());
                daysStarts[i].setHours(0)
                daysStarts[i] = daysStarts[i].getTime();
                promises[i] = this._dbService.db.query(
                    'StockIntrant/tonnage_sans_ration',
                    {
                        include_docs: false,
                        start_key : [ stock._id, moment(daysStarts[i]).format("YYYY/MM/DD")],
                        end_key : [ stock._id, moment(daysEnds[i]).format("YYYY/MM/DD")]
                    }
                ).then(res => {
                    let value;
                    res.rows[0] && res.rows[0].value? value = res.rows[0].value : value = 0;
                    tonnages[i]? tonnages[i]+= value : tonnages[i] = value;
                    return tonnages;
                }).catch(err => {
                    if(err.message == "Failed to fetch") {
                        console.log("timeout caught");
                        return -1;
                    }
                });
            }
            return Promise.all(promises).then(values => {
                return values[0];
            }).catch(err => console.error(err));
        }).then(tonnages => {
            return tonnages;
        }).catch(err => console.error(err));
    }

    getWeekTonnagePrevuForRationsByStockAndDate(stock , dateDebut : any){
        let tonnages = new Array(7);
        
        let weekRange = this.dateService.getWeekRangeFromDate(dateDebut);
        let daysStarts = this.dateService.calculateDaysInBetween(weekRange.debut, weekRange.fin);
        daysStarts = daysStarts.map((date: Date) => {
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return date;
        })
        let promises = new Array(7);
        promises = daysStarts.map(day => {
            return this.getTonnagePrevuByStockAndDate(stock, day.getTime()).then(tonnage => {
                return { tonnage, date: day.getTime()}
            })
        })
        // dateDebutFormat est fixé au lundi minuit
        return Promise.all(promises).then(tonnages => {
            return _.sortBy(tonnages, 'date').map(ton => ton.tonnage);
        }).catch(err => console.error(err));
    }

    public getWeekTonnagePrevuByStockAndDate(stock , dateDebut : any){
        let tonnages = new Array(7);
        let dateDebutFormat = new Date(dateDebut);
        dateDebutFormat = new Date(dateDebutFormat.getFullYear(), dateDebutFormat.getMonth(), dateDebutFormat.getDate());
        let weekRange = this.dateService.getWeekRangeFromDate(dateDebutFormat);
        let daysStarts = this.dateService.calculateDaysInBetween(weekRange.debut, weekRange.fin);
        let daysEnds = [];
        //dateDebutFormat est fixé au lundi minuit
        let dateEnd = new Date(weekRange.fin);
        dateEnd.setHours(23);
        let dateEndTime = dateEnd.getTime();
        let dateStart = daysStarts[0].getTime();
        return this._dbService.db.query(
          'StockIntrant/tonnage_prevu_avec_ration',
          {
            include_docs : false,
            start_key : [stock._id, moment(dateStart).format("YYYY/MM/DD")],
            end_key : [stock._id, moment(dateEndTime).format("YYYY/MM/DD")],
            group_level : 2
          }
        ).then(res => {
          let rows = res.rows;
          let tonnages = new Array(7);
          let dateW = dateStart;
          for(let i = 0; i < tonnages.length; i++){
            let row = rows.filter(row => {
              return moment(row.key[1],'YYYY/MM/DD').diff(moment(dateW)) >= 0 && moment(row.key[1],'YYYY/MM/DD').diff(moment(dateW).add(1,'days')) <=0;
            })
            row && row.length ? tonnages[i] = row.map(row => row.value).reduce((a,b)=>a+b,0) : tonnages[i] = 0;
            dateW += 24*60*60*1000;
          }
          return tonnages;
        })
    }

    public update(stock : StockIntrant){
        let user = JSON.parse(sessionStorage.getItem('loggedUser'));
        return this._dbService.db.get(stock._id).then(doc=>{
            stock.modifiedBy = user.email;
            stock.modifiedOn = new Date().getTime();
            stock.exported = false;
            stock.thirdPartyExport = false;
            let help = stock.toDao();
            delete help._rev;
            _.merge(doc, help);
            return this._dbService.db.put(doc);
        });
    }

    public delete(stock : StockIntrant){
        return this._dbService.db.get(stock._id).then(doc => {
            stock._rev = doc._rev;
            return super.linkedDocuments(stock._id).then(res =>{
                if(res.rows.length > 0){
                    stock.archived = true;
                    return this.update(stock);
                } else {
                    return this._dbService.db.remove(stock);
                }
            })
        });
    }

    public bulkDelete(stocks: StockIntrant[]){
        let promises = [];
        promises = stocks.map(stock => this.delete(stock));
        return Promise.all(promises);
    }


}
