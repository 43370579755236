import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nouvel-element',
  templateUrl: './nouvel-element.component.html',
  styleUrls: ['./nouvel-element.component.scss']
})
export class NouvelElementComponent implements OnInit {
  elements = [
    { name: "Nouvel événement", value: "evenement" },
    { name: "Nouvelle tâche", value: "tache" }
  ];
  element;
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  save() {
    this.activeModal.close(this.element);
  }

  close() {
    this.activeModal.close();
  }

}
