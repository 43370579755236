import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DocumentService} from 'global/services/document/document.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {OuvrageIncorporation} from 'shared-ration/models/ouvrage-incorporation';

@Injectable({
  providedIn: 'root'
})

export class OuvragesIncorporationService {

  private readonly _headers: any[];
  private readonly organisationId;
  private uriPath = 'ouvrageIncorporation';

  digesteurHeaders = [
    { nom: "label", label: "Nom" },
    { nom: "code", label: "Code" }
  ]

  constructor(
      private _documentService: DocumentService,
      private _datePipe: DatePipe,
      private http: HttpClient
  ) {
    this._headers = [
      { nom: 'nom', label: 'Nom' },
      { nom: 'tonnage', label: 'Tonnage' },
      { nom: 'code', label: 'Code' },
      { nom: 'volumeUtile', label: 'Volume utile' },
      { nom: 'dureeLissage', label: 'Durée de lissage' },
      { nom: 'source', label: 'Source' },
      { nom: 'description', label: 'Description' },
    ];
    this.organisationId = JSON.parse(sessionStorage.getItem('organisation')).id;
  }

  headers() {
    return this._headers;
  }

  getOuvragesBySite(siteId: string) {
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/`);
  }

  getOuvrage(siteId: string, ouvrageIncId: string) {
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${ouvrageIncId}`);
  }

  addOuvrage(siteId: string, ouvrageInc: OuvrageIncorporation) {
    return this.http.post(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/`, ouvrageInc, {responseType: 'text'});
  }

  updateOuvrage(siteId: string, ouvrageInc: OuvrageIncorporation, ouvrageIncId: string) {
    return this.http.put(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${ouvrageIncId}`, ouvrageInc, {responseType: 'text'});
  }

  deleteOuvrage(siteId: string, ouvrageIncId: string) {
    return this.http.delete(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${ouvrageIncId}`);
  }

  checkCode(siteId: string, code: string){
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/code/${code}`).toPromise();
  }
  
}
