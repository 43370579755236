import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-support-ticket-modal',
    templateUrl: './support-ticket-modal.component.html'
})
export class SupportTicketModalComponent implements OnInit {

    constructor(
        private _activeModal: NgbActiveModal,
    ) { }

    ngOnInit() {

    }

    close() {
        this._activeModal.close();
    }

}