import { Injectable } from '@angular/core';
import { BvTranslateService } from 'global/services/bv-translate/bv-translate.service';
import { Tiers } from 'tracabilite/models/dto/tiers';
import { Site } from 'global/models/dto/site';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';

import { defer } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TiersService extends CommonDatabaseQueryService{

  private tiers : Tiers[] = [];

  nodes = [
    {id:1, name: 'Code tiers', dataKey: 'codeTiers'},
    {id:2, }
  ]

  constructor(_databaseService : DatabaseService, private translateService: TranslateService, private bvTranslateService: BvTranslateService) {
    super(_databaseService,{
      all: 'Tiers/all',
      fetch_all: 'Tiers/fetch_all',
      joins : 'Tiers/joins',
      by_site : 'Tiers/by_site',
      linked_documents: 'Tiers/linked_documents'
    });
  }

    get headers() {
        return [
            {nom: 'nom', label: this.translateService.instant('app.global.headers.nom')},
            {nom: 'contact.nom', label: this.translateService.instant('app.global.headers.contactNom')},
            {nom: 'contact.numeroPortable', label: this.translateService.instant('app.global.headers.contactNumPort')},
            {nom: 'typeActivite', label: this.translateService.instant('app.global.headers.typeActivite'), list: this.bvTranslateService.getParametres().typeActivite},
        ];
    }

  public documents(id: string): any{
      return this._dbService.db
          .query(
              'GedDocument/by_documents',
              {
                  include_docs: true,
                  key: id
              }
          )
          .then( res => res.rows.map(d => d.doc))
          .catch( error => defer(()=>Promise.reject(error)))
  }

  /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public fetchAll(site: string=undefined,skip: number = 0, limit: number = 100,desc: boolean = false): any{
    return super.fetchAll(site,skip,limit,desc)
      .then( res => {
        let tiers = new Array<Tiers>();
        tiers = res.rows.map(row => row.doc);
        return this._dbService.db.query('Site/all')
          .then(sites =>{
            sites = sites.rows.map(site => site.value);
            tiers = tiers.map(t =>{
              let site = sites.find(s => s._id === t.site);
              if(site) {
                t.site = site;
              }
              return t;
            })
            tiers = _.sortBy(tiers,(t) => t.nom.toLocaleLowerCase());
            let total_rows = res.rows.length;
            if(res.rows.length >= limit){
              total_rows = (res.total_rows-res.offset) > limit ? res.total_rows-res.offset : limit;
            }
            return {rows: tiers, total_rows: total_rows};
          })
      })
      .catch(error => defer(()=>Promise.reject(error)))
  }

    async bySite(site: string = undefined, skip: number = 0, limit: number = 10000, desc: boolean = false) {
        const tiers = [];
        const sites = [];
        const res = await super.bySite(site, skip, limit, desc);
        if (res) {
            res.rows.forEach(row => {
                if (row.key[1] === 0) { tiers.push(row.doc as Tiers); }
                else if (row.key[1] === 1) { sites.push(row.doc as Site); }
            });
            for (let i = 0; i < tiers.length; i++) {
                tiers[i].site = sites[i].dataType === 'site' ? sites[i] : {nom: 'Tous les sites'} as any;
            }
        }
        const resSite = await this._dbService.query('Tiers/by_site', { include_docs: true, startkey: [null], endkey: [null, {}] });
        if (resSite) {
            resSite.rows.forEach( row => { if (row.key[1] === 0) { tiers.push(row.doc as Tiers); } });
        }
        return _.sortBy(_.uniqBy(_.flatten(tiers), '_id'), (t) => t.nom.toLocaleLowerCase());
    }

  public list(site : string = undefined, skip: number = 0, limit: number = 10000,desc: boolean = false){
      return super.list(site, skip,limit,desc)
        .then( res => {
            let tiers = new Array<Tiers>();
            let tier = new Tiers();
            for(let i = 0; i< res.rows.length; i++){
              if(res.rows[i].key[1] == 0){
                tier = res.rows[i].doc as Tiers;
                tiers.push(tier);
              }
              else if(res.rows[i].key[1] == 1) tier.site = res.rows[i].doc as Site;
            }
            return tiers;
        }).catch(error => defer(()=>Promise.reject(error)))
  }

  public joins(docId : string = undefined, skip: number = 0, limit: number = 10000,desc: boolean = false){
      return super.joins(docId, skip,limit,desc)
        .then( res => {
            let tiers = new Array<Tiers>();
            let tier = new Tiers();
            for(let i = 0; i< res.rows.length; i++){
              if(res.rows[i].key[1] == 0){
                tier = res.rows[i].doc as Tiers;
                tiers.push(tier);
              }
              else if(res.rows[i].key[1] == 1) tier.site = res.rows[i].doc as Site;
            }
            return tiers;
        }).catch(error => defer(()=>Promise.reject(error)))
  }


  public getIncrement(){
    let inc = this._dbService.increment("tiersCounter");
    let tiers = new Tiers();
    tiers.codeTiers = 'T'+inc;
    return this.checkIfExists(tiers).then(res => {
        if(!res) return inc;
        else return this.getIncrement();
    }).catch(error => defer(()=>Promise.reject(error)))
  }

  public create(tiers : Tiers){
    tiers._id = 'tiers_' + uuid();
    return this._dbService.db.put(tiers.toDao());
  }

  public add(tiers: Tiers){
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    tiers.createdBy = user.email;
    tiers.modifiedBy = user.email;
    tiers.createdOn = new Date().getTime();
    tiers.modifiedOn = new Date().getTime();
    if(tiers._id == undefined) tiers._id = 'tiers_'+uuid();
    return this._dbService.db.put(tiers.toDao());
  }

  public update(tiers : Tiers){
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    tiers.modifiedBy = user.email;
    tiers.modifiedOn = new Date().getTime();
    return this._dbService.db.get(tiers._id).then(doc=>{
      tiers._rev = doc._rev;
      return this._dbService.db.put(tiers.toDao())});
  }

  public upSert(tier: Tiers): any{
    if(tier._id && tier._rev) return this.update(tier);
    else return this.create(tier);
  }

  public delete(tiers : Tiers){
    return super.linkedDocuments(tiers._id)
      .then(res =>{
        if(res && res.rows.length) tiers.archived = true;
        else tiers._deleted = true;
        return this.upSert(tiers)
      })
      .catch(error => error)
  }

  public bulkDelete(tiers: Tiers[]){
    let promises = [];
    promises = tiers.map(tiers => this.delete(tiers));
    return Promise.all(promises);
  }

  public checkIfExists(tiers : Tiers){
      return this._dbService.db.query(
          'Tiers/by_codeTiers',
          {
              include_docs: true,
              key : tiers.codeTiers
          }
      ).then(res => {
          let sameObject = false;
          if(res.rows.length == 0) return false;
          else if(tiers._id){
              res.rows.forEach(row => {
                  if(row.id == tiers._id) {
                      sameObject = row.id == tiers._id;
                  }
              })
              return !sameObject;
          }
          return true;
      })
  }


  asyncData<T> (data:T) {
    return defer(()=>Promise.resolve(data));
  }
}
