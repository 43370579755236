import { Component, Input, ElementRef } from '@angular/core';
import { IColumn } from '../interfaces/IColumn';
import { IFilter } from '../interfaces/IFilter';
import { FilterService } from '../services/FilterService';
import { SortService } from '../services/SortService';

@Component({
	selector : 'column-filter-interval',
	templateUrl :'../views/column-filter-interval.html'
})
export class ColumnFilterIntervalComponent{
	@Input() column: IColumn;
	private min : number;
	private max : number;
	private elementRef
	

	constructor(
		private filterService_: FilterService,
		private sortService_: SortService, 
		private myElement : ElementRef
	){
		this.min = this.max = null;
		this.elementRef = myElement;
	}

	setColumnOrder(){
		this.sortService_.setColumnToSort(this.column);
	}

	clear(){
		this.min = this.max = null;
	}

	apply(){
		if( this.min && this.max && this.min<=this.max ) {
			let that =this;
			this.filterService_.addFilter({
				column: this.column,
				values: [
				{label: "interval", value: this.min.toString(), predicate : function(value){
						return value>=that.min && value<=that.max;
					} 
				}
				]
			})
		}else {this.filterService_.deleteFilter({
				column: this.column,
				values: []
			})
		}
	}

	// handleClick(event){
 //    	var clickedComponent = event.target;
 //    	console.log("----------------------");
 //    	console.log("clicked component",clickedComponent);
 //    	console.log("next next sibling",clickedComponent.nextSibling.nextSibling);
 //    	console.log("native element",this.elementRef.nativeElement);
	// 	let obj = this.elementRef.nativeElement.firstElementChild.firstElementChild.nextElementSibling;
	// 	console.log(obj);
 //    	var inside = false;
 //    	do {
 //        	if (clickedComponent === this.elementRef.nativeElement) {
 //            	inside = true;
 //        	}
 //        	clickedComponent = clickedComponent.parentNode;
 //    	} while (clickedComponent);
 //    	if(inside){
 //    	}else{
 //        	console.log('outside');

 //    	}
	// }
}