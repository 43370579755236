import { BreadcrumbService } from './../../../modules/global/services/breadcrumb/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportTicketService } from './../support-ticket.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-support-ticket-update',
  templateUrl: './support-ticket-update.component.html',
  styleUrls: ['./support-ticket-update.component.scss']
})
export class SupportTicketUpdateComponent implements OnInit {
  ticket;
  id;
  organisation;

  newComment = '';
  isLoading = false;
  loadingMessage = '';
  solvedBoolean = false;

  ticketImages = [];
  ticketFiles = [];
  imagesSize = [];
  private bloc: any = {};
  private blocModules: any = [];


  constructor(
    private supportTicketService: SupportTicketService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.bloc = {
      id: "support",
      label: "Support",
      modules: [
        { id: "tickets", label: "Mes tickets", readWrite: 1 },
        // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
      ]
    };
    this.blocModules = [
      { id: "tickets", label: "Mes tickets", readWrite: 1 },
      // {id: "guideUtilisation", label: "Guide d'utilisation", readWrite: 1}
    ];
    this.id = this.route.snapshot.paramMap.get("id");
    this.breadcrumbService.add({ label: this.id, route: this.router.routerState.snapshot.url });
    this.organisation = JSON.parse(sessionStorage.getItem('organisation'));
    this.getTicket();
  }

  getTicket() {
    this.isLoading = true;
    this.loadingMessage = 'Chargement';
    this.supportTicketService.getZendeskTicket(this.organisation, +this.id).subscribe(ticket => {
      ticket.comments.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      this.ticket = ticket;
      if (ticket.request.status === 'new') {
        ticket.request.status = 'ouvert'
      } else if (ticket.request.status === 'open') {
        ticket.request.status = 'ouvert'
      } else if (ticket.request.status === 'closed' || ticket.request.status === 'solved') {
        ticket.request.status = 'resolu'
      } else if (ticket.request.status === 'pending') {
        ticket.request.status = 'en attente'
      }
      for (let comment of ticket.comments) {
        for (let attachment of comment.attachments) {
          attachment.extension = attachment.file_name.substr(attachment.file_name.lastIndexOf('.') + 1).toLowerCase();
          attachment.file_name = attachment.file_name.substr(0, attachment.file_name.lastIndexOf("."));
        }
      }
      this.ticket.request.priority = this.getCustomField(360009067380, this.ticket.request.custom_fields)
      this.ticket.request.module = this.getCustomField(360008759880, this.ticket.request.custom_fields)
      this.ticket.request.onglet = this.getCustomField(360011048799, this.ticket.request.custom_fields)
      this.ticket.comments.map(comment => {
        let user = this.ticket.users.find(user => user.id === comment.author_id);
        comment.user = user;
      })
      if (this.ticket.request.module == "Autre_problème") this.ticket.request.module = "Autre problème";
      this.isLoading = false;
    })
  }

  private getCustomField(id: number, fields: any[]): any {
    let value = fields.find(field => field.id == id).value;
    if (value) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  addTicketImages(event) {
    this.imagesSize = [];
    let ticketImagesLength;
    this.ticketImages ? ticketImagesLength = this.ticketImages.length : ticketImagesLength = 0;
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size > 1048576) {
          this.imagesSize.push(event.target.files[i].name)
        }
        else if (ticketImagesLength >= 5) {
          return
        } else {
          this.ticketFiles.push({
            name: event.target.files.item(i).name,
            file: event.target.files.item(i),
            size: event.target.files.item(0).size
          })
          ticketImagesLength = ticketImagesLength + 1;
          if (event.target.files[i].type === 'application/pdf') {
            this.ticketImages.push('assets/images/pdf-icon-preview.png')
          }
          else if (event.target.files[i].type === 'text/plain') {
            this.ticketImages.push('assets/images/txt-icon-preview.png')
          }
          else if (event.target.files[i].type === 'text/csv') {
            this.ticketImages.push('assets/images/csv-icon-preview.png')
          }
          else {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.ticketImages.push(event.target.result);
            }

            reader.readAsDataURL(event.target.files[i]);
          }
        }
      }
    }
  }

  deleteTicketImages(id) {
    this.ticketImages.splice(id, 1);
    this.ticketFiles.splice(id, 1);
  }

  close() {
    this.breadcrumbService.removeFrom(-2);
    this.router.navigate(['support/tickets/list']);
  }

  onSubmit() {
    this.isLoading = true;
    const ticket = {
      "request": {
        "solved": this.solvedBoolean,
        "comment": {
          "body": this.newComment
        },
      }
    }
    let organisation = JSON.parse(sessionStorage.getItem('organisation'));
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.supportTicketService.updateZendeskTicket(organisation.id, user.email, this.ticket.request.id, this.ticketFiles, ticket)
      .subscribe(res => {
        this.ticketImages = [];
        this.ticketFiles = [];
        this.imagesSize = [];
        this.newComment = '';
        if (this.solvedBoolean) {
          let newTicketsArray = JSON.parse(sessionStorage.getItem("newTickets") || "[]");
          let ticket = newTicketsArray.find(ticket => ticket.id === +this.id);
          let index = newTicketsArray.indexOf(ticket);
          newTicketsArray[index].status = 'solved';
          sessionStorage.setItem("newTickets", JSON.stringify(newTicketsArray));
        }
        if (res.body) {
          this.getTicket();
        }
      }, error => {
        console.log(error)
      });
  }

  newTicket() {
    this.breadcrumbService.removeFrom(-2);
    this.breadcrumbService.add({ label: "Mes tickets", route: '/support/tickets/list' });
    this.router.navigate(['support/tickets/add']);
  }

}
