import { Injectable, OnInit } from '@angular/core';
import { defer, pipe, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import  * as _ from 'lodash';
import { ReceptionPrevue } from 'appro/models/dto/reception-prevue';
import { Site } from 'global/models/dto/site';
import { ReceptionPrevueDAO } from 'appro/models/dao/reception-prevue';
import { CustomDatepickerI18n } from 'global/services/custom-datepicker-i18n/custom-datepicker-i18n';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class ReceptionPrevueService extends CommonDatabaseQueryService{

    private receptions: ReceptionPrevue[];
    private _headers: any[];
    constructor(
        private dateService : CustomDatepickerI18n,
        private translateService: TranslateService,
        _databaseService: DatabaseService,
    ) {
        super(_databaseService,{
            all: 'ReceptionPrevue/all',
            joins: 'ReceptionPrevue/joins',
            by_site: 'ReceptionPrevue/by_site',
            tonnage_by_stock_and_date: 'ReceptionPrevue/tonnage_by_stock_and_date',
            by_site_and_numReception: 'ReceptionPrevue/by_site_and_numReception'
        });
    }

    get headers() {
        return [
            {nom: 'numReception', label : this.translateService.instant('app.global.headers.numero')},
            {nom: 'dateReception', label: this.translateService.instant('app.global.headers.date')},
            {nom: 'gisement.nom', label: this.translateService.instant('app.global.headers.gisement')},
            {nom: 'gisement.transporteur.nom', label: this.translateService.instant('app.global.headers.transporteur')},
            {nom: 'poidsNet', label: this.translateService.instant('app.global.headers.poidsNet')}
        ];
    }

    public get(id: string): any{
    	return super.joins(id)
        .then( res => {

          let reception: ReceptionPrevue;

          for(let i = 0; i< res.rows.length; i++){
            if(res.rows[i].key[1] == 0 && res.rows[i].doc){
              delete res.rows[i].doc.siteId;
              reception = res.rows[i].doc as ReceptionPrevue;
            }
            else if(res.rows[i].key[1] == 1 && res.rows[i].doc) reception.site = res.rows[i].doc as Site;
          }
          return reception;
        })
        .catch(error => defer(()=>Promise.reject(error)))
    }

    public add(reception: ReceptionPrevue){
  		if(reception._id == undefined) reception._id = 'receptionPrevue_'+uuid();
      if(reception.gisement.site) delete reception.gisement.site ;
  		return this._dbService.db.put(reception.toDao());
  	}

    public update(reception: ReceptionPrevue){
      reception.thirdPartyExport = false;
      reception.exported = false;
      if(reception.gisement.site){
        delete reception.gisement.site ;
      }
      if(reception._id == undefined || reception._rev == undefined)
        return defer(()=>Promise.reject("for updating neither _id, nore _rev can be undefined or null"));
      return this._dbService.db.put(reception.toDao());
	  }

  public upSert(reception: any){
    if(reception._id) return this.update(this.toReception(reception));
    else return this.add(this.toReception(reception));
  }

  public byDate(site:any, start: string,end: string){
    return this._dbService.db.query(
      'ReceptionPrevue/by_site_and_date',
      {
        include_docs: true,
        startkey: [site,start],
        endkey: [site,end]
      }
    );
  }

  public bulkReception(receptions: ReceptionPrevue[]): any{
    let receptionDAO = receptions.map(reception =>{
      if(!reception._id) reception._id = 'receptionPrevue_'+uuid();
      return reception.toDao();
    })
    return this._dbService.db.bulkDocs(receptionDAO);
  }

  public checkIfExists(reception : ReceptionPrevue){
	  if(reception.site){
		  let siteId = reception.site.id || reception.site._id;
		  return this._dbService.db.query(
			  'ReceptionPrevue/by_site_and_numReception',
			  {
				  include_docs: true,
				  key: [siteId,reception.numReception]
			  }
		  ).then(res =>{
			  let sameObject = false;
			  if(res.rows.length == 0) return false;
			  else if(reception._id){
				  res.rows.forEach(row => {
					  if(row.id == reception._id) sameObject = row.id == reception._id;
				  })
				  return !sameObject;
			  }
			  return true;
		  })
	  }
  }

    /**
  * Joins by site
  * @param site - site
  * @param skip - number of rows to skip
  * @param limit - max number of rows to return
  * @param desc - descending sort param
  */
  public bySite(site: string=undefined,skip: number = 0, limit: number = 10000,desc: boolean = false): any{
    return super.bySite(site,skip,limit,desc)
      .then( res => {
        let reception: ReceptionPrevue;
        let site: Site;
        let receptions = new Array<ReceptionPrevue>();
        let sites = new Array<Site>();
        for(let i = 0; i< res.rows.length; i++){
          if(res.rows[i].key[1] == 0 && res.rows[i].doc){
            delete res.rows[i].doc.siteId;
            reception = res.rows[i].doc as ReceptionPrevue;
            receptions.push(reception);
          }
          else if(res.rows[i].key[1] == 1 && res.rows[i].doc){
            site = res.rows[i].doc as Site;
            sites.push(site);
          }
        }
        sites.forEach((site,index)=>{
          receptions[index].site = site;
        })
        res.rows = _.sortBy(receptions,[function(recep){ return - recep.dateReception; }]);
        return res.rows;
      })
      .catch(error => defer(()=>Promise.reject(error)))
  }

  public bulkDelete(receptions: ReceptionPrevue[]){
    let promises = [];
    promises = receptions.map(reception => super.delete(reception));
    return Promise.all(promises);
  }

  private toReception(recep: Object): ReceptionPrevue{
    let reception: ReceptionPrevue = new ReceptionPrevue();
    Object.keys(recep)
      .forEach(key => reception[key] = recep[key]);
    return reception;
  }
}
