import { IColumn } from '../interfaces/IColumn';
import { IFilterable } from '../interfaces/IFilterable';
import { AlignRule } from '../enums/align-rule';

export class Column implements IColumn{
	dataKey: string;
  name: string;
  align: AlignRule;
  visible: boolean;
  exclude?: boolean;
  filterable?: IFilterable;
  list?: [{id: string, label: string}];

  constructor(){
  	this.visible = false;
  }

  isLeftAligned():boolean {
    return this.align === AlignRule.Left;
  };

  isRightAligned():boolean {
    return this.align === AlignRule.Right;
  };
}