import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRow } from '../interfaces/IRow';
import { DataService } from '../services/DataService';
import { SortService } from '../services/SortService';

@Component({
	selector:'[table-row]',
	templateUrl: '../views/table-row.html'
})
export class TableRowComponent{
	@Input('table-row') tableRow: IRow;
	@Output('onselect') onSelect: EventEmitter<any> = new EventEmitter();
	@Output('onclick') onClick : EventEmitter<any> = new EventEmitter();

	constructor(
		private dataService: DataService,
		private sortService: SortService
	){

	}

	toggleSelection(){
		if(this.tableRow.selected) {
			this.dataService.selectRow(this.tableRow);
		}
		else {
			this.dataService.deselectRow(this.tableRow);
		}
		this.onSelect.emit(this.dataService.getSelectedRows());
  	}

  	rowClicked(){
  		this.onClick.emit(this.tableRow);
  	}
}