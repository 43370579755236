import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { IColumn } from '../interfaces/IColumn';

@Component({
	selector: 'table-column-selector',
	templateUrl: '../views/table-column-selector.html'
})
export class TableColumnSelectorComponent implements OnChanges, OnInit{
	@Input() columns: Array<IColumn>;
	@Output('onselectcolumns') onColumnSelected : EventEmitter<any> = new EventEmitter();
	private selectedColumns_: Array<Boolean>;
	constructor(){
	}

	ngOnInit(){
		this.selectedColumns_ = new Array<Boolean>();
		_.each(this.columns,(aColumn : IColumn)=>{
			this.selectedColumns_.push((aColumn.visible || !aColumn.exclude)) 
		})
	}

	ngOnChanges(changes: SimpleChanges){
		const columns: SimpleChange = changes.columns;
		this.selectedColumns_ = new Array<Boolean>();
		_.each(columns.currentValue, (aColumn: IColumn)=>{
			this.selectedColumns_.push(false);
		})
	}

	selectAll(){
		this.selectedColumns_ = _.map(this.selectedColumns_,(status: boolean)=>{
			return true;
		})
	}

	clearAll(){
		this.selectedColumns_ = _.map(this.selectedColumns_,(status: boolean)=>{
			return false;
		})
	}

	apply(){
		_.each(this.selectedColumns_,(status: boolean, index: number)=>{
			this.columns[index].visible = status;
			this.columns[index].exclude = !status;
		})
		this.onColumnSelected.emit(this.columns);
	}
}