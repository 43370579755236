import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

	private _perimetreSource = new BehaviorSubject(undefined);

  constructor() { }

  add(type: string="info",message: string){
  	this._perimetreSource.next({type: type, message: message});
  }

  message(){
  	return this._perimetreSource.asObservable();
  }
}
