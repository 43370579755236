import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TREE_ACTIONS, KEYS, IActionMapping, TreeComponent } from 'angular-tree-component';
import * as _ from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit {

	@Input('nodes') fieldNodes: any[];
	@ViewChild('leftTree', { static: false }) leftTree: TreeComponent;
	@ViewChild('rightTree', { static: false }) rightTree: TreeComponent;
	fileType: string;
	leftOptions: any;
	rightOptions: any;
	selectedNodes: any[];

	private selectedTree: any;
	private selectEvent: any
	private selectedNode2: any;
	private selectedNode1: any;

  constructor(
  	private _activeModal: NgbActiveModal,
	private translateService: TranslateService
	) { }

  ngOnInit() {
	  this.fieldNodes.map(node => {
		  if(typeof node.name === 'object'){
		  	let name = '';
		  	node.name.forEach(string => {
				if(string.includes('app.global.headers')){
					name = name + this.translateService.instant(string);
				} else {
					name = name + string;
				}
			});
		  	node.name = name;
		  } else {
			  node.name = this.translateService.instant(node.name);
		  }
	  	return node;
	  });
	  this.fileType = 'csv';
		this.selectedNodes = [];
		this.leftOptions = {
			allowDrag: (node) => node.isLeaf,
			allowDrop: false,
			actionMapping: {
				mouse: {
					click: (tree, node, $event) => {
						if(node.isLeaf){
							TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event)
							let children = _.find(this.selectedNodes, (n)=>{ return node.data.id == n.parent});
							if(children == undefined) this.selectedNode1 = node;
							else this.selectedNode1 = undefined;
						}
					}
				}
			}
		};
		this.rightOptions = {
			allowDrag: true,
			allowDrop: (element, {parent, index}) => parent.parent == null,
			actionMapping: {
				mouse: {
					click: (tree, node, $event) => {
						TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event)
						this.selectedNode2 = node;
						this.selectEvent = $event;
						this.selectedTree = tree;
					}
				}
			}
		};
  }

	deleteNode(node: any){
		if(node != undefined && node != null){
			_.remove(this.selectedNodes, (n) => {
				return node.id == n.id;
			})
			// child node
			if(node.data.parent) this.addNodeToList(this.fieldNodes,node.data);
			// root node
			else this.fieldNodes.push(node.data);
			// update trees
			this.leftTree.treeModel.update();
			this.rightTree.treeModel.update();
		}
	}

	allowDrop(element){
		this.addNode(element);
	}

	addAllNodes(){
		this.leftTree.treeModel.doForAll(node => node.isLeaf? this.addNode(node) : null);
	}

	addNode(node: any){
		if(node != undefined && node != null){
			this.deleteNodeFromList(this.fieldNodes,node);
			this.selectedNodes.push(node.data);
			this.selectedNodes = _.uniqBy(this.selectedNodes,'id');
			this.rightTree.treeModel.update();
			this.leftTree.treeModel.update();
		}
	}

	drillUp(node: any){
		if(node != undefined && node.index != 0){
			[this.selectedNodes[node.index-1],this.selectedNodes[node.index]] = [this.selectedNodes[node.index],this.selectedNodes[node.index-1]];
			node.index--;
			this.rightTree.treeModel.update();
		} 
	}

	drillDown(node: any){
		if(node != undefined && node.index < this.selectedNodes.length-1){
			[this.selectedNodes[node.index+1],this.selectedNodes[node.index]] = [this.selectedNodes[node.index],this.selectedNodes[node.index+1]];
			node.index++;
			this.rightTree.treeModel.update();
		} 
	}

	deleteSelectedNodes(){
		_.each(this.selectedNodes, (node)=>{
			// child node
			if(node.parent) this.addNodeToList(this.fieldNodes,node);
			// root node
			else this.fieldNodes.push(node);
		})
		this.selectedNodes = [];
		this.rightTree.treeModel.update();
		this.leftTree.treeModel.update();
	}

	private deleteNodeFromList(list: any,node: any): any{
		_.remove(list,(n:any)=>{
			if(n.children) this.deleteNodeFromList(n.children,node);
			return n.id == node.id;
		})
	}

	private addNodeToList(list: any,node: any): any{
		for(let i in list){
			if(list[i].id == node.parent) list[i].children.push(node);
			else this.addNodeToList(list[i].children, node);
		} 
	}

  close(){
  	this._activeModal.dismiss('dismiss');
  }

  export(){
  	this._activeModal.close(this.selectedNodes);
  }

}
