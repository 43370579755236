import { SupportTicketUpdateComponent } from './components/support-tickets/support-ticket-update/support-ticket-update.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeBoardComponent } from 'home-board/home-board.component';
import { OrgaBoardComponent } from 'orga-board/orga-board.component';
import { CanDeactivateGuard } from 'global/services/can-desactivate-guard/can-deactivate-guard.service';
import { LoginComponent } from 'login/login.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { UserPasswordComponent } from './components/user/user-password/user-password.component';
import { UserHomeComponent } from './components/user/user-home/user-home.component';
import { UserProfilComponent } from './components/user/user-profil/user-profil.component';
import { UserAccessComponent } from './components/user/user-access/user-access.component';
import { SupportTicketAddComponent } from './components/support-tickets/support-ticket-add/support-ticket-add.component';
import { SupportTicketListComponent } from './components/support-tickets/support-ticket-list/support-ticket-list.component';



const routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{
		path: 'user',
		component: UserHomeComponent,
		children: [
			{ path: 'password', component: UserPasswordComponent },
			{ path: 'profil', component: UserProfilComponent },
			{ path: 'access', component: UserAccessComponent }
		]
	},
	{ path: 'login', component: LoginComponent },
	{ path: 'home-board', component: HomeBoardComponent },
	{ path: 'orga-board', component: OrgaBoardComponent },
	{
		path: 'support',
		children: [
			{
				path: 'tickets',
				children: [
					{ path: 'list', component: SupportTicketListComponent },
					{ path: 'add', component: SupportTicketAddComponent },
					{ path: ':id', component: SupportTicketUpdateComponent },
					{ path: '', redirectTo: 'list', pathMatch: 'full' }
				]
			},
		]
	},
	{ path: 'timeout', component: TimeoutComponent },
	{ path: 'password-forgot', component: PasswordForgotComponent },
	{ path: 'resetPassword', component: PasswordResetComponent },
]

@NgModule({
	exports: [
		RouterModule
	],
	imports: [
		RouterModule.forRoot(routes, { useHash: true }),
	],
	declarations: [],
	providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
