import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerimetreService {

    private _perimetreSource = new BehaviorSubject(null);
    private _perimetreDisabled = new BehaviorSubject(false);
    public perimetre$ = this._perimetreSource.asObservable();
    public perimetreDisabled$ = this._perimetreDisabled.asObservable();

    public changePerimetre(newPerimetre){
        sessionStorage.setItem('perimetre',JSON.stringify(newPerimetre));
        this._perimetreSource.next(newPerimetre);
    }

    public disablePerimetre(disabled){
        this._perimetreDisabled.next(disabled);
    }
  constructor() { }
}
