import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, merge, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

    private _breadcrumbSource = new BehaviorSubject([]);
    public breadcrumb = this._breadcrumbSource.asObservable();

    add(breadcrumbItem : any){
        let tmp;
        if(sessionStorage.getItem('tmpBreadcrumb')) {
            tmp = JSON.parse(sessionStorage.getItem('tmpBreadcrumb'));
            sessionStorage.removeItem('tmpBreadcrumb');
        } else {
            tmp = this._breadcrumbSource.getValue();
        }
        tmp[0] ? null : tmp.push({label : JSON.parse(sessionStorage.getItem('perimetre')).nom, route :"home-board"});
        let index = _.findIndex(tmp,['label',breadcrumbItem.label]);
        if(index < 0 ){
            tmp.push(breadcrumbItem);
            this._breadcrumbSource.next(tmp);
        } else {
            tmp.splice(index+1);
            tmp[0] ? null : tmp.push({label : JSON.parse(sessionStorage.getItem('perimetre')).nom, route :"home-board"});
            this._breadcrumbSource.next(tmp);
        }
    }

    getBeforeLast() {
        const breadcrump = this._breadcrumbSource.getValue();
        return breadcrump[(this._breadcrumbSource.getValue().length - 2)];
    }

    getLast() {
        const value = this._breadcrumbSource.getValue();
        return value[value.length - 1];
    }

    removeLast(){
        let tmp = this._breadcrumbSource.getValue();
        tmp.pop();
        this._breadcrumbSource.next(tmp);
    }

    removeFrom(index : number){
        let tmp = this._breadcrumbSource.getValue();
        tmp.splice(index);
        this._breadcrumbSource.next(tmp);
    }

    changePerimetre(breadcrumbItem : any){
        let tmp = this._breadcrumbSource.getValue();
        tmp[0]? tmp[0] = breadcrumbItem : tmp = [breadcrumbItem];
        this._breadcrumbSource.next(tmp);
    }

    reset(){
        let tmp = JSON.parse(sessionStorage.getItem('perimetre')).nom;
        this._breadcrumbSource.next([{label : tmp, route : "home-board"}]);
    }

  constructor(private router : Router) { }
}
