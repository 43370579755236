import { Injectable } from '@angular/core';
import { BvTranslateService } from 'global/services/bv-translate/bv-translate.service';
import { AnalyseIntrant } from 'intrants/models/dto/analyse-intrant';
import { DatabaseService } from 'global/services/database/database.service';
import { CommonDatabaseQueryService } from 'global/services/common-database-query/common-database-query.service';
import { DocumentService } from 'global/services/document/document.service';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { Site } from 'global/models/dto/site';
import * as uuid from 'uuid/v4';
import { defer, pipe, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AnalysesIntrantService extends CommonDatabaseQueryService{

    cpc = ["MS/MB","MO/MB","PM/MB stand","PM/MB flash","N-NH4","Norg","Ntot","Nkjeldahl","P2O5","K2O"]

    constructor(_databaseService : DatabaseService, private _documentService: DocumentService, private translateService: TranslateService, private bvTranslateService: BvTranslateService) {
      super(_databaseService,{
        all: 'AnalyseIntrant/all',
        joins : 'AnalyseIntrant/joins',
        document : 'AnalyseIntrant/document',
        by_site : 'AnalyseIntrant/by_site'
      });
    }

    get headers() {
        return [
            {nom : 'gisement.nom', label : this.translateService.instant('app.global.headers.gisement')},
            {nom : 'dateAnalyse', label : this.translateService.instant('app.global.headers.dateAnalyse')},
            {nom: 'numLot', label: this.translateService.instant('app.global.headers.nLot')},
            {nom : 'type', label : this.translateService.instant('app.global.headers.typeAnalyse'), list: this.bvTranslateService.getParametres().typeAnalyse},
            {nom : 'MS/MB', label : this.translateService.instant('app.global.headers.MS/MB%')},
            {nom : 'MO/MB', label : this.translateService.instant('app.global.headers.MO/MB%')},
            {nom : 'PM/MB', label : this.translateService.instant('app.global.headers.PM/MB%')},
            {nom: 'Ntot', label : this.translateService.instant('app.global.headers.azoteTotale%')}
        ];
    }

    public list(){
        return super.list()
            .then( res => {
                let analyses = new Array<AnalyseIntrant>();
                let analyse = new AnalyseIntrant();
                res.rows.forEach( row => {
                    if(row.key[1] == 0) analyse = row.doc as AnalyseIntrant;
                    else if(row.key[1] == 1) {
                        analyse.site = row.doc as Site;
                        analyses.push(analyse);
                    }
                })
                return _.sortBy(analyses,(analyse) => analyse.nom.toLocaleLowerCase());
            })
            .catch( error => defer(()=>Promise.reject(error)))
    }

    public documents(id: string): any{
        return this._dbService.db
            .query(
                'AnalyseIntrant/document',
                {
                    include_docs: true,
                    start_key: [id,2],
                    end_key: [id,3]
                }
            )
            .then( res => res.rows.map(d => d.doc))
            .catch( error => defer(()=>Promise.reject(error)))
    }

    public joins(docId : string = undefined, skip: number = 0, limit: number = 10000,desc: boolean = false){
        return super.joins(docId, skip,limit,desc)
          .then( res => {
              let analyses = new Array<AnalyseIntrant>();
              let analyse = new AnalyseIntrant();
              for(let i = 0; i< res.rows.length; i++){
                if(res.rows[i].key[1] == 0){
                  analyse = res.rows[i].doc as AnalyseIntrant;
                  analyses.push(analyse);
                }
                else if(res.rows[i].key[1] == 1) analyse.site = res.rows[i].doc as Site;
                else if(res.rows[i].key[1] == 2) analyse.gisement = res.rows[i].doc as Gisement;
              }
              return _.sortBy(analyses,(analyse) => analyse.nom.toLocaleLowerCase());
          }).catch(error => defer(()=>Promise.reject(error)))
    }

    public bySite(id : string){
        return super.bySite(id).then( res =>  {
            let analyses = new Array<AnalyseIntrant>();
            let sites = new Array<Site>();
            let gisements = new Array<Gisement>();
            res.rows.forEach( row => {
                if(row.key[1] == 0) analyses.push(row.doc as AnalyseIntrant);
                else if(row.key[1] == 1) sites.push(row.doc as Site);
                else if(row.key[1] == 2) gisements.push(row.doc as Gisement);
            })
            for(let i = 0; i< analyses.length; i++){
                analyses[i].site = sites[i];
            }
            for(let i = 0; i< analyses.length; i++){
                analyses[i].gisement = gisements[i];
            }
            return _.sortBy(analyses,(analyse) => analyse.nom.toLocaleLowerCase());
        })
        .catch( error => defer(()=>Promise.reject(error)))
    }

    public getIncrement(){
      return this._dbService.increment("analyseCounter");
    }

    public create(analyse : AnalyseIntrant){
      let user = JSON.parse(sessionStorage.getItem('loggedUser'));
      analyse.createdBy = user.email;
      analyse.createdOn = new Date().getTime();
      analyse.modifiedBy = user.email;
      analyse.modifiedOn = new Date().getTime();
      analyse._id = 'analyseIntrant_' + uuid();
      let tmp = new AnalyseIntrant();
      _.merge(tmp,analyse);
      return this._dbService.db.put(tmp.toDao());
    }

    public update(analyse : AnalyseIntrant){
      let user = JSON.parse(sessionStorage.getItem('loggedUser'));
      return this._dbService.db.get(analyse._id).then(doc=>{
        analyse.modifiedBy = user.email;
        analyse.modifiedOn = new Date().getTime();
        analyse._rev = doc._rev;
        analyse.exported = false;
        analyse.thirdPartyExport = false;
        let tmp = new AnalyseIntrant();
        _.merge(tmp,analyse);
        return this._dbService.db.put(tmp.toDao())});
    }

    public delete(analyse : AnalyseIntrant){
      return this._dbService.db.get(analyse._id).then(doc => {
        analyse._rev = doc._rev;
        return this._dbService.db.query(
            'Gisement/all',
            {
                include_docs: true
            }).then(res => {
                res = res.rows.map(row => row.doc)
                res.forEach(gisement=> {
                    gisement.analyses = _.difference(gisement.analyses, [analyse._id]);
                })
                return res;
            }).then(gisements => {
                this._dbService.db.bulkDocs(gisements).catch(error => defer(()=>Promise.reject(error)));
            }).then(res => {
                return this._dbService.db.remove(analyse);
            })
        })
    }

    public bulkDelete(analyses: AnalyseIntrant[]){
        let promises = [], merges = [];
        promises = analyses.map(analyse => this.delete(analyse));
        let organisation = JSON.parse(sessionStorage.getItem('organisation'));
        from(analyses).pipe(
          concatMap(analyse => this._documentService.deleteDocuments(organisation.id,analyse._id))
        ).subscribe(res => {
          console.log(res);
        });
        return Promise.all(promises);
    }

    public byDateAndSite(start: string,end: string,site:any=undefined){
      let options: any = {
        include_docs: true
      }
      let promise = undefined;
      if(site) {
        options.startkey = [site,start,0];
        options.endkey = [site,end,2];
        promise = this._dbService.db.query('AnalyseIntrant/by_date_and_site',options)
      }
      else{
        options.startkey = [start,0];
        options.endkey = [end,2];
        promise = this._dbService.db.query('AnalyseIntrant/by_date',options)
      }
      return promise.then(res =>{
        let analyses = new Array<AnalyseIntrant>();
        let sites = new Array<Site>();
        let gisements = new Array<Gisement>();
        res.rows.forEach( row => {
          if(row.key[2] == 0 || row.key[1] == 0) analyses.push(row.doc as AnalyseIntrant);
          else if(row.key[2] == 1 || row.key[1] == 1) sites.push(row.doc as Site);
          else if(row.key[2] == 2 || row.key[1] == 2) gisements.push(row.doc as Gisement);
        })
        for(let i = 0; i< analyses.length; i++) {
          analyses[i].site = sites[i];
        }
        for(let i = 0; i< analyses.length; i++){
          analyses[i].gisement = gisements[i];
        }
        return _.sortBy(analyses,(analyse) => -analyse.dateAnalyse);
      })
      .catch(error => defer(()=>Promise.reject(error)));
    }
}
