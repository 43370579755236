import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LoginService } from 'global/services/login/login.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';

@Injectable()
export class XhrInterceptor implements HttpInterceptor {

  constructor(
    private loginService: LoginService,
    private router: Router,
    private confirmationGuard: ConfirmationGuardService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let xhr = req.clone();
    if (req.url.includes('https://biogasview.zendesk.com')) {
      next.handle(req);
    }
    else if (!req.url.includes('/anonymous')) {
      const userLogged = sessionStorage.getItem('user');
      const session = sessionStorage.getItem('session');
      if (session != undefined) {
        xhr = req.clone({
          headers: req.headers
            .set('X-Requested-With', 'XMLHttpRequest')
            .set('Access-Control-Allow-Origin', '*')
            .set('Authorization', `Bearer ${session}`),
          withCredentials: true
        });
      }
      else {
        xhr = req.clone({
          headers: req.headers
            .set('X-Requested-With', 'XMLHttpRequest')
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*'),
          withCredentials: true
        });
      }
    }
    return next.handle(xhr).pipe(
      tap((event: HttpEvent<any>) => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401 && !req.url.includes('/authorization')) {
            this.confirmationGuard.alert('Votre session a expiré, vous devez vous reconnecter', 'sm')
              .then(() => {
                sessionStorage.setItem('lastRoute', this.router.url)
                this.router.navigate(["/login"]);
              })
          }
        }
      })
    )
  }
}