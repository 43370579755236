import { SiteDocument } from 'global/models/dto/site-document';


export class AnalyseLabo extends SiteDocument{
	public dateAnalyse : any;
	public laboratoire : string;
	public numLot : string;
	public numAnalyse : string;
	public commentaire : string;
	constructor(){
		super();
	}
}
