import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { Famille } from '../famille';
import { UniteFamille } from '../unite-famille';

export class VariableDAO extends SiteDocumentDAO {

	code: string;
	nom: string;
	equipement: string;
	nomComplet: string;
	famille: Famille;
	unite: UniteFamille;
	frequence: any;
	description: string;
	seuilHaut: number;
	seuilHautBloquant: number;
	seuilBas: number;
	seuilBasBloquant: number;
	valeurParDefaut: string;
	consolidation: string;
	type: string;
	valeurs: string[];

	constructor() {
		super();
		this.dataType = 'variable';
	}
}