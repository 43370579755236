export class BiogasviewDocument{
	_id: string;
	_rev: string;
	dataType: string;
	createdBy: string;
	modifiedBy: string;
	attachments: any;
	createdOn: number;
	modifiedOn: number;
	archived: boolean;
	_deleted: boolean;
}