import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BvTranslateService } from 'global/services/bv-translate/bv-translate.service';
import { isNumber, padNumber, toInteger } from 'global/services/util/util';
import * as moment from 'moment-timezone';

const I18N_VALUES = {
    'fr': {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        weekdaysFullname: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
        months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
        monthsFullname: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    },
    'it': {
        weekdays: ['|Lu', '|Ma', '|Me', '|Je', '|Ve', '|Sa', '|Di'],
        weekdaysFullname: ['|Lundi', '|Mardi', '|Mercredi', '|Jeudi', '|Vendredi', '|Samedi', '|Dimanche'],
        months: ['|Jan', '|Fév', '|Mar', '|Avr', '|Mai', '|Juin', '|Juil', '|Aou', '|Sep', '|Oct', '|Nov', '|Déc'],
        monthsFullname: ['|Janvier', '|Février', '|Mars', '|Avril', '|Mai', '|Juin', '|Juillet', '|Aôut', '|Septembre', '|Octobre', '|Novembre', '|Décembre'],
    }
    // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable({
    providedIn: 'root'
})
export class I18n {
    language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable({
    providedIn: 'root'
})
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private _i18n: I18n, private ngbDateAdapter: NgbDateAdapter<Date>, private bvTranslateService: BvTranslateService, private translateService: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
    }

    getWeekdayFullName(weekday: number): string {
        return I18N_VALUES[this.translateService.currentLang].weekdaysFullname[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this.translateService.currentLang].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return I18N_VALUES[this.translateService.currentLang].monthsFullname[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct) {
        return date.day + '/' + date.month + '/' + date.year;
    }

    getDayRange(date) {
        let weekday = this.getWeekdayFullName(date.getDay());
        if (weekday === undefined) {
            weekday = 'Dimanche';
        }
        return '' + weekday + ' ' + date.getDate() + ' ' + this.getMonthFullName(date.getMonth() + 1) + ' ' + date.getFullYear();
    }

    getMonthYearRange(dateStart, dateEnd?) {
        let number = 1;
        if (dateStart && dateEnd) {
            if (dateEnd.getMonth() - dateStart.getMonth() === 2) {
                number = 2;
            }
        }
        return '' + this.getMonthFullName(dateStart.getMonth() + number) + ' ' + dateStart.getFullYear();
    }

    getWeekAndDaysRange(dateStart, dateEnd) {
        const date = new Date(dateStart.getTime());
        dateEnd = new Date(date.setDate(dateStart.getDate() + 6));
        return '' + dateStart.getDate() + ' ' + this.getMonthFullName(dateStart.getMonth() + 1) + ' ' + dateStart.getFullYear() + ' - ' + dateEnd.getDate() + ' ' + this.getMonthFullName(dateEnd.getMonth() + 1) + ' ' + dateEnd.getFullYear();
    }

    calculateDaysInBetweenToString(debut, fin) {
        const dateDebut = new Date(debut);
        const dateFin = new Date(fin);
        const days = [];
        if (dateDebut && dateFin && debut < fin) {
            const date = new Date(dateDebut);
            while (date.getTime() < dateFin.getTime()) {
                const dateString = this.toLocaleDateStringCustom(date);
                days.push(dateString);
                date.setDate(date.getDate() + 1);
            }
        }
        return days;
    }

    calculateDaysInBetween(debut, fin) {
        const dateDebut = new Date(debut);
        const dateFin = new Date(fin);
        const days = [];
        if (dateDebut && dateFin && debut < fin) {
            let date = new Date(dateDebut);
            while (date.getTime() < dateFin.getTime()) {
                days.push(date);
                date = new Date(date);
                date.setDate(date.getDate() + 1);

            }
        }
        return days;
    }

    toLocaleDateStringCustom(date: Date) {
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const locale = this.bvTranslateService.getLocale();
        let dateString = date.toLocaleDateString(locale, options);
        dateString = dateString[0].toUpperCase() + dateString.slice(1);
        return dateString;
    }

    getDay(jsDay: number): number{
        const temp = [6,0,1,2,3,4,5];
        return temp[jsDay];
    }

    getWeekRangeFromDate(date) {
        date = new Date(date);
        const dateDebut = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const numOfdaysPastSinceLastMonday = this.getDay(date.getDay());
        dateDebut.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
        const dateFin = new Date(dateDebut);
        dateFin.setDate(dateDebut.getDate() + 6);
        dateFin.setHours(23);
        dateFin.setMinutes(59);
        dateFin.setSeconds(59);
        return { debut: dateDebut, fin: dateFin };
    }

    getWeekRangeFromDate2(date) {
        date = new Date(date);
        const dateDebut = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const numOfdaysPastSinceLastMonday = this.getDay(date.getDay()) >= 0 ? this.getDay(date.getDay()) : 6;
        dateDebut.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
        const dateFin = new Date(dateDebut);
        dateFin.setDate(dateDebut.getDate() + 6);
        dateFin.setHours(23);
        dateFin.setMinutes(59);
        dateFin.setSeconds(59);
        return { debut: dateDebut, fin: dateFin };
    }

    getDateFromModel(date: number) {
        if (date) {
            const tmp = new Date(date);
            let dateFormat = '';
            dateFormat = '' + padNumber(tmp.getDate()) + '/' + padNumber(tmp.getMonth() + 1) + '/' + tmp.getFullYear();
            return dateFormat;
        }
    }

    getDateAndTimeFromModel(date: number) {
        if (date) {
            let tmp = new Date(date);
            let dateFormat = '';
            dateFormat = '' + padNumber(tmp.getDate()) + '/' + padNumber(tmp.getMonth() + 1) + '/' + tmp.getFullYear() + ' ';
            if (tmp.getHours() < 10) {
                dateFormat += '0' + tmp.getHours() + ':';
            } else {
                dateFormat += tmp.getHours() + ':';
            }
            if (tmp.getMinutes() < 10) {
                dateFormat += '0' + tmp.getMinutes() + ':';
            } else {
                dateFormat += tmp.getMinutes() + ':';
            }
            if (tmp.getSeconds() < 10) {
                dateFormat += '0' + tmp.getSeconds();
            } else {
                dateFormat += tmp.getSeconds();
            }
            return dateFormat;
        }
    }

    getMonthRangesByYear(year: number) {
        let date = new Date(year, 0, 1);
        let result = [];
        let dateDebutMois = new Date(date);
        let dateFinMois = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
        result.push([dateDebutMois, dateFinMois]);
        for (let i = 1; i < 12; i++) {
            dateDebutMois = new Date(dateDebutMois.getFullYear(), dateDebutMois.getMonth() + 1, 1);
            dateFinMois = new Date(dateDebutMois.getFullYear(), dateDebutMois.getMonth() + 1, 0, 23, 59, 59);
            result.push([dateDebutMois, dateFinMois]);
        }
        return result;
    }

    getDateMonthYearFromModel(date: number) {
        if (date) {
            let tmp = new Date(date);
            let dateFormat = '';
            dateFormat = '' + padNumber(tmp.getMonth() + 1) + '/' + tmp.getFullYear();
            return dateFormat;
        }
    }

    public stringFromDateRange(dateRange) {
        return this.translateService.instant('app.global.prepositions.du') + ' ' + this.getDateFromModel(dateRange.debut.getTime()) + ' ' + this.translateService.instant('app.global.prepositions.au') + ' ' + this.getDateFromModel(dateRange.fin.getTime());
    }

    ngbDateFromDate(date: Date): NgbDateStruct {
        return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
    }

    dateFromNgbDate(date: NgbDateStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }

    getModelFromDateString(value: string): number {
        let time: number;
        if (typeof value === 'string') {
            const dateParts = value.trim().split(' ').length >= 1 ? value.trim().split(' ')[0].split('/') : value.trim().split('/');
            const timeParts = value.trim().split(' ').length >= 2 ? value.trim().split(' ')[1].split(':') : ['00', '00'];
            if (dateParts.length == 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                time = +(new Date(toInteger(dateParts[2]), toInteger(dateParts[1]) - 1, toInteger(dateParts[0]), toInteger(timeParts[0]), toInteger(timeParts[1])).getTime());
            }
            if (dateParts.length == 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                time = +(new Date(toInteger(dateParts[1]), toInteger(dateParts[0]) - 1).getTime());
            }
        } else if (typeof value === 'number') time = value;
        return this.getTimeZoneTime(time);
    }

    isDateString(dateString: any) {
        if (typeof dateString === 'string') {
            const dateParts = dateString.trim().split('/');
            if (dateParts.length == 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return true;
            }
            if (dateParts.length == 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return true;
            }
        }
        return false;
    }

    timeZoneDate(zoneId?: string): number {
        let timeZone = zoneId != undefined && zoneId != '' ? zoneId : 'Europe/Paris';
        return moment().tz(timeZone).valueOf();
    }

    getTimeZoneTime(date: any, zoneId?: string): number {
        let timeZone = zoneId != undefined && zoneId != '' ? zoneId : 'Europe/Paris';
        return moment.tz(date, timeZone).valueOf();
    }

    getLocaleWeekOfYear(date: any) {
        return moment(new Date(date)).week();
    }

    getYearMonthFromString(value: string, date: string): any {
        let reg = new RegExp('^[0-9]{1,2}?/[0-9]{1,2}?$');
        let month = new Date(date).getMonth() + 1;
        if (reg.test(value)) {
            let help = { month: parseInt(value.split('/')[1]), year: new Date().getFullYear() };
            help.year = help.month > month ? help.year - 1 : help.year;
            return help;
        } else if (value == 'm-1') return {
            year: moment(date).subtract(1, 'months').year(),
            month: moment(date).subtract(1, 'months').month() + 1
        };
        else if (value == 'm-2') return {
            year: moment(date).subtract(2, 'months').year(),
            month: moment(date).subtract(2, 'months').month() + 1
        };
    }
}
