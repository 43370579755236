import { Component, OnInit } from '@angular/core';

import { ToastMessageService } from 'global/services/toast-message/toast-message.service';

@Component({
  selector: 'toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

	public show: boolean = false;
	public message: string;
	public className: string = "alert";

  constructor(
  	private _toastMessageService: ToastMessageService
	) { }

  ngOnInit() {
  	this._toastMessageService.message()
  		.subscribe(res =>{
  			if(res != undefined && res !=null){
  				this.message = res.message;
	  			this.makeClassName(res.type);
	  			this.show = true;
	  			setTimeout(()=> this.show = false, 3000);
  			}
  		})
  }

  private makeClassName(type?: string){
  	switch(type){
  		case 'INFO': this.className += " info";
  			break;
  		case 'SUCCESS': this.className += " success";
  			break;
			case 'DANGER': this.className += " danger";
  			break;
			default: this.className += " info";
  			break;
  	}
  }

}
