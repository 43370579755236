import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFixed'
})
export class NumberFixedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return isNaN(parseFloat(value)) || isNaN(parseInt(args)) ? '-' : value.toFixed(args)
  }

}
