import { SiteDocumentDAO } from 'global/models/dao/site-document';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { Tiers } from 'tracabilite/models/dto/tiers';

export class ReceptionPrevueDAO extends SiteDocumentDAO{

  public dateReception : any;
	public gisement : Gisement;
  public poidsNet : number;
  public nbreColis: number;
	public numReception : string;
  public commentaire : string;

    constructor(){
        super();
        this.dataType = "receptionPrevue";
    }
}
