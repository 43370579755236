import { Categorie } from './categorie';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { EvenementDAO } from 'journal/models/dao/evenement';
import { SiteDocument } from 'global/models/dto/site-document';

export class Evenement extends SiteDocument implements IDto2Dao, IBGVTreeNode {
  public nom: string;
  public description: string;
  public evenementJournee: boolean;
  public dateDebut: any;
  public dateFin: any;
  public couleur: string;
  public categorie: Categorie;
  public getCouleur: boolean;
  public intervenant: any;

  constructor() {
    super();
    this.dateDebut = new Date();
    this.dateFin = new Date();
    this.dateFin.setMinutes((this.dateFin.getMinutes() + 30));
    this.couleur = "#ffffff";
    this.categorie = undefined;
    this.getCouleur = true;
  }

  toDao(): EvenementDAO {
    let evenement = new EvenementDAO();
    Object.keys(this).forEach(key => {
      if ("categorie".includes(key) && this[key]) evenement[key] = this[key]._id;
      else if (key == "site") evenement[key] = this[key]._id || this[key].id;
      else {
        evenement[key] = this[key];
      }
    });
    return evenement;
  }

  toNodes(parentId?: number, parentName?: any): any {
    let keyNames = [
    ]
    let index = 0;
    return keyNames.map(key => {
      let node: any = {},
        id = parentId ? parentId + index + 1 : index;
      node = {
        id: id,
        name: key.label,
        dataKey: key.dataKey
      }
      index++;
      return node;
    })

  }

}
