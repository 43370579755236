import { ReceptionDAO } from 'appro/models/dao/reception';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { SiteDocument } from 'global/models/dto/site-document';
import { Gisement } from 'tracabilite/models/dto/gisement';

export class Reception extends SiteDocument implements IDto2Dao, IBGVTreeNode {
    public dateReception: any;
    public gisement: Gisement;
    public badge: string;
    public numReception: string;
    public numPesee: string;
    public dsdEntree: string;
    public dsdSortie: string;
    public poidsEntree: number;
    public poidsSortie: number;
    public poidsNet: number;
    public statutReception: string;
    public statutFacturationClient: string;
    public statutFacturationFournisseur: string;
    public documentAEmettre: string;
    public commentaire: string;
    public tare: number;
    public plaque: string;
    public codeUtilisateur: string;
    public nbreColis: number;
    public notReportable: boolean;
    public datePesee1: any;
    public datePesee2: any;
    public dateIncorporation: any;
    public numDocument: string;
    public numBenne: string;
    public chauffeur: string;
    public numLot: string;
	public parcelle: any;
	public certifieDurable: boolean;

    constructor() {
        super();
        this.dataType = 'reception';
        this.statutFacturationClient = '-';
        this.statutFacturationFournisseur = '-';
        this.statutReception = 'Acceptée';
    this.certifieDurable = false;
	}

    public toDao(): ReceptionDAO {
        const reception: ReceptionDAO = new ReceptionDAO();
        Object.keys(this)
            .forEach(key => {
                if (key != 'site') {
                    reception[key] = this[key];
                }
            });
        const site: any = this['site'];
        if (site instanceof Object) {
            reception.site = site.id || site._id;
        } else {
            reception.site = site;
        }
        return reception;
    }

    toNodes(parentId?: number, parentName?: any): any {
        const keyNames = [
            { 'label': 'app.global.headers.site', 'dataKey': 'site.nom' },
            { 'label': 'Opération de traitement', 'dataKey': 'typeOperation'},
		    { 'label': 'app.global.headers.dateReception', 'dataKey': 'dateReception' },
            { 'label': 'app.global.headers.heureReception', 'dataKey': 'heureReception' },
            { 'label': 'app.global.headers.numReception', 'dataKey': 'numReception' },
            { 'label': 'app.global.headers.codeGisement', 'dataKey': 'gisement.codeGisement' },
            { 'label': 'Code déchet', 'dataKey': 'gisement.codeDechet'},
            {'label': 'Département', 'dataKey': 'departement'},
            {'label': 'Pays','dataKey': 'pays'},
            {'label': 'app.global.headers.gisement', 'dataKey': 'gisement.nom' },
            { 'label': 'Categorie gisement','dataKey': 'gisement.categorie'},
            {'label': 'Type facturation gisement','dataKey': 'gisement.typeFacturation'},
            {'label': 'app.global.headers.nBadge', 'dataKey': 'badge' },
            { 'label': 'app.global.headers.nPesee', 'dataKey': 'numPesee' },
            { 'label': 'app.global.headers.poidsEntree', 'dataKey': 'poidsEntree' },
            { 'label': 'app.global.headers.poidsSortie', 'dataKey': 'poidsSortie' },
            { 'label': 'app.global.headers.poidsNet', 'dataKey': 'poidsNet' },
            { 'label': 'app.global.headers.statutReception', 'dataKey': 'statutReception' },
            { 'label': 'app.global.headers.statutFactClient', 'dataKey': 'statutFacturationClient' },
            { 'label': 'app.global.headers.statutFactFournisseur', 'dataKey': 'statutFacturationFournisseur' },
            { 'label': 'app.global.headers.tare', 'dataKey': 'tare' },
            { 'label': 'app.global.headers.plaque', 'dataKey': 'plaque' },
            { 'label': 'app.global.headers.nBenne', 'dataKey': 'numBenne' },
            { 'label': 'app.global.headers.chauffeur', 'dataKey': 'chauffeur' },
            { 'label': 'app.global.headers.nLot', 'dataKey': 'numLot' },
            { 'label': 'app.global.headers.chauffeur', 'dataKey': 'documentAEmettre' },
            { 'label': 'app.global.headers.commentaire', 'dataKey': 'commentaire' },
            { 'label': 'app.global.headers.codeNegociant', 'dataKey': 'gisement.negociant.codeTiers' },
            { 'label': 'app.global.headers.negociant', 'dataKey': 'gisement.negociant.nom' },
            { 'label': 'app.global.headers.codeProducteur', 'dataKey': 'gisement.producteur.codeTiers' },
            { 'label': 'app.global.headers.producteur', 'dataKey': 'gisement.producteur.nom' },
            {'label': 'Type activité producteur', 'dataKey': 'gisement.producteur.typeActivite'},
            {'label': 'Type facturation producteur', 'dataKey': 'gisement.producteur.typeTiers'},
            { 'label': 'app.global.headers.codeTransporteur', 'dataKey': 'gisement.transporteur.codeTiers' },
            { 'label': 'app.global.headers.transporteur', 'dataKey': 'gisement.transporteur.nom'},
            {'label': 'Code producteur', 'dataKey': 'gisement.producteur.codeTiers'},
            {'label': 'Producteur', 'dataKey': 'gisement.producteur.nom'},
            {'label': 'Code négociant', 'dataKey': 'gisement.negociant.codeTiers'},
            {'label': 'Négociant', 'dataKey': 'gisement.transporteur.nom' },
            { 'label': 'app.global.headers.conditionnement', 'dataKey': 'gisement.conditionnement' },
            { 'label': 'app.global.headers.modeTransport', 'dataKey': 'gisement.modeDeLivraison' },
            { 'label': 'app.global.headers.distanceSiteProduction%', 'dataKey': 'gisement.distanceSiteProduction' },
            { 'label': 'app.global.headers.datePeseeEntree', 'dataKey': 'datePesee1' },
            { 'label': 'app.global.headers.heurePeseeEntree', 'dataKey': 'heurePesee1' },
            { 'label': 'app.global.headers.datePeseeSortie', 'dataKey': 'datePesee2' },
            { 'label': 'app.global.headers.heurePeseeSortie', 'dataKey': 'heurePesee2' },
            { 'label': 'app.global.headers.matiereSecheMB', 'dataKey': 'MS/MB' },
            { 'label': 'app.global.headers.matiereOrganiqueMB', 'dataKey': 'MO/MB' },
            { 'label': 'app.global.headers.potentionMéthaneOrganiqueMB', 'dataKey': 'PM/MB stand' },
            {'label': 'Azote total', 'dataKey': 'Ntot'},
            { 'label': 'app.global.headers.nombreColis', 'dataKey': 'nbreColis' },
            { 'label': 'app.global.headers.codeUtilisateur', 'dataKey': 'codeUtilisateur' },
            { 'label': 'app.global.headers.dateIncorporation', 'dataKey': 'dateIncorporation' },
            { 'label': 'app.global.headers.nDocument', 'dataKey': 'numDocument' },
            { 'label': 'app.global.headers.stockIntrant', 'dataKey': 'gisement.stockIntrant.nom' },
            {'label': 'Parcelle - Références cadastrales', 'dataKey': 'parcelle.referenceCadastre'},
            {'label': 'Parcelle - Nom', 'dataKey': 'parcelle.nom'},
            {'label': 'Parcelle - Commune', 'dataKey': 'parcelle.commune'},
            {'label': 'Parcelle - Exploitant', 'dataKey': 'parcelle.exploitant.nom'},
            {'label': 'Parcelle - Commentaire', 'dataKey': 'parcelle.commentaire'},
            {'label': 'Parcelle - Surface agricole utile (Sau) (ha)', 'dataKey': 'parcelle.surfaceAgricole'},
            {'label': 'Parcelle - Surface potentielle d\'épandage (Sau) (ha)', 'dataKey': 'parcelle.surfaceEpandage'},
            {'label': 'Parcelle - Culture N', 'dataKey': 'parcelle.cultureN'},
            {'label': 'Parcelle - Culture N-1', 'dataKey': 'parcelle.cultureN1'},
            {'label': 'Ouvrage', 'dataKey': 'gisement.ouvrageIncorporation.nom'},
            {'label': 'Digesteur', 'dataKey': 'gisement.digesteur.nom'},
        ];
        let index = 0;
        return keyNames.map(key => {
            let node: any = {},
                id = parentId ? parentId + index + 1 : index;
            node = {
                id: id,
                name: key.label,
                dataKey: key.dataKey
            };
            index++;
            return node;
        });
    }

}
