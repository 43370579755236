import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export type Period = 'aujourdhui' | 'semaineEnCours' | 'moisEnCours' | 'anneeEnCours' | '30derniersJours' | '12derniersMois' | 'depuisLeDebut' | '3derniersMois' | 'moisPrecedent' | 'between' | 'fixed';

@Component({
    selector: 'app-mega-navigation',
    templateUrl: './mega-navigation.component.html',
    styleUrls: ['./mega-navigation.component.scss']
})
export class MegaNavigationComponent implements OnInit {

    @ViewChild('close', { static: true }) closeButton: ElementRef;
    @Input() default: Period;
    @Input() period: { from: any, to: any };
    @Input() id: string;
    @Output() select = new EventEmitter();

    model: NgbDateStruct;
    date: { year: number, month: number };
    fromDate: NgbDate;
    toDate: NgbDate;
    fromDay: any;
    toDay: any;
    label: Period;
    labelTxt: String;
    fromState = false;
    fromToState = false;

    periodes: Period[] = [
        'aujourdhui',
        'semaineEnCours',
        'moisEnCours',
        'anneeEnCours',
        '30derniersJours',
        '12derniersMois',
        'depuisLeDebut',
        '3derniersMois',
        'moisPrecedent',
    ];


    constructor(calendar: NgbCalendar, private _router: Router,
                private _route: ActivatedRoute, private translateService: TranslateService) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(this.fromDate, 'd', 10);
        this.setDay();
        this.translateService.onLangChange.subscribe(lang => {
            this.onPeriodChange(this.label);
        });
    }

    ngOnInit() {
        if (this.period) {
            this.fromDay = this.period.from;
            this.toDay = this.period.to;
            this.onPeriodChange('between');
        } else {
            this.default ? this.onPeriodChange(this.default) : this.onPeriodChange('12derniersMois');
        }
        this.id = this.id ? `#${this.id}` : '';
    }

    displayFrom(state) {
        this.fromState = state;
    }

    displayFromTo(state) {
        this.fromToState = state;
    }

    onPeriodChange(period: Period) {
        if (period === 'between' && this.fromDay && this.toDay) {
            this.fromToState = false;
            this.labelTxt = this.translateService
                .instant('app.modules.global.components.megaNavigation.duAuParams', {fromDay: this.fromDay, toDay: this.toDay});
        } else if (period === 'fixed' && this.fromDay) {
            this.fromState = false;
            this.labelTxt = this.translateService
                .instant('app.modules.global.components.megaNavigation.periodeDu', {day: this.fromDay});
        } else  {
            const per = period ? period : 'aujourdhui';
            const dates = this.getPeriod(per);
            this.fromDay = dates.from.format('DD/MM/YYYY');
            this.toDay = dates.to.format('DD/MM/YYYY');
            this.labelTxt = this.translateService.instant(`app.modules.global.components.megaNavigation.${per}`)
                + ' (' + this.translateService.instant('app.modules.global.components.megaNavigation.duAuParams', {
                            fromDay: this.fromDay,
                            toDay: this.toDay
                        }) + ')';
        }
        this.select.emit({
            from: moment(this.fromDay, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            to: moment(this.toDay, 'DD/MM/YYYY').format('YYYY/MM/DD')
        });
    }

    onDate1Selection(date: NgbDate) {
        if (this.toDate && date.before(this.toDate)) {
            this.fromDate = date;
            this.setDay();
        } else {
            this.fromDate = date;
        }
    }

    onDate2Selection(date: NgbDate) {
        if (this.fromDate && date.after(this.fromDate)) {
            this.toDate = date;
            this.setDay();
        } else {
            this.toDate = date;
        }
    }

    onDateSelection(date: NgbDate) {
        this.fromDate = this.toDate = date;
        this.setDay();
    }

    private setDay() {
        this.fromDay = moment(this.fromDate.year + '/' + this.fromDate.month + '/' + this.fromDate.day, 'YYYY/MM/DD').format('DD/MM/YYYY');
        this.toDay = moment(this.toDate.year + '/' + this.toDate.month + '/' + this.toDate.day, 'YYYY/MM/DD').format('DD/MM/YYYY');
    }

    private getPeriod(label: Period): { from: Moment, to: Moment } {
        let period;
        switch (label) {
            case 'aujourdhui':
                period = {
                    from: moment().startOf('day'),
                    to: moment().endOf('day')
                };
                break;
            case 'semaineEnCours':
                period = {
                    from: moment().startOf('week'),
                    to: moment().endOf('week')
                };
                break;
            case 'moisEnCours':
                period = {
                    from: moment().startOf('month'),
                    to: moment().endOf('month')
                };
                break;
            case 'anneeEnCours':
                period = {
                    from: moment().startOf('year'),
                    to: moment().endOf('year')
                };
                break;
            case '30derniersJours':
                period = {
                    from: moment().subtract(29, 'day'),
                    to: moment()
                };
                break;
            case '12derniersMois':
                period = {
                    from: moment().subtract(12, 'month'),
                    to: moment()
                };
                break;
            case 'depuisLeDebut':
                period = {
                    from: moment('2000/01/01', 'DD/MM/YYYY'),
                    to: moment()
                };
                break;
            case '3derniersMois':
                period = {
                    from: moment().subtract(2, 'months').startOf('month'),
                    to: moment().endOf('month')
                };
                break;
            case 'moisPrecedent':
                period = {
                    from: moment().subtract(1, 'months').startOf('month'),
                    to: moment().subtract(1, 'months').endOf('month')
                };
                break;
            default:
                period = {
                    from: moment(this.fromDay, 'DD/MM/YYYY'),
                    to: moment(this.toDay, 'DD/MM/YYYY')
                };
                break;
        }
        return period;
    }

}
