import { AnalyseLabo } from 'tracabilite/models/dto/analyse-labo';
import { Gisement } from 'tracabilite/models/dto/gisement';
import { IDto2Dao } from 'global/interfaces/IDto2Dao';
import { AnalyseIntrantDAO } from '../dao/analyse-intrant';
import { CaracteristiquePhysicoChimique } from 'tracabilite/models/caracteristique-physico-chimique';
import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';


export class AnalyseIntrant extends AnalyseLabo implements IDto2Dao, IBGVTreeNode{
	public nom : string;
	public type : string;
	public gisement : Gisement;
	public numEchantillon : string;
	public commentaire : string;
	public cpc : CaracteristiquePhysicoChimique[];
	constructor(){
		super();
		this.dataType = 'analyseIntrant';
		this.dateAnalyse = Date.now();
	}

	public toDao(): AnalyseIntrantDAO{
		let analyse: AnalyseIntrantDAO = new AnalyseIntrantDAO();
		Object.keys(this)
			.forEach(key => {
				if(key == "site") {
					this[key]._id? analyse[key] = this[key]._id : analyse[key] = this[key].id;
				} else if(key == "gisement") {
          analyse[key] = this[key]._id;
        }
				else analyse[key]= this[key];
			});
		return analyse;
	}

	toNodes(parentId?: number, parentName?: any): any{
    let keyNames = [
		{'label': 'app.global.headers.site','dataKey': 'site.nom'},
		{'label': 'app.global.headers.dateAnalyse','dataKey': 'dateAnalyse'},
    	{'label': 'app.global.headers.nom','dataKey': 'nom'},
		{'label': 'app.global.headers.gisement','dataKey': 'gisement.nom'},
		{'label': 'app.global.headers.codeGisement','dataKey': 'gisement.codeGisement'},
    	{'label': 'app.global.headers.typeAnalyse','dataKey': 'type'},
    	{'label': 'app.global.headers.numeroEchantillon','dataKey': 'numEchantillon'},
    	{'label': 'app.global.headers.laboratoire','dataKey': 'laboratoire'},
    	{'label': 'app.global.headers.numeroLot','dataKey': 'numLot'},
    	{'label': 'app.global.headers.numeroAnalyse','dataKey': 'numAnalyse'},
    	{'label': 'app.global.headers.commentaire','dataKey': 'commentaire'},
		{'label': 'app.global.headers.matiereSecheMB', 'dataKey': 'MS/MB'},
		{'label': 'app.global.headers.matiereOrganiqueMB', 'dataKey': 'MO/MB'},
		{'label': 'app.global.headers.potentionMéthaneOrganiqueMB', 'dataKey': 'PM/MB stand'},
		{'label': 'app.global.headers.potentionMethaneMBFlash','dataKey': 'PM/MB flash'},
		{'label': 'app.global.headers.azoteAmmoniacal','dataKey': 'N-NH4'},
		{'label': 'app.global.headers.azoteOrganique','dataKey': 'Norg'},
    	{'label': 'app.global.headers.azoteTotale','dataKey': 'Ntot'},
		{'label': 'app.global.headers.azoteKjeldahl','dataKey': 'Nkjeldahl'},
		{'label': 'app.global.headers.phosphore','dataKey': 'P2O5'},
    	{'label': 'app.global.headers.potassium','dataKey': 'K2O'},
    ]
    let index = 0;
    return keyNames.map(key =>{
      let node: any = {},
        id = parentId ? parentId+index+1 : index;
      node = {
        id: id,
        name: key.label,
        dataKey: key.dataKey
      }
      index++;
      return node;
    })
  }

}
