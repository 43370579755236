import { Entreprise } from './entreprise';
import { Contact } from './contact';
import { Banque } from './banque';

export class PorteurProjet extends Entreprise{
	roleAdditionnels: string[];
  domiciliationBancaire: string;
  codeBic: string;
  iban: string;
  devise: string;
  codeRib: string;
  numeroAgrementsanitaire: string;
  noteFacturation: string;
  libelleBanque: string;
  contactFacturation: Contact = new Contact();
	compteBancaires: Banque[] = new Array<Banque>();
}