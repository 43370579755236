import { Adresse } from './adresse';
import { Contact } from './contact';

export class Entreprise{
	nom: string;
  type: string;
  adresse: Adresse = new Adresse();
  contact: Contact = new Contact();
  siret: string;
  tvaNumber: string;
  codeClient: string;
  rCS: string;
}