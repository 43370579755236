import { IBGVTreeNode } from 'global/interfaces/IBGVTreeNode';
import * as _ from 'lodash';

export class Adresse implements IBGVTreeNode{
	complementAdresse: string;
	adresse: string;
	pays: string;
	commune: string;
	codePostal: string;

	constructor(){
		this.pays = 'France';
	}

	toNodes(parentId?: number, parentName?: any): any{
		let keyNames ={
			'complementAdresse': 'Adresse bis',
			'adresse': 'Adresse',
			'pays': 'Pays',
			'commune': 'Commune',
			'codePostal': 'Code postal'
		}

		return Object.keys(keyNames)
			.map((key,index) =>{
				if(parentId != undefined && parentName)
					return {
						id: index+parentId+1,
						name: keyNames[key],
						dataKey: parentName+'.'+key,
						parent: parentId
					}
				else return {
					id: index,
					name: keyNames[key],
					dataKey: key
				}
			})
	}
}
